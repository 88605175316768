import queryString from "query-string";
import { history } from "../stores/configureStore";

export const setInitFilters = () => {
    let { market = [], typology = [], subTypology, search } = queryString.parse(history.location.search, {
        arrayFormat: "comma"
    });
    if (!Array.isArray(market)) {
    market = !!market ? [market] : [];
    }
    if (!Array.isArray(typology)) {
    typology = !!typology ? [typology] : [];
    }
    localStorage.setItem("initFilters", JSON.stringify({ market, typology, subTypology, search }));
};


