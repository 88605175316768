import * as actionTypes from "../constants/actionTypes";
import * as actions from "../actions";

import { catchError, filter, map, switchMap } from "rxjs/operators";

import { Action } from "../actions/share";
import { Epic } from "redux-observable";
import { State } from "../interfaces";
import { ajax } from "rxjs/ajax";
import domain from "../utils/domain";
import { isOfType } from "typesafe-actions";
import { of } from "rxjs";

export const getSharedLinksEpic: Epic<Action, Action, State> = (
  action$,
  state$
) =>
  action$.pipe(
    filter(isOfType(actionTypes.GET_SHARED_LINKS)),
    switchMap((action) => {
      const { nid, fids, exp, typology, subTypology, selectedBrand } = action.payload;

      const state: State = state$.value;
      const { auth, language } = state;
      const { accessToken, sessionToken } = auth;
      
      let types: string[] = [];
      if(subTypology){
        types.push(subTypology); 
      } else if(typology){
        if(Array.isArray(typology)){
          types = typology;
        }        
      }
      // @TODO: 
      /*
      "lang": "en",
      "fids": [
        29343
      ],
      "nid": 10473,
      "exp": 1729151966,
      "brand": 350,
      "markets": [
          509,
          588
      ],
      "types": ["123"]
      */
      return ajax({
        body: {
          lang: language.selectedLanguage?.id,
          nid,
          fids,
          exp,
          types,
          brand: selectedBrand?.id? selectedBrand.id : null,
          domain: process.env.REACT_APP_DOMAIN
        },
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
          "X-CSRF-Token": sessionToken
        },
        url: `${domain}api/item/share?_format=json`,
        crossDomain: true,
        method: "POST"
      }).pipe(
        map(({ response }) => {
          const links = response.links;
          return actions.getSharedLinksSuccess(links);
        }),
        catchError((error) => {
          console.log("share error ", error);
          if (error.response)
            return of(
              actions.getSharedLinksError(error.response.message, error.status)
            );
          return of(actions.getSharedLinksError(error.message, error.status));
        })
      );
    })
  );
