import React, { useEffect, useState } from 'react';
import axios from 'axios';
import domain from '../../utils/domain';
import { shallowEqual, useSelector } from 'react-redux';
import { State } from '../../interfaces';
import { AuthState } from '../../reducers/auth';
import { Loader } from '../../components/loader';
import { Row } from 'antd';
import { RouteComponentProps } from 'react-router-dom';


interface Props extends RouteComponentProps {
}

const RedirectToken: React.FC<Props> = (props) => {

  const { match } = props;

  const { token } = match.params as { token: string };

  const auth: AuthState = useSelector(
    (state: State) => state.auth,
    shallowEqual
  );  
  const { accessToken } = auth;

  const [error, setError] = useState<boolean>(false);

  useEffect(() => {
    const fetchOriginalUrl = async () => {
      try {
        const response = await axios({
          method: "get",
          url: `${domain}/api/url_shortner/get?token=${token}`,
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });
        if (response.data.original_url) {
          // Redirect to the original URL and cause full-page reload of the browser
          window.location.href = response.data.original_url;
        }
      } catch (error) {
        console.error('Error fetching redirect URL:', error);
        setError(true);
      }
    };

    if(accessToken){
      fetchOriginalUrl();
    }
  }, [token, accessToken]);

  return !error ? (
    <Loader isLoading />
  ) : (
    <Row align="middle" justify="center">
      <h3>
        No library found for <code>{window.location.pathname}</code>
      </h3>
    </Row>
  );
};

export default RedirectToken;
