import React, { useEffect, useCallback } from "react";
import { shallowEqual, useSelector, useDispatch } from "react-redux";
import { Row } from "antd";
import * as actions from "../../actions";

import { LanguageState } from "../../reducers/language";
import { State } from "../../interfaces";

interface Props {
  match: any;
}

// const  = ({ onLoginWithToken, ...rest }) => {
const DealerPortalAccess: React.FC<Props> = (props) => {
  const { match } = props;
  const { access_token, refresh_token } = match.params;

  const language: LanguageState = useSelector(
    (state: State) => state.language,
    shallowEqual
  );
  const { selectedLanguage } = language;
  const from = localStorage.getItem('from') || '/';

  const dispatch = useDispatch();

  const onLoginWithToken = useCallback(
    (access_token, refresh_token) =>
      dispatch(actions.loginWithToken(access_token, refresh_token, from)),
    [dispatch]
  );

  useEffect(() => {
    const from = localStorage.getItem("from");
    localStorage.clear();
    if (from) {
      localStorage.setItem("from", from);
    };
    // console.log("selectedLanguage ", selectedLanguage);
    // console.log("access_token.. ", access_token);
    // console.log("refresh_token.. ", refresh_token);
    if (selectedLanguage) {
      if (access_token) {
        onLoginWithToken(access_token, refresh_token);
      }
    }
  }, [access_token, onLoginWithToken, refresh_token, selectedLanguage]);

  // componentDidUpdate(prevProps, prevState, snapshot) {
  //   const { selectedLanguage: prevLang } = prevProps;
  //   const { selectedLanguage } = this.props;
  //   if (prevLang !== selectedLanguage) {
  //     const { access_token, refresh_token } = this.props.match.params;
  //     // console.log("access_token.. ", access_token);
  //     // console.log("refresh_token.. ", refresh_token);
  //     if (access_token) {
  //       const { onLoginWithToken } = this.props;
  //       onLoginWithToken(access_token, refresh_token);
  //     }
  //   }
  // }
  return (
    <Row align="middle" justify="center">
      <h3>Logging in current user, please wait...</h3>
    </Row>
  );
};

export default DealerPortalAccess;
