import { combineReducers } from "redux";
import * as actionTypes from "../constants/actionTypes";
import { Action as HomeAction } from "../actions/home";
import { Banner } from "../interfaces";

type Action = HomeAction;

export type HomeState = ReturnType<typeof home>;

const isLoading = (state: boolean = false, action: Action) => {
  switch (action.type) {
    case actionTypes.GET_HOME_BANNERS:
      return true;
    case actionTypes.GET_HOME_BANNERS_SUCCESS:
    case actionTypes.GET_HOME_BANNERS_ERROR:
      return false;

    default:
      return state;
  }
};

const homeBanners = (state: Array<Banner> = [], action: Action) => {
  switch (action.type) {
    case actionTypes.GET_HOME_BANNERS_SUCCESS:
      const { banners } = action.payload;
      return banners;
    default:
      return state;
  }
};

const home = combineReducers({
  isLoading,
  homeBanners
});

export default home;
