import * as actions from "../../actions";

import { Button, Layout, Menu, Popover, Row } from "antd";
import { Link, useLocation } from "react-router-dom";
import { MaxWidth, State, Typology } from "../../interfaces";
import { QuestionCircleOutlined, ShareAltOutlined } from "@ant-design/icons";
import React, { useCallback } from "react";
import { getLanguageIcon, openForums } from "../../utils";
import { shallowEqual, useDispatch, useSelector } from "react-redux";

import { AuthState } from "../../reducers/auth";
import Brand from "./Brand";
import DynamicIcon from "../DynamicIcon";
import { FiltersState } from "../../reducers/filters";
import { LanguageState } from "../../reducers/language";
import Logo from "./Logo";
import LogoutBtn from "./LogoutBtn";
import { MdMenu } from "react-icons/md";
import { history } from "../../stores/configureStore";
import queryString from "query-string";
import { setInitFilters } from "../../utils/filters";
import { social } from "../../utils/social";
import { useMedia } from "react-use";

const SubMenu = Menu.SubMenu;
const MenuItemGroup = Menu.ItemGroup;
const { Header } = Layout;

const Nav = () => {
  const language: LanguageState = useSelector(
    (state: State) => state.language,
    shallowEqual
  );
  const { entities, labels, selectedLanguage } = language;
  const auth: AuthState = useSelector(
    (state: State) => state.auth,
    shallowEqual
  );
  const { user, isFetching, accessToken } = auth;

  const filters: FiltersState = useSelector(
    (state: State) => state.filters,
    shallowEqual
  );
  const { selectedBrand } = filters;

  const dispatch = useDispatch();
  const onLogout = useCallback(() => dispatch(actions.logout()), [dispatch]);
  const onSelectLanguage = useCallback(
    (language) => dispatch(actions.selectLanguage(language)),
    [dispatch]
  );

  const location = useLocation();

  const isSmall = useMedia(MaxWidth.LG);

  const _openForums = () => {
    openForums({
      accessToken: accessToken as string,
      brandCode: selectedBrand!.code,
      brandId: selectedBrand!.id,
      languageId: selectedLanguage!.code,
      userName: user!.userName,
      destination: "forum"
    });
  };

  const openUserProfile = () => {
    openForums({
      accessToken: accessToken as string,
      brandCode: selectedBrand!.code,
      brandId: selectedBrand!.id,
      languageId: selectedLanguage!.code,
      userName: user!.userName,
      destination: "user"
    });
  };

  const handleClick = (param: any) => {
    //console.log("param ", param);
    const { key } = param;
    if (key === "forums") {
      _openForums();
    } else if (key.includes("flag")) {      
      setInitFilters();

      //console.log('history', history.location);
      const code = key.substr(5, 5);
      const language = entities.byId[code];
      onSelectLanguage(language);
    }
  };

  const languages = entities.all.map((_id: string) => {
    const { id, name } = entities.byId[_id];
    return (
      <Menu.Item key={`flag:${id}`}>
        <div className="flag-wrapper">
          <img
            className="flag"
            src={`/flags/${getLanguageIcon(id)}.png`}
            alt={name}
          />
          <span className="flag-name">{name}</span>
        </div>
      </Menu.Item>
    );
  });

  const help = {
    nha: [
      <Menu.Item key="guide1">
        <a
          href={`/help/${selectedBrand?.code}/UK.pdf`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <img className="flag help" src={`/flags/GB.png`} alt="GB" />
          User guide
        </a>
      </Menu.Item>,
      <Menu.Item key="guide2">
        <a
          href={`/help/${selectedBrand?.code}/IT.pdf`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <img className="flag help" src={`/flags/IT.png`} alt="IT" />
          Guida utente
        </a>
      </Menu.Item>,
      <Menu.Item key="guide3">
        <a
          href={`/help/${selectedBrand?.code}/FR.pdf`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <img className="flag help" src={`/flags/FR.png`} alt="FR" />
          Guide de l’utilisateur
        </a>
      </Menu.Item>,
      /*     <Menu.Item key="guide4">
      <a
        href={`/help/${selectedBrand?.code}/ES.pdf`}
        target="_blank"
        rel="noopener noreferrer"
      >
        <img className="flag help" src={`/flags/ES.png`} alt="ES" />
        Guía del usuario
      </a>
    </Menu.Item>, */
      <Menu.Item key="guide5">
        <a
          href={`/help/${selectedBrand?.code}/DA.pdf`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <img className="flag help" src={`/flags/DA.png`} alt="DA" />
          Brugervejledning
        </a>
      </Menu.Item>,
      <Menu.Item key="guide6">
        <a
          href={`/help/${selectedBrand?.code}/NL.pdf`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <img className="flag help" src={`/flags/NL.png`} alt="NL" />
          Gebruikershandleiding
        </a>
      </Menu.Item>,
      <Menu.Item key="guide7">
        <a
          href={`/help/${selectedBrand?.code}/PT.pdf`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <img className="flag help" src={`/flags/ptPT.png`} alt="PT" />
          Guia de usuario
        </a>
      </Menu.Item>,
      <Menu.Item key="guide8">
        <a
          href={`/help/${selectedBrand?.code}/PL.pdf`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <img className="flag help" src={`/flags/PL.png`} alt="PL" />
          Podręcznik użytkownika
        </a>
      </Menu.Item>,
      <Menu.Item key="guide9">
        <a
          href={`/help/${selectedBrand?.code}/SV.pdf`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <img className="flag help" src={`/flags/SV.png`} alt="SV" />
          Användarguide
        </a>
      </Menu.Item>,
      <Menu.Item key="guide10">
        <a
          href={`/help/${selectedBrand?.code}/DE.pdf`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <img className="flag help" src={`/flags/DE.png`} alt="DE" />
          Benutzerhandbuch
        </a>
      </Menu.Item>,
      <Menu.Item key="guide11">
        <a
          href={`/help/${selectedBrand?.code}/UK.pdf`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <img className="flag help" src={`/flags/UK.png`} alt="UK" />
          Керівництво користувача
        </a>
      </Menu.Item>
    ],
    caseih: [
      <Menu.Item key="guide1">
        <a
          href={`/help/${selectedBrand?.code}/UK.pdf`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <img className="flag help" src={`/flags/GB.png`} alt="GB" />
          User guide
        </a>
      </Menu.Item>,
      <Menu.Item key="guide2">
        <a
          href={`/help/${selectedBrand?.code}/IT.pdf`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <img className="flag help" src={`/flags/IT.png`} alt="IT" />
          Guida utente
        </a>
      </Menu.Item>,
      <Menu.Item key="guide3">
        <a
          href={`/help/${selectedBrand?.code}/FR.pdf`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <img className="flag help" src={`/flags/FR.png`} alt="FR" />
          Guide de l’utilisateur
        </a>
      </Menu.Item>,
      /*     <Menu.Item key="guide4">
      <a
        href={`/help/${selectedBrand?.code}/ES.pdf`}
        target="_blank"
        rel="noopener noreferrer"
      >
        <img className="flag help" src={`/flags/ES.png`} alt="ES" />
        Guía del usuario
      </a>
    </Menu.Item>, */
      <Menu.Item key="guide5">
        <a
          href={`/help/${selectedBrand?.code}/DA.pdf`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <img className="flag help" src={`/flags/DA.png`} alt="DA" />
          Brugervejledning
        </a>
      </Menu.Item>,
      <Menu.Item key="guide6">
        <a
          href={`/help/${selectedBrand?.code}/NL.pdf`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <img className="flag help" src={`/flags/NL.png`} alt="NL" />
          Gebruikershandleiding
        </a>
      </Menu.Item>,
      /*     <Menu.Item key="guide7">
      <a
        href={`/help/${selectedBrand?.code}/PT.pdf`}
        target="_blank"
        rel="noopener noreferrer"
      >
        <img className="flag help" src={`/flags/PT.png`} alt="PT" />
        Guia de usuario
      </a>
    </Menu.Item>, */
      <Menu.Item key="guide8">
        <a
          href={`/help/${selectedBrand?.code}/PL.pdf`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <img className="flag help" src={`/flags/PL.png`} alt="PL" />
          Podręcznik użytkownika
        </a>
      </Menu.Item>,
      <Menu.Item key="guide9">
        <a
          href={`/help/${selectedBrand?.code}/SV.pdf`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <img className="flag help" src={`/flags/SV.png`} alt="SV" />
          Användarguide
        </a>
      </Menu.Item>,
      <Menu.Item key="guide10">
        <a
          href={`/help/${selectedBrand?.code}/DE.pdf`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <img className="flag help" src={`/flags/DE.png`} alt="DE" />
          Benutzerhandbuch
        </a>
      </Menu.Item>,
      <Menu.Item key="guide11">
        <a
          href={`/help/${selectedBrand?.code}/UK.pdf`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <img className="flag help" src={`/flags/UK.png`} alt="UK" />
          Керівництво користувача
        </a>
      </Menu.Item>
    ]
  };

  // const welcome = labels ? labels.welcome : null;

  const brandCode = selectedBrand?.code || user?.brands?.[0].code;
  const logoLink =
    user && user?.brands.length > 1 ? "/select-brand" : `/${brandCode}/home`;

  const qps: any = {
    market: filters.markets.selectedMarkets,
    typology: filters.typologies.selectedTypologies
  };

  const subType: Typology | null = filters.typologies.selectedSubtypology;
  const subTypeId = subType?.id;
  if (subTypeId) {
    qps.subTypology = subTypeId;
  }

  const queryParams = queryString.stringify(qps, { arrayFormat: "comma" });

  return (
    <Header className="main-nav">
      <Row justify="space-between" align="middle">
        {isSmall ? (
          <>
            {!selectedBrand && (
              <Link className="logo-link" to={logoLink}>
                <Brand />
              </Link>
            )}
            <Link to={logoLink}>
              <Logo brand={selectedBrand} />
            </Link>
          </>
        ) : (
          <Link className="logo-link" to={logoLink}>
            <Brand />
          </Link>
        )}
        <div className="navbar">
          {isSmall ? (
            // menu mobile
            <>
              <LogoutBtn
                isFetching={isFetching}
                labels={labels}
                user={user}
                openUserProfile={openUserProfile}
                onLogout={onLogout}
              />
              {selectedBrand && (
                <Popover
                  overlayClassName="popover-menu-mobile"
                  placement="bottomRight"
                  // getPopupContainer={triggerNode => triggerNode.parentNode}
                  content={
                    <Menu
                      className="menu-mobile"
                      onClick={handleClick}
                      selectedKeys={[location.pathname]}
                    >
                      <Menu.Item key={`/${selectedBrand.code}/home`}>
                        <Link to={`/${selectedBrand.code}/home`}>Home</Link>
                      </Menu.Item>
                      <Menu.Item key={`/${selectedBrand.code}/library`}>
                        <Link
                          to={`/${selectedBrand.code}/library?${queryParams}`}
                        >
                          {labels.library}
                        </Link>
                      </Menu.Item>
                      {/* <Menu.Item key="/news">
                        <Link to="/news">{labels.news}</Link>
                      </Menu.Item>
                      <Menu.Item key="forums">{labels.forum}</Menu.Item> */}
                      {selectedLanguage && (
                        <MenuItemGroup key="l1" title="Languages">
                          {languages.map((item) => item)}
                        </MenuItemGroup>
                      )}
                      <MenuItemGroup key="social" title="Social">
                        {social[selectedBrand.code][
                          selectedLanguage?.code ?? "en"
                        ].map(
                          (
                            item: {
                              icon: string;
                              name: string;
                              link: string;
                            },
                            index
                          ) => {
                            const { icon, name, link } = item;
                            const _link =
                              link === ""
                                ? social[selectedBrand.code]["en"][index].link
                                : link;

                            return (
                              <Menu.Item key={icon}>
                                <a
                                  href={_link}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  <DynamicIcon type={icon} />
                                  {name}
                                </a>
                              </Menu.Item>
                            );
                          }
                        )}
                      </MenuItemGroup>
                      <MenuItemGroup key="help" title="Help">
                        {help[selectedBrand?.code].map((item) => item)}
                      </MenuItemGroup>
                    </Menu>
                  }
                  trigger="click"
                >
                  <Button className="menu-btn">
                    <MdMenu />
                  </Button>
                </Popover>
              )}
            </>
          ) : (
            // menu desktop
            <>
              <Link to={logoLink}>
                <Logo brand={selectedBrand} />
              </Link>
              {selectedBrand && (
                <Menu
                  className="menu link-menu"
                  theme="light"
                  mode="horizontal"
                  onClick={handleClick}
                  selectedKeys={[location.pathname]}
                >
                  <Menu.Item key={`/${selectedBrand.code}/home`}>
                    {location.pathname === `/${selectedBrand.code}/home` ? (
                      <div className="ant-menu-item-selected">Home</div>
                    ) : (
                      <Link to={`/${selectedBrand.code}/home`}>Home</Link>
                    )}
                  </Menu.Item>
                  <Menu.Item key={`/${selectedBrand.code}/library`}>
                    {location.pathname === `/${selectedBrand.code}/library` ? (
                      <div className="ant-menu-item-selected">
                        {labels.library}
                      </div>
                    ) : (
                      <Link to={`/${selectedBrand.code}/library?${queryParams}`}> {labels.library}</Link>
                    )}
                  </Menu.Item>
                  {/*
                  <Menu.Item key="/news">
                    <Link to="/news">{labels.news}</Link>
                  </Menu.Item>
                   <Menu.Item key="forums">{labels.forum}</Menu.Item> */}
                  <SubMenu
                    title={
                      <span>
                        <ShareAltOutlined />
                      </span>
                    }
                  >
                    {social[selectedBrand.code][
                      selectedLanguage?.code ?? "en"
                    ].map(
                      (
                        item: { icon: string; name: string; link: string },
                        index
                      ) => {
                        const { icon, name, link } = item;
                        const _link =
                          link === ""
                            ? social[selectedBrand.code]["en"][index].link
                            : link;

                        return (
                          <Menu.Item key={icon}>
                            <a
                              href={_link}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <DynamicIcon type={icon} />
                              {name}
                            </a>
                          </Menu.Item>
                        );
                      }
                    )}
                  </SubMenu>
                  <SubMenu
                    title={
                      <span>
                        <QuestionCircleOutlined />
                      </span>
                    }
                  >
                    {help[selectedBrand?.code].map((item) => item)}
                  </SubMenu>
                </Menu>
              )}
              <LogoutBtn
                isFetching={isFetching}
                labels={labels}
                user={user}
                openUserProfile={openUserProfile}
                onLogout={onLogout}
              />
              <Menu
                className="menu"
                theme="light"
                mode="horizontal"
                onClick={handleClick}
              >
                {selectedLanguage && entities.all.length > 1 && (
                  <SubMenu
                    title={
                      <div className="flag-wrapper">
                        <img
                          className="flag"
                          src={`/flags/${getLanguageIcon(
                            selectedLanguage.id
                          )}.png`}
                          alt={selectedLanguage.name}
                        />
                      </div>
                    }
                  >
                    {languages.map((item) => item)}
                  </SubMenu>
                )}
              </Menu>
            </>
          )}
        </div>
      </Row>
    </Header>
  );
};

export default Nav;
