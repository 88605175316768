import { combineReducers } from "redux";
import * as actionTypes from "../constants/actionTypes";
import { disableFlatNode } from "../utils";
import { Action as TypologiesAction } from "../actions/typologies";
import { Action as FilterssAction } from "../actions/filters";
import { Action as LanguagesesAction } from "../actions/language";
import { Action as ItemsAction } from "../actions/items";
import { Entities, Typology } from "../interfaces";

export type Action =
  | TypologiesAction
  | LanguagesesAction
  | FilterssAction
  | ItemsAction;

export type TypologiesState = ReturnType<typeof typologies>;

const entities = (
  state: Entities<Typology> = {
    all: [],
    byId: {}
  },
  action: Action
) => {
  switch (action.type) {
    case actionTypes.GET_FILTERS_SUCCESS:
    case actionTypes.INIT_FILTERS_SUCCESS:
      const { all, byId } = action.payload.typologies;
      return {
        all,
        byId
      };

    case actionTypes.SEARCH_ITEMS_SUCCESS:
      // se la pagina non è 0, vuol dire che stiamo paginando con gli stesso criteri di ricerca e quindi non serve ricalcolare i filtri
      const { page } = action.payload;
      if (page > 0) {
        return {
          all: state.all,
          byId: state.byId
        };
      }

      // se è una nuova ricerca disabilito i tags su cui non ho risulta
      const { item_types } = action.payload.filters;
      const { all: ids, byId: typeById } = state;
      ids.forEach(id => {
        if (typeById && typeById[id])
          typeById[id] = disableFlatNode(typeById[id], item_types) as Typology;
      });
      return {
        all: ids,
        byId: typeById
      };

    case actionTypes.SELECT_LANGUAGE:
      return {
        all: [],
        byId: {}
      };
    default:
      return state;
  }
};

const selectedTypologies = (state: string[] = [], action: Action) => {
  switch (action.type) {
    case actionTypes.INIT_TYPOLOGY:
    case actionTypes.SET_TYPOLOGY:
      const { typeIds } = action.payload;
      return typeIds;

    case actionTypes.TOGGLE_TYPOLOGY_ON_SELECTED_LIST:
      const { typeId } = action.payload;
      // let list = [...state];
      let list = [];
      // controllo se già esiste
      if (state.indexOf(typeId) > -1) {
        // se si lo rimuovo
        list = [];
        // list = state.filter(id => id !== typeId);
      } else {
        // altrimenti lo aggiungo
        list.push(typeId);
      }
      return list;

    case actionTypes.SELECT_LANGUAGE:
      return [];
    default:
      return state;
  }
};

const selectedSubtypology = (state: Typology | null = null, action: Action) => {
  switch (action.type) {
    case actionTypes.INIT_SUBTYPOLOGY:
    case actionTypes.SET_SUBTYPOLOGY:
      return action.payload?.type;
    case actionTypes.SET_TYPOLOGY:
    case actionTypes.TOGGLE_TYPOLOGY_ON_SELECTED_LIST:
      return null;
    default:
      return state;
  }
}

const typologies = combineReducers({
  entities,
  selectedSubtypology,
  selectedTypologies,
});

export default typologies;
