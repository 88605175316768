import * as actionTypes from "../constants/actionTypes";
import { Entities, Dictionary } from "../interfaces";
import { Language } from "../reducers/language";

export interface GetLanguagesAction {
  type: typeof actionTypes.GET_LANGUAGES;
}
export interface GetLanguagesSuccessAction {
  type: typeof actionTypes.GET_LANGUAGES_SUCCESS;
  payload: {
    entities: Entities<Language>;
  };
}
export interface GetLanguagesErrorAction {
  type: typeof actionTypes.GET_LANGUAGES_ERROR;
  payload: { error: string; status: number };
}
export interface SelectLanguageAction {
  type: typeof actionTypes.SELECT_LANGUAGE;
  payload: { language: Language };
}
export interface SetBrowserLanguageAction {
  type: typeof actionTypes.SET_BROWSER_LANGUAGE;
  payload: { id: string };
}
export interface GetLocaleSuccessAction {
  type: typeof actionTypes.GET_LOCALE_SUCCESS;
  payload: {
    labels: Dictionary<string>;
  };
}

export type Action =
  | GetLanguagesAction
  | GetLanguagesSuccessAction
  | GetLanguagesErrorAction
  | SelectLanguageAction
  | SetBrowserLanguageAction
  | GetLocaleSuccessAction;

export function getLanguages() {
  return {
    type: actionTypes.GET_LANGUAGES
  };
}

export function getLanguagesSuccess(entities: Entities<Language>): Action {
  return {
    type: actionTypes.GET_LANGUAGES_SUCCESS,
    payload: {
      entities
    }
  };
}

export function getLanguagesError(error: string, status: number): Action {
  return {
    type: actionTypes.GET_LANGUAGES_ERROR,
    payload: {
      error,
      status
    }
  };
}

export function selectLanguage(language: Language): Action {
  return {
    type: actionTypes.SELECT_LANGUAGE,
    payload: {
      language
    }
  };
}

export function setBrowserLanguage(id: string): Action {
  return {
    type: actionTypes.SET_BROWSER_LANGUAGE,
    payload: {
      id
    }
  };
}

export function getLocaleSuccess(labels: Dictionary<string>): Action {
  return {
    type: actionTypes.GET_LOCALE_SUCCESS,
    payload: {
      labels
    }
  };
}
