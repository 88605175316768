
import { combineReducers } from "redux";
import * as actionTypes from "../constants/actionTypes";
import { InitSearchStringAction, Action as SearchStringAction } from "../actions/searchString";
import { Action as FiltersAction } from "../actions/filters";
import { Action as LanguagesesAction } from "../actions/language";

export type Action = InitSearchStringAction | SearchStringAction | LanguagesesAction | FiltersAction;

export type SearchStringState = ReturnType<typeof searchString>;

const text = (state: string = "", action: Action) => {
  switch (action.type) {
    case actionTypes.INIT_SEARCH_STRING:
    case actionTypes.SET_SEARCH_STRING:
      const { text } = action.payload;
      return text;

    case actionTypes.SELECT_LANGUAGE:
      return "";
    default:
      return state;
  }
};

const searchString = combineReducers({
  text
});

export default searchString;
