import React from "react";

export const VideoSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="currentColor"
    viewBox="0 0 60 60.001"
  >
    <g id="video" transform="translate(-572 -704)">
      <path
        id="Rectangle_Copy_16"
        data-name="Rectangle Copy 16"
        d="M0,0H60V60H0Z"
        transform="translate(572 764) rotate(-90)"
        fill="none"
      />
      <g
        id="Group_624"
        data-name="Group 624"
        transform="translate(302.832 360.898)"
      >
        <path
          id="Path_645"
          data-name="Path 645"
          d="M299.168,343.1a30,30,0,1,0,30,30A30.036,30.036,0,0,0,299.168,343.1Zm0,3.5a26.5,26.5,0,1,1-26.5,26.5A26.53,26.53,0,0,1,299.168,346.6Z"
        />
        <path
          id="Path_646"
          data-name="Path 646"
          d="M289.356,390.087a1.951,1.951,0,0,0,.986.267,1.98,1.98,0,0,0,.984-.264L317.807,374.8a1.963,1.963,0,0,0,0-3.4l-26.477-15.289a1.967,1.967,0,0,0-2.95,1.7V388.39A1.965,1.965,0,0,0,289.356,390.087ZM313.1,373.1l-21,12.124V360.976Z"
        />
      </g>
    </g>
  </svg>
);
