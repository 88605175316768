import { combineReducers } from "redux";
import * as actionTypes from "../constants/actionTypes";
import typologies from "./typologies";
import markets from "./markets";
import searchString from "./searchString";
import categories from "./categories";
import tags from "./tags";
import properties from "./properties";
import { Action as UserAction } from "../actions/auth";
import { Action as FilterAction } from "../actions/filters";
import { Brand } from "../interfaces";
import { setLess, getTheme, Theme } from "../theme";

// export type FiltersState = {
//   +typologies: TypologiesState,
//   +markets: MarketsState,
//   +searchString: SearchStringState,
//   +categories: CategoriesState,
//   +tags: TagsState,
//   +properties: PropertiesState,
//   +isLoading: boolean,
//   +hasError: boolean,
//   +errorMessage?: string
// };
type Action = UserAction | FilterAction;
export type FiltersState = ReturnType<typeof filters>;

const isLoading = (state: boolean = false, action: Action) => {
  switch (action.type) {
    case actionTypes.GET_FILTERS:
    case actionTypes.SET_MARKET:
    case actionTypes.TOGGLE_MARKET_ON_SELECTED_LIST:
      return true;

    case actionTypes.GET_FILTERS_SUCCESS:
    case actionTypes.GET_FILTERS_ERROR:
      return false;

    default:
      return state;
  }
};

const hasError = (state: boolean = false, action: Action) => {
  switch (action.type) {
    case actionTypes.GET_FILTERS:
    case actionTypes.GET_FILTERS_SUCCESS:
      return false;

    case actionTypes.GET_FILTERS_ERROR:
      return true;
    default:
      return state;
  }
};

const errorMessage = (state: string | null = null, action: Action) => {
  switch (action.type) {
    case actionTypes.GET_FILTERS:
    case actionTypes.GET_FILTERS_SUCCESS:
      return null;

    case actionTypes.GET_FILTERS_ERROR:
      const { error } = action.payload;
      return error;
    default:
      return state;
  }
};

const selectedBrand = (state: Brand | null = null, action: Action) => {
  switch (action.type) {
    case actionTypes.SET_BRAND:
      const { brand } = action.payload;
      return brand;

    case actionTypes.LOGOUT:
      return null;
    default:
      return state;
  }
};

const selectedTheme = (state: Theme = getTheme(null), action: Action) => {
  switch (action.type) {
    case actionTypes.SET_BRAND:
      const { brand } = action.payload;
      setLess(brand.code);
      return getTheme(brand.code);

    case actionTypes.LOGOUT:
      setLess(null);
      return getTheme(null);
    default:
      return state;
  }
};

const filters = combineReducers({
  typologies,
  markets,
  searchString,
  categories,
  tags,
  properties,
  isLoading,
  hasError,
  errorMessage,
  selectedBrand,
  selectedTheme
});

export default filters;
