import * as actionTypes from "../constants/actionTypes";
import * as actions from "../actions";

import {
  catchError,
  debounceTime,
  filter,
  map,
  mapTo,
  switchMap
} from "rxjs/operators";

import { Action } from "../actions/news";
import { Epic } from "redux-observable";
import { State } from "../interfaces";
import { ajax } from "rxjs/ajax";
// import { push } from "connected-react-router";
import domain from "../utils/domain";
import { isOfType } from "typesafe-actions";
import { normalizeFlatList } from "../utils/norm";
import { of } from "rxjs";
import queryString from "query-string";

export const setFilterForNewsEpic: Epic<Action, Action> = (action$) =>
  action$.pipe(
    filter(
      isOfType([
        actionTypes.SET_SEARCH_STRING_FOR_NEWS,
        actionTypes.SET_SORT_FOR_NEWS
      ])
    ),
    debounceTime(300),
    mapTo(actions.resetPageForNews())
  );

export const resetPageForNewsEpic: Epic<Action, Action> = (action$) =>
  action$.pipe(
    filter(isOfType(actionTypes.RESET_PAGE_FOR_NEWS)),
    mapTo(actions.getNews())
  );

export const fetchNextPageForNewsEpic: Epic<Action, Action> = (action$) =>
  action$.pipe(
    filter(isOfType(actionTypes.GET_NEXT_PAGE_FOR_NEWS)),
    mapTo(actions.getNews())
  );

export const getNewsEpic: Epic<Action, Action, State> = (action$, state$) =>
  action$.pipe(
    filter(isOfType(actionTypes.GET_NEWS)),
    switchMap((action) => {
      const state = state$.value;
      const { auth, language, news } = state;
      const { accessToken, sessionToken } = auth;
      const { filters } = state;
      const { selectedBrand, markets } = filters;
      const { page, itemsPerPage, searchString, sortBy } = news;
      // uso forkJoin per chiamare più servizi in sequenza

      return ajax({
        crossDomain: true,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
          "X-CSRF-Token": sessionToken
        },
        url: `${domain}api/homepage/popupnews?_format=json`,
        body: {
          page,
          pageSize: itemsPerPage,
          lang: language.selectedLanguage?.id,
          brand: [selectedBrand?.id],
          markets: markets.selectedMarkets,
          searchString,
          sort: sortBy
        }
      }).pipe(
        map((results) => {
          const { news, page, total, size } = results.response;

          const entities = normalizeFlatList(news);
          return actions.getNewsSuccess(entities, page, total, size);
        }),
        catchError((error) => {
          if (error.response)
            return of(
              actions.getNewsError(error.response.message, error.status)
            );
          return of(actions.getNewsError(error.message, error.status));
        })
      );
    })
  );

export const fetchNewsyIdEpic: Epic<Action, Action, State> = (
  action$,
  state$
) =>
  action$.pipe(
    filter(isOfType(actionTypes.GET_NEWS_BY_ID)),
    debounceTime(300),
    switchMap((action) => {
      const state = state$.value;
      const { auth, language, filters } = state;
      const { accessToken, sessionToken } = auth;
      const { selectedBrand } = filters;

      const { newsID } = action.payload;

      const params = queryString.stringify({
        lang: language.selectedLanguage?.id,
        brands: [selectedBrand?.id],
        newsid: newsID
      });
      return ajax({
        crossDomain: true,
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "X-CSRF-Token": sessionToken
        },
        url: `${domain}api/news?${params}`
      }).pipe(
        map(({ response }) => {
          // gestisco il caso non ci sia item
          return actions.getNewsByIdSuccess(response);
        }),
        catchError((error) => {
          if (error.response)
            return of(
              actions.getNewsByIdError(error.response.message, error.status)
            );
          return of(actions.getNewsByIdError(error.message, error.status));
        })
      );
    })
  );
