import React, { useCallback } from "react";
import { shallowEqual, useSelector, useDispatch } from "react-redux";
import * as actions from "../../actions";
import { MailOutlined } from "@ant-design/icons";
import { Form, Row, Col, Input, Button } from "antd";
import { Link } from "react-router-dom";

import { AuthState } from "../../reducers/auth";
import { LanguageState } from "../../reducers/language";
import { State } from "../../interfaces";

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 24 }
};

interface Props {
  location: Object;
}

const ForgotPasswordPage: React.FC<Props> = (props) => {
  const auth: AuthState = useSelector(
    (state: State) => state.auth,
    shallowEqual
  );
  const { isFetching } = auth;

  const language: LanguageState = useSelector(
    (state: State) => state.language,
    shallowEqual
  );
  const { labels } = language;

  const dispatch = useDispatch();

  const onRecoverPassword = useCallback(
    (userName, email) => dispatch(actions.recoverPassword(userName, email)),
    [dispatch]
  );

  const onFinish = (values: any) => {
    console.log("Success:", values);
    const { userName, email } = values;
    onRecoverPassword(userName, email);
  };

  return (
    <Row className="login" align="middle">
      <Col xs={{ span: 22, offset: 1 }} sm={{ span: 8, offset: 8 }}>
        {labels && (
          <Form {...layout} onFinish={onFinish} className="login-form">
            <Form.Item
              name="email"
              rules={[
                {
                  type: "email",
                  message: `${labels.notValidMail}`
                },
                {
                  required: true,
                  message: `${labels.requiredFiels}`
                }
              ]}
            >
              <Input
                prefix={<MailOutlined style={{ fontSize: 13 }} />}
                placeholder="E-mail"
              />
            </Form.Item>
            <Form.Item>
              <Link className="login-form-forgot" to="/">
                {labels.backToLogin}
              </Link>
            </Form.Item>
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                className="login-form-button"
                loading={isFetching}
              >
                {labels.recoverPassword}
              </Button>
            </Form.Item>
          </Form>
        )}
      </Col>
    </Row>
  );
};

export default ForgotPasswordPage;
