import React from "react";

export const BestPracticesSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="currentColor"
    viewBox="0 0 60.218 60"
  >
    <path
      id="Path_655"
      data-name="Path 655"
      d="M2166.065,349.2c-13.779,0-25,10.845-25,24.165a23.385,23.385,0,0,0,3.83,12.785l-8.382,8.1a1.832,1.832,0,0,0,.011,2.652,1.957,1.957,0,0,0,.761.45l8.261,2.559,2.647,7.987a1.971,1.971,0,0,0,3.208.747l11.7-11.312a23.756,23.756,0,0,0,5.9,0l11.7,11.312a1.987,1.987,0,0,0,2.743.011,1.867,1.867,0,0,0,.481-.758l2.648-7.987,8.246-2.559a1.848,1.848,0,0,0,.788-3.1l-8.391-8.108a23.4,23.4,0,0,0,3.822-12.777C2191.046,360.044,2179.844,349.2,2166.065,349.2Zm0,3.75c11.682,0,21.1,9.121,21.1,20.415a19.865,19.865,0,0,1-3.814,11.7,1.8,1.8,0,0,0-.193.22,21.067,21.067,0,0,1-9.693,7.174l-.1.036c-.571.205-1.152.382-1.743.539-.138.036-.277.072-.417.106-.513.125-1.035.227-1.56.315a20.35,20.35,0,0,1-6.9.04l-.027,0a21.252,21.252,0,0,1-13.761-8.207,1.941,1.941,0,0,0-.178-.2,19.841,19.841,0,0,1-3.83-11.715C2144.948,362.07,2154.383,352.949,2166.065,352.949Zm-.027,8.627a1.94,1.94,0,0,0-1.776,1.176l-2.47,5.958-6.632.439a1.9,1.9,0,0,0-1.8,2,1.853,1.853,0,0,0,.688,1.31l5.106,4.116-1.628,6.237a1.87,1.87,0,0,0,1.4,2.279,1.994,1.994,0,0,0,1.515-.232l5.629-3.424,5.633,3.424a1.977,1.977,0,0,0,2.671-.6,1.819,1.819,0,0,0,.234-1.449l-1.625-6.229,5.1-4.116a1.832,1.832,0,0,0,.246-2.641,1.963,1.963,0,0,0-1.356-.67l-6.632-.435-2.473-5.966A1.944,1.944,0,0,0,2166.038,361.576Zm.027,6.907,1.126,2.721a1.936,1.936,0,0,0,1.673,1.172l3.024.2-2.331,1.879a1.838,1.838,0,0,0-.632,1.9l.743,2.838-2.569-1.556a2,2,0,0,0-2.064,0l-2.571,1.557.741-2.838a1.84,1.84,0,0,0-.643-1.9l-2.329-1.882,3.038-.2a1.938,1.938,0,0,0,1.659-1.164Zm-18.841,20.7a25.136,25.136,0,0,0,11.368,7.226l-7.674,7.419-1.989-5.98a1.9,1.9,0,0,0-1.246-1.2l-6.186-1.923Zm37.67.007,5.723,5.53-6.17,1.923a1.91,1.91,0,0,0-1.262,1.2l-1.973,5.98-7.678-7.419a25.112,25.112,0,0,0,11.359-7.218Z"
      transform="translate(-2135.955 -349.199)"
    />
  </svg>
);
