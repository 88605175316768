interface Social {
  [key: string]: {
    [key: string]: {
      icon: string;
      name: string;
      link: string;
    }[];
  };
}
export const social = {
  caseih: {
    da: [
      {
        icon: "facebook",
        name: "Facebook",
        link: ""
      },
      {
        icon: "instagram",
        name: "Instagram",
        link: ""
      },
      {
        icon: "youtube",
        name: "Youtube",
        link: ""
      }
    ],
    de: [
      {
        icon: "facebook",
        name: "Facebook",
        link: ""
      },
      {
        icon: "instagram",
        name: "Instagram",
        link: ""
      },
      {
        icon: "youtube",
        name: "Youtube",
        link: ""
      }
    ],
    en: [
      {
        icon: "facebook",
        name: "Facebook",
        link: "https://www.facebook.com/caseih.uk.roi"
      },
      {
        icon: "instagram",
        name: "Instagram",
        link: "https://www.instagram.com/caseih.europe/"
      },
      {
        icon: "youtube",
        name: "Youtube",
        link: "https://www.youtube.com/channel/UCk4VQzbGX3cNzFN0oSXCuXg"
      }
    ],
    es: [
      {
        icon: "facebook",
        name: "Facebook",
        link: ""
      },
      {
        icon: "instagram",
        name: "Instagram",
        link: ""
      },
      {
        icon: "youtube",
        name: "Youtube",
        link: ""
      }
    ],
    fr: [
      {
        icon: "facebook",
        name: "Facebook",
        link: ""
      },
      {
        icon: "instagram",
        name: "Instagram",
        link: ""
      },
      {
        icon: "youtube",
        name: "Youtube",
        link: "https://www.youtube.com/@CaseIHFrance"
      }
    ],
    it: [
      {
        icon: "facebook",
        name: "Facebook",
        link: ""
      },
      {
        icon: "instagram",
        name: "Instagram",
        link: ""
      },
      {
        icon: "youtube",
        name: "Youtube",
        link: ""
      }
    ],
    nl: [
      {
        icon: "facebook",
        name: "Facebook",
        link: ""
      },
      {
        icon: "instagram",
        name: "Instagram",
        link: ""
      },
      {
        icon: "youtube",
        name: "Youtube",
        link: ""
      }
    ],
    pl: [
      {
        icon: "facebook",
        name: "Facebook",
        link: ""
      },
      {
        icon: "instagram",
        name: "Instagram",
        link: ""
      },
      {
        icon: "youtube",
        name: "Youtube",
        link: ""
      }
    ],
    "pt-pt": [
      {
        icon: "facebook",
        name: "Facebook",
        link: ""
      },
      {
        icon: "instagram",
        name: "Instagram",
        link: ""
      },
      {
        icon: "youtube",
        name: "Youtube",
        link: ""
      }
    ],
    sv: [
      {
        icon: "facebook",
        name: "Facebook",
        link: ""
      },
      {
        icon: "instagram",
        name: "Instagram",
        link: ""
      },
      {
        icon: "youtube",
        name: "Youtube",
        link: ""
      }
    ],
    uk: [
      {
        icon: "facebook",
        name: "Facebook",
        link: ""
      },
      {
        icon: "instagram",
        name: "Instagram",
        link: ""
      },
      {
        icon: "youtube",
        name: "Youtube",
        link: ""
      }
    ]
  },
  nha: {
    da: [
      {
        icon: "facebook",
        name: "Facebook",
        link: ""
      },
      {
        icon: "instagram",
        name: "Instagram",
        link: ""
      },
      {
        icon: "youtube",
        name: "Youtube",
        link: ""
      }
    ],
    de: [
      {
        icon: "facebook",
        name: "Facebook",
        link: ""
      },
      {
        icon: "instagram",
        name: "Instagram",
        link: ""
      },
      {
        icon: "youtube",
        name: "Youtube",
        link: ""
      }
    ],
    en: [
      {
        icon: "facebook",
        name: "Facebook",
        link: "https://www.facebook.com/NHAgriUKandROI"
      },
      {
        icon: "instagram",
        name: "Instagram",
        link: "https://www.instagram.com/newhollandag/"
      },
      {
        icon: "youtube",
        name: "Youtube",
        link: "https://www.youtube.com/user/NewHollandAG"
      }
    ],
    es: [
      {
        icon: "facebook",
        name: "Facebook",
        link: ""
      },
      {
        icon: "instagram",
        name: "Instagram",
        link: ""
      },
      {
        icon: "youtube",
        name: "Youtube",
        link: ""
      }
    ],
    fr: [
      {
        icon: "facebook",
        name: "Facebook",
        link: ""
      },
      {
        icon: "instagram",
        name: "Instagram",
        link: "https://www.instagram.com/newhollandagfr/"
      },
      {
        icon: "youtube",
        name: "Youtube",
        link: ""
      }
    ],
    it: [
      {
        icon: "facebook",
        name: "Facebook",
        link: ""
      },
      {
        icon: "instagram",
        name: "Instagram",
        link: ""
      },
      {
        icon: "youtube",
        name: "Youtube",
        link: ""
      }
    ],
    nl: [
      {
        icon: "facebook",
        name: "Facebook",
        link: ""
      },
      {
        icon: "instagram",
        name: "Instagram",
        link: ""
      },
      {
        icon: "youtube",
        name: "Youtube",
        link: ""
      }
    ],
    pl: [
      {
        icon: "facebook",
        name: "Facebook",
        link: ""
      },
      {
        icon: "instagram",
        name: "Instagram",
        link: ""
      },
      {
        icon: "youtube",
        name: "Youtube",
        link: ""
      }
    ],
    "pt-pt": [
      {
        icon: "facebook",
        name: "Facebook",
        link: ""
      },
      {
        icon: "instagram",
        name: "Instagram",
        link: ""
      },
      {
        icon: "youtube",
        name: "Youtube",
        link: ""
      }
    ],
    sv: [
      {
        icon: "facebook",
        name: "Facebook",
        link: ""
      },
      {
        icon: "instagram",
        name: "Instagram",
        link: ""
      },
      {
        icon: "youtube",
        name: "Youtube",
        link: ""
      }
    ],
    uk: [
      {
        icon: "facebook",
        name: "Facebook",
        link: ""
      },
      {
        icon: "instagram",
        name: "Instagram",
        link: ""
      },
      {
        icon: "youtube",
        name: "Youtube",
        link: ""
      }
    ]
  }
} as Social;
