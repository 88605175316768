import React from "react";

export const ImageSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="currentColor"
    viewBox="0 0 60 60"
  >
    <g id="image" transform="translate(-6351 782)">
      <g id="Group_607" data-name="Group 607">
        <path
          id="Rectangle_Copy_16"
          data-name="Rectangle Copy 16"
          d="M0,0H60V60H0Z"
          transform="translate(6351 -722) rotate(-90)"
          fill="none"
        />
        <g
          id="Group_581"
          data-name="Group 581"
          transform="translate(6313 -1064.874)"
        >
          <path
            id="Path_464"
            data-name="Path 464"
            d="M93.477,297.666H83.087c-2.67,0-2.768-2.706-2.771-3.012v-1.505c0-1.9-1.926-4.518-5.542-4.518H60.965c-4.066,0-5.542,2.7-5.542,4.518v1.5c0,.124-.066,3.021-2.813,3.021H42.221a3.627,3.627,0,0,0-3.463,3.764v27.1a3.627,3.627,0,0,0,3.463,3.764h8.311a.755.755,0,0,0,0-1.505H42.221a2.177,2.177,0,0,1-2.078-2.259V309.711H51.225a20.955,20.955,0,0,0-1.385,7.528c0,10.793,8.078,19.574,18.008,19.574s18.008-8.781,18.008-19.574a20.957,20.957,0,0,0-1.388-7.528H95.554v18.821a2.177,2.177,0,0,1-2.077,2.259h-9.7a.755.755,0,0,0,0,1.505h9.7a3.628,3.628,0,0,0,3.463-3.764v-27.1A3.628,3.628,0,0,0,93.477,297.666ZM67.848,335.308c-9.167,0-16.624-8.1-16.624-18.068s7.457-18.068,16.624-18.068,16.624,8.1,16.624,18.068S77.015,335.308,67.848,335.308Zm16.277-27.1a.62.62,0,0,0-.284.07c-3-6.292-9.035-10.61-15.992-10.61s-12.994,4.318-15.991,10.61a.629.629,0,0,0-.286-.07H40.143V301.43a2.177,2.177,0,0,1,2.078-2.259h10.39c3.28,0,4.182-2.948,4.2-4.517v-1.505c0-1.212,1.106-3.012,4.156-3.012h13.81c2.9,0,4.156,1.993,4.156,3.012v1.505c0,1.563.869,4.517,4.157,4.517h10.39a2.177,2.177,0,0,1,2.077,2.259v6.776Zm-16.277-4.518c-6.875,0-12.467,6.081-12.467,13.552s5.592,13.552,12.467,13.552,12.468-6.081,12.468-13.552S74.723,303.688,67.848,303.688Zm0,25.6c-6.11,0-11.082-5.4-11.082-12.045s4.972-12.045,11.082-12.045,11.082,5.4,11.082,12.045S73.959,329.285,67.848,329.285Z"
            transform="translate(0.152 0.151)"
          />
          <path
            id="Path_465"
            data-name="Path 465"
            d="M68,337.874c-10.43,0-18.918-9.195-18.918-20.5a21.914,21.914,0,0,1,.98-6.519H41.2v17.776a1.223,1.223,0,0,0,1.17,1.268h8.312a1.741,1.741,0,0,1,0,3.47H42.373A4.572,4.572,0,0,1,38,328.634V301.617a4.572,4.572,0,0,1,4.373-4.738H52.764a1.883,1.883,0,0,0,1.9-2.045v-1.473c0-2.205,1.718-5.487,6.451-5.487h13.81c4.206,0,6.449,3.187,6.449,5.487v1.5a1.826,1.826,0,0,0,1.862,2.017H93.627A4.572,4.572,0,0,1,98,301.617v27.017a4.572,4.572,0,0,1-4.373,4.738h-9.7a1.741,1.741,0,0,1,0-3.47h9.7a1.223,1.223,0,0,0,1.17-1.268V310.857H85.933a21.854,21.854,0,0,1,.985,6.519C86.918,328.679,78.43,337.874,68,337.874Zm0-37.523c-8.665,0-15.715,7.637-15.715,17.026S59.335,334.4,68,334.4s15.715-7.637,15.715-17.026S76.665,300.351,68,300.351Zm16.5,7.035H94.8v-5.769a1.223,1.223,0,0,0-1.17-1.266H83.239c-3.716,0-5.066-3.282-5.066-5.488v-1.5c0-.426-.811-2.015-3.245-2.015H61.118c-2.368,0-3.248,1.236-3.248,2.015v1.5c-.024,2.213-1.4,5.488-5.106,5.488H42.373a1.223,1.223,0,0,0-1.17,1.266v5.769H51.5a18.207,18.207,0,0,1,33,0ZM68,331.871c-7.374,0-13.376-6.5-13.376-14.495s6-14.494,13.376-14.494,13.376,6.5,13.376,14.494S75.374,331.871,68,331.871Zm0-25.516c-5.609,0-10.172,4.943-10.172,11.022S62.391,328.4,68,328.4s10.172-4.943,10.172-11.022S73.609,306.355,68,306.355Z"
            transform="translate(0)"
          />
        </g>
      </g>
    </g>
  </svg>
);
