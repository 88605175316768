import React from "react";

export const NewsSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="currentColor"
    viewBox="0 0 60 60"
  >
    <g id="news" transform="translate(-476 -596)">
      <path
        id="Rectangle_Copy_16"
        data-name="Rectangle Copy 16"
        d="M0,0H60V60H0Z"
        transform="translate(476 656) rotate(-90)"
        fill="none"
      />
      <path
        id="Path_636"
        data-name="Path 636"
        d="M1815.513,363.6c-.851-8.468-2.3-12.421-4.676-12.726-.068-.01-.136-.016-.205-.02-.032,0-.062-.009-.095-.009s-.047.006-.071.007a1.793,1.793,0,0,0-.218.022,2.329,2.329,0,0,0-1.122.457l-40.946,21.229-9.2-4.4a1.936,1.936,0,0,0-2.774,1.747V388.29a1.937,1.937,0,0,0,2.774,1.751l9.2-4.4,5.562,2.884a10.295,10.295,0,0,0,9.818,13.415,10.227,10.227,0,0,0,8.193-4.077l17.4,9.023a2.327,2.327,0,0,0,1.077.439,1.761,1.761,0,0,0,.229.025c.025,0,.048.007.073.007.052,0,.1-.008.15-.01.029,0,.058,0,.086-.006,2.2-.171,3.387-2.76,4.323-9.358a141.6,141.6,0,0,0,1.111-18.88C1816.206,377.087,1816.157,370.014,1815.513,363.6ZM1792.1,394.227l-.007,0-.006,0-15.384-7.977c-.019-.011-.035-.026-.054-.037s-.064-.025-.095-.04l-7.2-3.731v-6.669l37.965-19.684a62.268,62.268,0,0,0-1.01,6.843c-.652,6.5-.723,13.476-.723,16.176,0,11.865.821,18.884,1.768,23.036Zm-15.265-4.184,11.849,6.144a6.922,6.922,0,0,1-11.849-6.144Zm-10.154-7.379-7,3.348V372.193l7,3.349Zm41.857-3.562c0-11.983,1.015-20.7,2-24.894.987,4.2,2,12.912,2,24.894s-1.013,20.7-2,24.9C1809.55,399.8,1808.536,391.084,1808.536,379.1Z"
        transform="translate(-1280.206 247.151)"
      />
    </g>
  </svg>
);
