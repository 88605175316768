import * as actionTypes from "../constants/actionTypes";
import * as actions from "../actions";

import { CategoryEntities, State } from "../interfaces";
import { catchError, filter, map, mergeMap } from "rxjs/operators";
import { forkJoin, of } from "rxjs";
import {
  normalizeFlatList,
  normalizeTreeData,
  normalizeTreeDataAll
} from "../utils/norm";

import { Epic } from "redux-observable";
import { Action as FilterAction } from "../actions/filters";
import { Action as ForumsAction } from "../actions/forums";
import { Action as NewsAction } from "../actions/news";
import { ajax } from "rxjs/ajax";
import domain from "../utils/domain";
import { findSubtypeById } from "../utils";
import { isOfType } from "typesafe-actions";
import language from "../reducers/language";

export type Action = FilterAction | NewsAction | ForumsAction;
export const setBrandEpic: Epic<Action, Action, State> = (action$, state$) =>
  action$.pipe(
    filter(isOfType(actionTypes.SET_BRAND)),
    mergeMap((action) => {
      return of(actions.getFilters(), actions.getNews());
    })
  );

const fetchMarkets = () => (action: any, state: State) => {
  const { auth, language, filters } = state;
  const { selectedBrand } = filters;
  const { accessToken } = auth;

  return ajax({
    crossDomain: true,
    responseType: "json",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`
    },
    url: `${domain}api/item/item_markets?_format=json&lang=${
      language.selectedLanguage?.id
    }&brands=${[selectedBrand?.id]}`
  });
};

const getSelectedMarkets = (state: State) => {
  const { filters } = state;
  const markets = filters.markets.selectedMarkets;
  const _markets = markets.length > 0 ? markets : "";
  // console.log("markets ", _markets);
  return _markets;
};

const fetchTypes = () => (action: any, state: State) => {
  const { auth, language, filters } = state;
  const { selectedBrand } = filters;
  const { accessToken } = auth;
  return ajax({
    crossDomain: true,
    responseType: "json",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`
    },
    url: `${domain}api/item/item_types?_format=json&lang=${
      language.selectedLanguage?.id
    }&brands=${[selectedBrand?.id]}&market=${getSelectedMarkets(state)}&multilevel=1`
  });
};

const fetchCategories = () => (action: any, state: State) => {
  /*
      categorie
      Parameters:
      types
      market
  
      depth - default 0 (the whole branch)
    */
  const { auth, language, filters } = state;
  const { selectedBrand } = filters;
  const { accessToken } = auth;

  return ajax({
    crossDomain: true,
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`
    },
    url: `${domain}api/item/item_categories?_format=json&lang=${
      language.selectedLanguage?.id
    }&brands=${[selectedBrand?.id]}&market=${getSelectedMarkets(state)}`
  });
};

const fetchTags = () => (action: any, state: State) => {
  /*
      tags
      Parameters:
      types
      market
  
      depth - default 0 (the whole branch)
    */

  const { auth, language, filters } = state;
  const { selectedBrand } = filters;
  const { accessToken } = auth;
  return ajax({
    crossDomain: true,
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`
    },
    url: `${domain}api/item/item_tags?_format=json&lang=${
      language.selectedLanguage?.id
    }&brands=${[selectedBrand?.id]}&market=${getSelectedMarkets(
      state
    )}&all=${true}`
  });
};

const fetchProperties = () => (action: any, state: State) => {
  /*
      tags
      Parameters:
      types
      market
  
      depth - default 0 (the whole branch)
    */

  const { auth, language, filters } = state;
  const { selectedBrand } = filters;
  const { accessToken } = auth;
  return ajax({
    crossDomain: true,
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`
    },
    url: `${domain}api/item/item_properties?_format=json&lang=${
      language.selectedLanguage?.id
    }&brands=${[selectedBrand?.id]}&market=${getSelectedMarkets(state)}`
  });
};

export const getFiltersEpic: Epic<Action, Action, State> = (action$, state$) =>
  action$.pipe(
    filter(
      isOfType([
        actionTypes.GET_FILTERS,
        actionTypes.SET_MARKET,
        actionTypes.TOGGLE_MARKET_ON_SELECTED_LIST
      ])
    ),
    mergeMap((action) => {
      // uso forkJoin per chiamare più servizi in sequenza
      return forkJoin(
        fetchMarkets()(action, state$.value),
        fetchTypes()(action, state$.value),
        fetchCategories()(action, state$.value),
        fetchTags()(action, state$.value),
        fetchProperties()(action, state$.value)
      ).pipe(
        mergeMap((results) => {
          // forkJoin ritorna un array con i risultati delle chiamate precedenti
          const [markets, typologies, categories, tags, properties] = results;
          const _typologies = normalizeFlatList(typologies.response.item_type);
          const _markets = normalizeFlatList(markets.response.market);
          const _categories: CategoryEntities = normalizeTreeData(
            categories.response
          );
          const allById = normalizeTreeDataAll(categories.response);
          //aggiungo anche la struttura flat
          _categories.allById = allById.byId ?? {};
          const _tags = normalizeFlatList(tags.response);
          const _properties = normalizeTreeData(properties.response);
          const _language = state$.value.language.selectedLanguage?.code;

          const axs = [actions.initFiltersSuccess(
            _markets,
            _typologies,
            _categories,
            _tags,
            _properties
          )];
          // console.log("markets ", _markets);
          let ls = localStorage.getItem("initFilters");
          if(ls) {
            const initFilters = JSON.parse(ls);
            localStorage.removeItem("initFilters");
            const validMarketIds = (initFilters.market || []).filter((marketId: string) => _markets.byId[marketId]);
            validMarketIds.length && axs.push(actions.initMarket(validMarketIds));
            const type = _typologies.byId[initFilters.typology[0]];
            type && axs.push(actions.initTypology(initFilters.typology))
            if(type && initFilters.subTypology) {
              const sub = findSubtypeById(type, initFilters.subTypology)
              sub && axs.push(actions.initSubTypology(sub))
            }
            if(!!initFilters?.search){
              axs.push(actions.initSearchString(initFilters?.search))
            }
          }
          axs.push(actions.getFiltersSuccess(
            _markets,
            _typologies,
            _categories,
            _tags,
            _properties
          ))
          return of(...axs);
        }),
        catchError((error) => {
          if (error.response)
            return of(
              actions.getFiltersError(error.response.message, error.status)
            );
          return of(actions.getFiltersError(error.message, error.status));
        })
      );
    })
  );
