import React from "react";
import Icon from "@ant-design/icons";
import { FaFacebookSquare, FaYoutube, FaInstagram } from "react-icons/fa";

import { ImageSvg } from "./icons/image";
import { CustomerTestimonialSvg } from "./icons/customerTestimonial";
import { IstitutionalMaterialSvg } from "./icons/istitutionalMaterial";
import { NewsSvg } from "./icons/news";
import { TemplateSvg } from "./icons/template";
import { VideoSvg } from "./icons/video";
import { SocialMediaSvg } from "./icons/socialMedia";
import { BestPracticesSvg } from "./icons/bestPractices";
import pressKit from "./icons/pressKit";
import sellOutDays from "./icons/sellOutDays";

interface Props {
  type: string | null;
}

const DynamicIcon: React.FC<Props> = (props) => {
  const { type } = props;
  switch (type) {
    case "picture":
      return <Icon component={ImageSvg} className="icon" />;

    case "video-camera":
      return <Icon component={VideoSvg} className="icon" />;

    case "user":
      return <Icon component={CustomerTestimonialSvg} className="icon" />;

    case "copy":
      return <Icon component={sellOutDays} className="icon" />;

    case "calendar":
      return <Icon component={BestPracticesSvg} className="icon" />;
    // return <Icon component={PosterSvg} className="icon" />;

    case "credit-card":
      return <Icon component={SocialMediaSvg} className="icon" />;
    // return <Icon component={FbBannerSvg} className="icon" />;

    case "folder-open":
      return <Icon component={IstitutionalMaterialSvg} className="icon" />;

    case "news":
      return <Icon component={NewsSvg} className="icon" />;

    case "book":
      return <Icon component={TemplateSvg} className="icon" />;

    case "facebook":
      return <FaFacebookSquare className="social-icon" />;

    case "instagram":
      return <FaInstagram className="social-icon" />;

    case "youtube":
      return <FaYoutube className="social-icon" />;

    default:
      return <Icon component={pressKit} className="icon" />;
  }
};

export default DynamicIcon;
