import React from "react";
import logoCaseIH from "../../images/caseih-app-logo.png";
import logoNHA from "../../images/nha-app-logo.png";
import { Brand } from "../../interfaces";

interface Props {
  brand: Brand | null;
}
const Logo: React.FC<Props> = (props) => {
  const { brand } = props;
  switch (brand?.code) {
    case "caseih":
      return <img src={logoCaseIH} alt="" className="logoBrandApp" />;
    case "nha":
      return <img src={logoNHA} alt="" className="logoBrandApp" />;

    default:
      return null;
  }
};

export default Logo;
