import { Category, CategoryAll } from "../reducers/categories";

import { AuthState } from "../reducers/auth";
import { FiltersState } from "../reducers/filters";
import { ForumsState } from "./../reducers/forums";
import { HomeState } from "../reducers/home";
import { ItemsState } from "../reducers/items";
import { LanguageState } from "../reducers/language";
import { NewsState } from "./../reducers/news";
import { ShareState } from "../reducers/share";
import { ShareLibraryState } from "../reducers/shareLibrary";
import { compose } from "redux";

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
    less: any;
  }
}

export enum MinWidth {
  XS = "(min-width: 575px)",
  SM = "(min-width: 576px)",
  MD = "(min-width: 768px)",
  LG = "(min-width: 992px)",
  XL = "(min-width: 1200px)",
  XXL = "(min-width: 1600px)"
}

export enum MaxWidth {
  XS = "(max-width: 575px)",
  SM = "(max-width: 576px)",
  MD = "(max-width: 768px)",
  LG = "(max-width: 992px)",
  XL = "(max-width: 1200px)",
  XXL = "(max-width: 1600px)"
}

export interface State {
  language: LanguageState;
  auth: AuthState;
  filters: FiltersState;
  items: ItemsState;
  share: ShareState;
  shareLibrary: ShareLibraryState;
  news: NewsState;
  forums: ForumsState;
  home: HomeState;
}

export interface Dictionary<T> {
  [Key: string]: T;
}

export interface Entities<T> {
  all: string[];
  byId: Dictionary<T>;
}

export interface CategoryEntities extends Entities<Category> {
  allById?: Dictionary<CategoryAll>;
}

export interface Brand {
  id: string;
  name: string;
  code: "caseih" | "nha";
}
export interface Market {
  id: string;
  name: string;
}

export interface Property {
  key: string;
  value: string;
  description: string;
  label: string;
  code: string;
  disabled?: boolean;
  children?: Property[];
}

export interface Typology {
  subtypes: Typology[]
  id: string;
  name: string;
  description: string;
  icon: string;
  code: string;
  disabled?: boolean;
  count?: number;
  isLeaf?: boolean
}

export interface Tag {
  id: string;
  name: string;
  count: string;
  disabled?: boolean;
}

export interface SelectedById {
  [Key: string]: string[];
}

export enum NewsSort {
  DATE_ASC = "date_asc",
  DATE_DESC = "date_desc"
}

export enum BrandCode {
  CASEIH = "caseih",
  NHA = "nha"
}

export interface News {
  id: string;
  title: string;
  subtitle: string;
  body: string;
  date: string;
  thumbUrl: string;
  brand: any[] | null;
  category: any[] | null;
  sticky: boolean;
  new: boolean;
}

export interface Forums {
  id: string;
  title: string;
  title_short: string;
  body: string;
  is_new: boolean;
  url: string;
}

export interface DetailsParamTypes {
  itemId: string;
}

export interface Banner {
  titolo: string;
  banner: string;
  url: string;
}