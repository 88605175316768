import React from "react";

export const CustomerTestimonialSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="currentColor"
    viewBox="0 0 60 60"
  >
    <g id="customer_testimonial" transform="translate(-573 -596)">
      <g
        id="customer_testimonial-2"
        data-name="customer_testimonial"
        transform="translate(-5963 1377)"
      >
        <path
          id="Rectangle_Copy_16"
          data-name="Rectangle Copy 16"
          d="M0,0H60V60H0Z"
          transform="translate(6536 -721) rotate(-90)"
          fill="none"
        />
      </g>
      <g
        id="Group_618"
        data-name="Group 618"
        transform="translate(111.573 252.53)"
      >
        <path
          id="Path_637"
          data-name="Path 637"
          d="M496.369,385.711a7.742,7.742,0,0,0,2.693-5.856,7.89,7.89,0,0,0-15.78,0,7.744,7.744,0,0,0,2.691,5.856,13.7,13.7,0,0,0-8.2,9.9,1.784,1.784,0,0,0,1.749,2.139h23.307a1.784,1.784,0,0,0,1.749-2.139A13.7,13.7,0,0,0,496.369,385.711Zm-5.2-10.671a5.019,5.019,0,0,1,5,4.9,4.96,4.96,0,0,1-5,4.9,4.9,4.9,0,1,1,0-9.8Zm-10,19.394a10.974,10.974,0,0,1,20,0Z"
        />
        <path
          id="Path_638"
          data-name="Path 638"
          d="M477.934,369.706l-.873-5.059,3.68-3.493a1.46,1.46,0,0,0-.794-2.5l-5.073-.754-2.252-4.568a1.429,1.429,0,0,0-1.308-.762h0a1.44,1.44,0,0,0-1.314.765l-2.249,4.565-5.073.754a1.461,1.461,0,0,0-.791,2.506l3.676,3.49-.871,5.059a1.459,1.459,0,0,0,2.121,1.541l4.5-2.373,4.5,2.373a1.459,1.459,0,0,0,2.123-1.541Zm-3.451-6.642a1.463,1.463,0,0,0-.437,1.314l.5,2.9-2.557-1.349a1.453,1.453,0,0,0-1.362,0l-2.553,1.348.5-2.9a1.459,1.459,0,0,0-.432-1.31l-2.069-1.963,2.857-.424a1.466,1.466,0,0,0,1.1-.8l1.276-2.594,1.283,2.6a1.452,1.452,0,0,0,1.094.8l2.86.425Z"
        />
        <path
          id="Path_639"
          data-name="Path 639"
          d="M521.358,359.48a1.449,1.449,0,0,0-1.179-1l-5.073-.754-2.252-4.568a1.43,1.43,0,0,0-1.308-.762h0a1.441,1.441,0,0,0-1.314.765l-2.249,4.565-5.073.754a1.46,1.46,0,0,0-.79,2.506l3.676,3.49-.872,5.059a1.46,1.46,0,0,0,2.121,1.541l4.5-2.373,4.5,2.373a1.459,1.459,0,0,0,2.122-1.541l-.873-5.059,3.68-3.493A1.454,1.454,0,0,0,521.358,359.48Zm-6.643,3.412a1.463,1.463,0,0,0-.437,1.314l.5,2.9-2.557-1.349a1.453,1.453,0,0,0-1.362,0l-2.553,1.348.5-2.9a1.46,1.46,0,0,0-.432-1.31l-2.069-1.963,2.857-.424a1.467,1.467,0,0,0,1.1-.8l1.276-2.6,1.283,2.6a1.452,1.452,0,0,0,1.094.8l2.861.425Z"
        />
        <path
          id="Path_640"
          data-name="Path 640"
          d="M486.168,360.55l-.871,5.059a1.459,1.459,0,0,0,2.121,1.541l4.5-2.373,4.5,2.373a1.459,1.459,0,0,0,2.123-1.541l-.873-5.059,3.68-3.493a1.461,1.461,0,0,0-.795-2.5l-5.073-.754-2.251-4.568a1.429,1.429,0,0,0-1.308-.762h0a1.44,1.44,0,0,0-1.314.765l-2.25,4.565-5.073.754a1.46,1.46,0,0,0-.79,2.506Zm3.37-3.968a1.464,1.464,0,0,0,1.1-.8l1.277-2.594,1.283,2.6a1.451,1.451,0,0,0,1.093.795l2.861.425-2.064,1.961a1.463,1.463,0,0,0-.437,1.314l.5,2.9-2.557-1.35a1.456,1.456,0,0,0-1.362,0l-2.553,1.348.5-2.9a1.457,1.457,0,0,0-.433-1.31l-2.068-1.963Z"
        />
      </g>
    </g>
  </svg>
);
