import * as actions from "../../actions";

import { Button, Drawer, Empty, Layout } from "antd";
import { MaxWidth, State } from "../../interfaces";
import React, { useCallback, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";

import EditModal from "../../components/repo/editmodal";
import { EditOutlined, ShareAltOutlined } from "@ant-design/icons";
import { FaFilter } from "react-icons/fa";
import { FiltersState } from "../../reducers/filters";
import { ItemsState } from "../../reducers/items";
import { LanguageState } from "../../reducers/language";
import { LibraryBreadcrumb } from "../../components/library/LibraryBreadcrumb";
import LibraryList from "../../components/library/LibraryList";
import SearchPanel from "../../components/repo/searchpanel";
import ShareModal from "../../components/repo/share/modal";
import { ShareState } from "../../reducers/share";
import Title from "antd/lib/typography/Title";
import { useMedia, useUnmount } from "react-use";
import { ShareLibraryState } from "../../reducers/shareLibrary";

const { Content, Header } = Layout;

const sidebar = (
  <div className="search-panel">
    <SearchPanel />
  </div>
);

const Library = (props: Object) => {
  // useWhyDidYouUpdate("Home", props);
  const filters: FiltersState = useSelector(
    (state: State) => state.filters,
    shallowEqual
  );
  const { isLoading: isLoadingFilters, selectedTheme } = filters;

  const language: LanguageState = useSelector(
    (state: State) => state.language,
    shallowEqual
  );
  const { labels } = language;

  const items: ItemsState = useSelector(
    (state: State) => state.items,
    shallowEqual
  );
  const {
    entities,
    total,
    editMode,
    showModal: showEditModal,
    userCanEdit
  } = items;

  const share: ShareState = useSelector(
    (state: State) => state.share,
    shallowEqual
  );

  const { showModal: showShareModal } = share;

  const dispatch = useDispatch();

  const onToggleEditMode = useCallback(
    () => dispatch(actions.toggleEditMode()),
    [dispatch]
  );

  const [open, setOpen] = useState(false);
  const isSmall = useMedia(MaxWidth.MD);

  const onClose = () => {
    setOpen(false);
  };

  const onToggleMenu = () => {
    setOpen((open) => !open);
  };

  const hasFilters = filters.typologies.selectedTypologies.length || filters.searchString.text?.length;

  const hasAnyFiltersAndMarkets = filters.typologies.selectedTypologies.length > 0 || filters.searchString.text?.length > 0 || filters.markets.selectedMarkets.length > 0;

  const baseId = filters.typologies.selectedTypologies[0];
  const isLeaf = filters.typologies.selectedSubtypology
    ? filters.typologies.selectedSubtypology?.isLeaf
    : filters.typologies.entities.byId[baseId]?.isLeaf;

  const showList = Boolean(labels && hasFilters); // && !isLoadingFilters
  const showCounter = false;
  const showEdit = isLeaf && Boolean(total) && userCanEdit;

  useUnmount(() => {
    // se la nuova pagine è details non pulisco i filtri
    if (window.location.href.includes("/details/")) { return; }

    const hasSearchString = filters.searchString.text != "";
    if (hasSearchString) {
      dispatch(actions.setSearchString(""));
    }

    if(filters.typologies.selectedTypologies.length){
      dispatch(actions.setTypology([]));
    }
    if(filters.typologies.selectedSubtypology){
      dispatch(actions.setSubTypology(null));
    }
    if(filters.markets.selectedMarkets.length){
      dispatch(actions.setMarket([]));
    }
  });

  const shareLibrary: ShareLibraryState = useSelector(
    (state: State) => state.shareLibrary,
    shallowEqual
  );

  const onShareLibraryHandler = () => {
    dispatch(actions.getSessionToken(actions.getSharedLibraryLink(window.location.href)));
  }

  return (
    <>
      <Layout className="main-layout">
        <Content className={"gallery " + (isSmall ? "gallery-mobile" : "")}>
          {labels && (
            <Header
              className={"grid-header " + (isSmall ? "grid-header-mobile" : "")}
            >
              {isSmall && ( // mobile of search panel
                <Button className="menu-btn" onClick={onToggleMenu}>
                  <FaFilter />
                </Button>
              )}
              {showCounter && (
                <span style={{ flex: 1 }}>
                  {entities.all.length}/{total} {labels.itemsFound}
                </span>
              )}
              <div className="wrapper-library-breadcrumb">
                <LibraryBreadcrumb />
                {hasAnyFiltersAndMarkets &&
                  <Button
                    onClick={onShareLibraryHandler}
                    loading={shareLibrary.isLoading}
                  >
                    <ShareAltOutlined />
                  </Button>
                }
              </div>

              {showEdit && (
                <Button onClick={onToggleEditMode}>
                  <EditOutlined />{" "}
                  {editMode ? `${labels.cancelEdit}` : `${labels.edit}`}
                </Button>
              )}
            </Header>
          )}
          {!hasFilters ? (
            <Empty 
              image={false}
              description={
                <Title level={2} style={{ color: selectedTheme.primary }}>
                  {labels.selectTypologyMarket}
                </Title>
              }
              className="filters-label"
              style={{
                display: "flex",
                flexDirection: "column",
                height: "90vh",
                justifyContent: "center",
                alignContent: "center",
                alignItems: "center"
              }}
            />
          ) : null}
          {showList && <LibraryList labels={labels} />}
          {showShareModal && <ShareModal />}
          {showEditModal && <EditModal />}
        </Content>
      </Layout>
      <Drawer
        className={isSmall ? "mobile" : "desktop"}
        style={{ overflow: "auto" }}
        getContainer={false}
        mask={isSmall}
        closable={false}
        placement="left"
        visible={open || !isSmall}
        width={250}
        maskClosable={isSmall}
        onClose={onClose}
      >
        {sidebar}
      </Drawer>
    </>
  );
};

export default Library;
