import * as actionTypes from "../constants/actionTypes";
import { Banner } from "../interfaces";

export interface GetHomeBannersAction {
  type: typeof actionTypes.GET_HOME_BANNERS;
}

export interface SetHomeBannersAction {
  type: typeof actionTypes.SET_HOME_BANNERS;
  payload: { banners: Array<Banner> };
}

export interface GetHomeBannersSuccessAction {
  type: typeof actionTypes.GET_HOME_BANNERS_SUCCESS;
  payload: { banners: Array<Banner> };
}

export interface GetHomeBannersErrorAction {
  type: typeof actionTypes.GET_HOME_BANNERS_ERROR;
  payload: { error: string; status: number };
}

export type Action =
  | GetHomeBannersAction
  | SetHomeBannersAction
  | GetHomeBannersSuccessAction
  | GetHomeBannersErrorAction;

export function setHomeBanners(banners: Array<Banner>): Action {
  return {
    type: actionTypes.SET_HOME_BANNERS,
    payload: { banners }
  };
}

export function getHomeBanners(): Action {
  return {
    type: actionTypes.GET_HOME_BANNERS
  };
}

export function getHomeBannersSuccess(banners: Array<Banner>): Action {
  return {
    type: actionTypes.GET_HOME_BANNERS_SUCCESS,
    payload: { banners }
  };
}

export function getHomeBannersError(error: string, status: number): Action {
  return {
    type: actionTypes.GET_HOME_BANNERS_ERROR,
    payload: {
      error,
      status
    }
  };
}
