import React, { useEffect, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';

import { AuthState } from '../../reducers/auth';
import { FiltersState } from "../../reducers/filters";
import { Loader } from '../../components/loader';
import { RouteComponentProps } from 'react-router-dom';
import { Row } from 'antd';
import { State } from '../../interfaces';
import axios from 'axios';
import domain from '../../utils/domain';
import { message } from "antd";

interface Props extends RouteComponentProps {
}

const RedirectSharedToken: React.FC<Props> = (props) => {

  const { match } = props;

  const { token } = match.params as { token: string };
  
  const auth: AuthState = useSelector(
    (state: State) => state.auth,
    shallowEqual
  );  
  const { accessToken } = auth;


  const filters: FiltersState = useSelector(
    (state: State) => state.filters,
    shallowEqual
  );
  const { isLoading } = filters;

  const [error, setError] = useState<boolean>(false);

  useEffect(() => {
    const fetchOriginalUrl = async () => {
      try {
        const response = await axios({
          method: "get",
          url: `${domain}/download/shared/${token}`,
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
          responseType: 'blob',
        });
        if (response.data) {
          let filename = getFilenameFromHeaders(response.headers);
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', filename);
          document.body.appendChild(link);
          link.click();
          message.success('Download started');     
        } else {
          message.error("Download couldn't be started. Please try again.");
        }
      } catch (error) {
        console.error('Error fetching redirect URL:', error);
        setError(true);
        message.error("Download couldn't be started. Please try again.");
      }
    };



    const CONTENT_DISPOSITION_HEADER = "content-disposition";

    const getFilenameFromHeaders = (headers: any): string => {
      const headerLine = headers[CONTENT_DISPOSITION_HEADER];
      if (!headerLine) {
        throw new Error(`could not find '${CONTENT_DISPOSITION_HEADER}' header`);
      }

      try {
        return headerLine.split("filename=")[1].replace(/"/g, "");
      } catch {
        throw new Error(`could not parse '${CONTENT_DISPOSITION_HEADER}' header`);
      }
    };



    if(accessToken){
      fetchOriginalUrl();
    }
  }, [token, accessToken]);

  if(!error && !isLoading){
    return (
      <Row align="middle" justify="center">
        <h3>
          Download started successfully
        </h3>
      </Row>
    );
  } else if(!error && isLoading){
    return (
      <Loader isLoading={isLoading} />
    );
  } else {
    return (
      <Row align="middle" justify="center">
        <h3>
          No shared item found
        </h3>
      </Row>
    );
  }
  /*
  return !error ? (
    <Loader isLoading={isLoading} />
  ) : (
    <Row align="middle" justify="center">
      <h3>
        No shared item found for <code>{window.location.pathname}</code>
      </h3>
    </Row>
  );*/
};

export default RedirectSharedToken;
