import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import configureStore from "./stores/configureStore";
import ReactGA from "react-ga";
import App from "./App";
import "./less/style.less";

const store = configureStore();
const reactGaCode = process.env.REACT_APP_GA;

ReactGA.initialize(reactGaCode ?? "");

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById("root")
);
