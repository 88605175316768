import React from "react";

export default () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="currentColor"
    viewBox="0 0 60 60"
  >
    <g id="sell_out_days" transform="translate(-2575.078 -352.721)">
      <g id="Group_625" data-name="Group 625">
        <path
          id="Path_655"
          data-name="Path 655"
          d="M2603.727,394.656l-10.213-6.081a2.875,2.875,0,0,0-2.884,0,3.032,3.032,0,0,0-1.491,2.625V403.33a3.035,3.035,0,0,0,1.537,2.634,2.964,2.964,0,0,0,1.4.369,2.924,2.924,0,0,0,1.446-.421l10.21-6.079a3.065,3.065,0,0,0,1-1,3.038,3.038,0,0,0-1-4.175Zm-2.448,2.6-8.7,5.13v-10.3Z"
        />
        <path
          id="Path_656"
          data-name="Path 656"
          d="M2618.759,388.518h0a2.606,2.606,0,0,0-2.825.172l-4.713,3.3,0,0h-2.065a2.859,2.859,0,0,0-2.856,2.856v4.839a2.859,2.859,0,0,0,2.856,2.856h2.067l4.708,3.3a2.663,2.663,0,0,0,1.546.5,2.593,2.593,0,0,0,1.231-.312,2.89,2.89,0,0,0,1.537-2.608V391.138A2.9,2.9,0,0,0,2618.759,388.518Zm-1.989,3.836v9.864l-2.812-2v-5.9Zm-6.293,3.108v3.608h-.708v-3.608Z"
        />
        <path
          id="Path_657"
          data-name="Path 657"
          d="M2595.417,386.565h17.554a1.738,1.738,0,0,0,1.7-2.082,10.678,10.678,0,0,0-5.866-7.505,6.229,6.229,0,0,0,1.722-4.284,6.336,6.336,0,0,0-12.672,0,6.234,6.234,0,0,0,1.72,4.284,10.684,10.684,0,0,0-5.865,7.506,1.739,1.739,0,0,0,1.7,2.081Zm15.079-3.473h-12.6a7.223,7.223,0,0,1,12.6,0Zm-6.3-7.493a2.84,2.84,0,1,1,2.9-2.839A2.876,2.876,0,0,1,2604.193,375.6Z"
        />
      </g>
      <path
        id="Path_658"
        data-name="Path 658"
        d="M2635.078,392.663c0-5.967-2.858-11.353-8.026-13.188a11.458,11.458,0,0,0,.276-2.5,11.041,11.041,0,0,0-3.6-8.262,9.238,9.238,0,0,0-6.171-2.411,14.409,14.409,0,0,0-13.921-13.577c-7.244,0-14.337,6.016-14.337,13.9a15.235,15.235,0,0,0,.869,5.1,19.689,19.689,0,0,0-3.7,4.507,8.052,8.052,0,0,0-2.426-.371c-4.846,0-7.588,4.678-7.588,9.936a9.986,9.986,0,0,0,1.5,5.343,13.707,13.707,0,0,0-2.881,8.484c0,7.22,5.4,13.094,12.034,13.094h41.235a6.84,6.84,0,0,0,6.549-7.084,7.387,7.387,0,0,0-2.051-5.142A14.55,14.55,0,0,0,2635.078,392.663Zm-6.731,16.371h-41.235c-4.687,0-8.5-4.22-8.5-9.407a9.877,9.877,0,0,1,2.878-7.039l1.3-1.271-1.229-1.346a6.153,6.153,0,0,1-1.572-4.171c0-3.225,1.157-6.249,4.054-6.249a4.755,4.755,0,0,1,2.356.628l1.594.9.821-1.681a15.85,15.85,0,0,1,4.55-5.614l1.187-.911-.646-1.383a11.389,11.389,0,0,1-1.076-4.862c0-5.854,5.508-10.216,10.8-10.216a10.82,10.82,0,0,1,10.4,10.616c0,.379-.019.755-.058,1.152l-.251,2.576,2.416-.594a5.7,5.7,0,0,1,4.967,1.087,7.283,7.283,0,0,1,2.685,5.726,7.534,7.534,0,0,1-.636,3.04l-.968,2.21,2.3.379c4.477.734,7.054,5.06,7.054,10.063a10.741,10.741,0,0,1-2.524,6.988l-1.559,1.824,2.109,1.062a3.45,3.45,0,0,1,1.792,3.1A3.227,3.227,0,0,1,2628.347,409.034Z"
      />
    </g>
  </svg>
);
