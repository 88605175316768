import * as actionTypes from "../constants/actionTypes";
import * as actions from "../actions";
import { Action as HomeAction } from "../actions/home";
import { Epic } from "redux-observable";
import { isOfType } from "typesafe-actions";
import { of } from "rxjs";
import { ajax } from "rxjs/ajax";
import { map, catchError, filter, switchMap } from "rxjs/operators";
import domain from "../utils/domain";
import { State, Banner } from "../interfaces";

export type Action = HomeAction;

const getHomeBanners = (action: any, state: State) => {
  const { auth, language, filters } = state;
  const { accessToken, sessionToken } = auth;
  const { selectedBrand } = filters;
  const lang = language.selectedLanguage?.id;
  const brand = selectedBrand?.id;

  return ajax({
    crossDomain: true,
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
      "X-CSRF-Token": sessionToken
    },
    url: `${domain}api/homepage/banner?lang=${lang}&brand=${brand}&_format=json`,
    body: {}
  });
};

export const getHomeEpic: Epic<Action, Action, State> = (action$, state$) =>
  action$.pipe(
    filter(isOfType([actionTypes.SET_BRAND, actionTypes.GET_HOME_BANNERS])),
    switchMap((action) => getHomeBanners(action, state$.value)),
    map((res) => {
      const banners = bannerMapper(res.response) as Array<Banner>;
      return actions.getHomeBannersSuccess(banners);
    }),
    catchError((error) => {
      const message = error.response?.message || error.message;
      return of(actions.getHomeBannersError(message, error.status));
    })
  );

const bannerMapper = (items: Array<any> = []): Array<Banner> => {
  return items.map((item) => ({
    titolo: item?.titolo,
    url: item?.url,
    banner: item?.banner
  }));
};
