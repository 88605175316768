import { combineReducers } from "redux";
import * as actionTypes from "../constants/actionTypes";
import { Action as ForumsAction } from "../actions/forums";
import { Action as FilterssAction } from "../actions/filters";
import { Action as LanguagesesAction } from "../actions/language";
import { Action as UserAction } from "../actions/auth";
import { Entities, Forums } from "../interfaces";

export type Action =
  | ForumsAction
  | LanguagesesAction
  | FilterssAction
  | UserAction;

export type ForumsState = ReturnType<typeof forums>;

const entities = (
  state: Entities<Forums> = {
    all: [],
    byId: {}
  },
  action: Action
) => {
  switch (action.type) {
    case actionTypes.GET_FORUMS_SUCCESS:
      const { entities } = action.payload;
      // const forumstate =
      //   page === 0
      //     ? applySetResult(state, entities)
      //     : applyUpdateResult(state, entities);

      return applySetResult(state, entities);

    case actionTypes.RESET_PAGE_FOR_FORUMS:
    case actionTypes.SELECT_LANGUAGE:
    case actionTypes.SET_BRAND:
    case actionTypes.LOGOUT:
      return {
        all: [],
        byId: {}
      };
    default:
      return state;
  }
};

// const page = (state: number = 0, action: Action) => {
//   switch (action.type) {
//     case actionTypes.GET_NEXT_PAGE_FOR_FORUMS:
//       const { page } = action.payload;
//       return page;

//     case actionTypes.RESET_PAGE_FOR_FORUMS:
//     case actionTypes.SELECT_LANGUAGE:
//     case actionTypes.LOGOUT:
//       return 0;

//     default:
//       return state;
//   }
// };

// const itemsPerPage = (state: number = 30, action: Action) => {
//   switch (action.type) {
//     default:
//       return state;
//   }
// };

// const total = (state: number = 0, action: Action) => {
//   switch (action.type) {
//     case actionTypes.GET_FORUMS_SUCCESS:
//       const { total } = action.payload;
//       return total;

//     case actionTypes.RESET_PAGE_FOR_FORUMS:
//     case actionTypes.SELECT_LANGUAGE:
//     case actionTypes.LOGOUT:
//       return 0;

//     default:
//       return state;
//   }
// };

const applySetResult = (
  entities: Entities<Forums>,
  newEntities: Entities<Forums>
) => {
  const { all, byId } = newEntities;
  return {
    all,
    byId
  };
};

const selectedForums = (state: Forums | null = null, action: Action) => {
  switch (action.type) {
    case actionTypes.SET_FORUM:
      const { forums } = action.payload;
      return forums;

    default:
      return state;
  }
};

const isLoading = (state: boolean = false, action: Action) => {
  switch (action.type) {
    case actionTypes.GET_FORUMS:
      return true;

    case actionTypes.RESET_PAGE_FOR_FORUMS:
    case actionTypes.GET_FORUMS_SUCCESS:
    case actionTypes.GET_FORUMS_ERROR:
      return false;

    default:
      return state;
  }
};

const hasError = (state: boolean = false, action: Action) => {
  switch (action.type) {
    case actionTypes.GET_FORUMS:
    case actionTypes.GET_FORUMS_SUCCESS:
      return false;

    case actionTypes.RESET_PAGE_FOR_FORUMS:
    case actionTypes.GET_FORUMS_ERROR:
      return true;
    default:
      return state;
  }
};

const errorMessage = (state: string | null = null, action: Action) => {
  switch (action.type) {
    case actionTypes.GET_FORUMS:
    case actionTypes.GET_FORUMS_SUCCESS:
    case actionTypes.RESET_PAGE_FOR_FORUMS:
      return null;

    case actionTypes.GET_FORUMS_ERROR:
      const { error } = action.payload;
      return error;
    default:
      return state;
  }
};

const forums = combineReducers({
  entities,
  // page,
  // itemsPerPage,
  // total,
  selectedForums,
  isLoading,
  hasError,
  errorMessage
});

export default forums;
