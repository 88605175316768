import * as actionTypes from "../constants/actionTypes";
import { Typology } from "../interfaces";

export interface InitTypeAction {
  type: typeof actionTypes.INIT_TYPOLOGY;
  payload: { typeIds: string[] };
}

export interface SetTypeAction {
  type: typeof actionTypes.SET_TYPOLOGY;
  payload: { typeIds: string[] };
}

export interface ToggleTypeAction {
  type: typeof actionTypes.TOGGLE_TYPOLOGY_ON_SELECTED_LIST;
  payload: { typeId: string };
}

export interface SetSubTypeAction {
  type: typeof actionTypes.SET_SUBTYPOLOGY;
  payload: { type: Typology | null };
}
export interface InitSubTypeAction {
  type: typeof actionTypes.INIT_SUBTYPOLOGY;
  payload: { type: Typology | null };
}

export type Action = InitTypeAction | SetTypeAction | ToggleTypeAction | InitSubTypeAction | SetSubTypeAction;

export function initTypology(typeIds: string[]): Action {
  return {
    type: actionTypes.INIT_TYPOLOGY,
    payload: {
      typeIds
    }
  };
}
export function setTypology(typeIds: string[]): Action {
  return {
    type: actionTypes.SET_TYPOLOGY,
    payload: {
      typeIds
    }
  };
}
export function initSubTypology(type: Typology | null = null): Action {
  return {
    type: actionTypes.INIT_SUBTYPOLOGY,
    payload: {
      type
    }
  };
}
export function setSubTypology(type: Typology | null): Action {
  return {
    type: actionTypes.SET_SUBTYPOLOGY,
    payload: {
      type: type || null
    }
  };
}

export function toggleTypologyOnSelectedList(typeId: string): Action {
  return {
    type: actionTypes.TOGGLE_TYPOLOGY_ON_SELECTED_LIST,
    payload: {
      typeId
    }
  };
}