import { Brand, Dictionary, State } from "../../../interfaces";
import { Card, Popconfirm, Row, Tag } from "antd";
import {
  CaretUpOutlined,
  CloseCircleOutlined,
  EyeOutlined
} from "@ant-design/icons";
import { Item, ItemFile } from "../../../reducers/items";
import React, { useState } from "react";
import { shallowEqual, useSelector } from "react-redux";

import DownloadBtn from "../downloadbtn";
import DynamicIcon from "../../DynamicIcon";
import FileLoader from "../../FileLoader";
import { FiltersState } from "../../../reducers/filters";
import ItemTag from "../itemtag";
import { Link } from "react-router-dom";
import ShareBtn from "../share/shareBtn";
import { Theme } from "../../../theme";
import { formatDate } from "../../../utils";

// import { useWhyDidYouUpdate } from "../../hooks/useWhyDidYouUpdate";

/*interface Item {
  id: string;
  thumbUrl: string;
  name: string;
  icon: string;
  publishDate: string;
  can_edit: boolean;
  tags: string[];
  markets: string[];
  typologyName: string;
  typologyIcon: string;
  files: Array<ItemFile>;
}*/

interface Props {
  item: Item;
  noLabel: string;
  yesLabel: string;
  downloadLabel: string;
  unpublishWarningLabel: string;
  addremovetags: string;
  tagById?: Dictionary<any>;
  marketById?: Dictionary<any>;
  userCanShare: boolean;
  editMode: boolean;
  onItemSelect: Function;
  onTagClicked?: Function;
  onRemoveItem?: Function;
  onShare?: Function;
  onOpenEditModal?: Function;
  theme: Theme;
}

interface ItemProps {
  id: string;
  unpublishWarningLabel: string;
  noLabel: string;
  yesLabel: string;
  can_edit: boolean;
  editMode: boolean;
  removeItem?: Function;
}

const UpublishBtn = ({
  id,
  noLabel,
  yesLabel,
  unpublishWarningLabel,
  editMode,
  can_edit,
  removeItem
}: ItemProps) => {
  const _onClick = () => {
    if (removeItem) removeItem(id);
  };

  return editMode && can_edit ? (
    <Popconfirm
      placement="bottomRight"
      title={unpublishWarningLabel}
      onConfirm={_onClick}
      okText={yesLabel}
      cancelText={noLabel}
    >
      <CloseCircleOutlined className="unpublish" />
    </Popconfirm>
  ) : null;
};

const ThumbItem: React.FC<Props> = (props) => {
  const {
    item,
    tagById,
    marketById,
    downloadLabel,
    unpublishWarningLabel,
    addremovetags,
    noLabel,
    yesLabel,
    editMode,
    userCanShare,
    onItemSelect,
    onShare,
    onTagClicked,
    onRemoveItem,
    onOpenEditModal,
    theme
  } = props;

  const {
    id,
    name,
    thumbUrl,
    icon,
    publishDate,
    tags,
    markets,
    typologyName,
    typologyIcon,
    files,
    can_edit
  } = item;

  const selectedBrand: Brand | null = useSelector(
    (state: State) => state.filters.selectedBrand,
    shallowEqual
  );

  const maxMarketsBeforeClipping = 4;
  const [clipMarkets, setClipMarkets] = useState(
    markets.length > maxMarketsBeforeClipping
  );

  const selectItem = () => {
    onItemSelect(item);
  };

  const handleOnShare = () => {
    if (onShare) onShare(item);
  };

  const handleOnOpenEditModal = () => {
    if (onOpenEditModal) onOpenEditModal(item);
  };

  const toggleClip = () => {
    setClipMarkets((state) => !state);
  };

  // console.log("ThumbItem");
  return (
    <Card
      className="thumb"
      title={
        <div className="card-title">
          <DynamicIcon type={icon} /> <p>{name}</p>
        </div>
      }
      headStyle={{backgroundColor: props.theme.primary}}
      bodyStyle={{ padding: 0 }}
      extra={
        <UpublishBtn
          id={id}
          can_edit={can_edit}
          editMode={editMode}
          removeItem={onRemoveItem}
          unpublishWarningLabel={unpublishWarningLabel}
          noLabel={noLabel}
          yesLabel={yesLabel}
        />
      }
    >
      <Link to={`/${selectedBrand?.code}/details/${id}`}>
        <div className="custom-image" onClick={selectItem}>
          {thumbUrl && thumbUrl !== "" && (
            <FileLoader fileName={name} fileUrl={thumbUrl} type="tag" />
          )}
          <Row className="img-overlay" align="middle" justify="center">
            <EyeOutlined className="icon" />
          </Row>
        </div>
      </Link>
      <div className="custom-card">
        <div className={`details-bar${clipMarkets ? " clip" : ""}`}>
          <h4>{formatDate(publishDate)}</h4>
          {marketById &&
            markets.map((market, index) => {
              return marketById[market] !== undefined ? (
                clipMarkets && index >= maxMarketsBeforeClipping ? null : (
                  <Tag
                    className="market-tag"
                    key={market}
                    id={market}
                    color={theme.primary}
                  >
                    {marketById[market].name}
                  </Tag>
                )
              ) : (
                console.log("market non presente nella lista ", market)
              );
            })}
          {markets.length > maxMarketsBeforeClipping && (
            <Tag className="market-tag show-more" onClick={toggleClip}>
              {!clipMarkets ? <CaretUpOutlined /> : "..."}
            </Tag>
          )}
        </div>
        {editMode && (
          <Tag
            className="add-remove"
            color={theme.primary}
            onClick={handleOnOpenEditModal}
          >
            {addremovetags}
          </Tag>
        )}
        {/* <div className="tag-wrapper">
          {tagById &&
            tags.map((tag) => {
              return tagById[tag] !== undefined ? (
                <ItemTag
                  key={tag}
                  id={tag}
                  itemId={id}
                  name={tagById[tag].name}
                  tagClicked={onTagClicked}
                />
              ) : (
                console.log("tag non presente nella lista ", tag)
              );
            })}
        </div> */}
        {typologyName && typologyIcon &&
          <div className="typology-bar">
            <DynamicIcon type={typologyIcon} />
            <h4 className="typology-name">{typologyName}</h4>
          </div>
        }

        <div className="btns-wrapper">
          <DownloadBtn
            direction="topCenter"
            clsName="btn-white"
            files={files}
            itemID={id}
            label={downloadLabel}
            theme={theme}
          />
          {userCanShare && (
            <ShareBtn
              clsName="btn-white"
              files={files}
              itemID={id}
              onShare={handleOnShare}
            />
          )}
        </div>
      </div>
    </Card>
  );
};

export default React.memo<Props>(ThumbItem);
