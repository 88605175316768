import * as actions from "../../actions";

import { Button, Card, Checkbox, Col, Divider, Modal, Row } from "antd";
import { Entities, News, State, Typology } from "../../interfaces";
import React, { useCallback } from "react";
import { formatDate, hasKeys } from "../../utils";
import { shallowEqual, useDispatch, useSelector } from "react-redux";

import DynamicIcon from "../../components/DynamicIcon";
import FileLoader from "../../components/FileLoader";
import { FiltersState } from "../../reducers/filters";
import { Header } from "antd/lib/layout/layout";
import HomeBanner from "../../components/home/HomeBanner";
import { HomeState } from "../../reducers/home";
import { ItemsState } from "../../reducers/items";
import { LanguageState } from "../../reducers/language";
import { Loader } from "../../components/loader";
import Masonry from "react-masonry-css";
import ThumbItem from "../../components/repo/thumbitem";
import { createLibraryQueryString } from "../../utils";
import { history } from "../../stores/configureStore";
import { push } from "connected-react-router";
import queryString from "query-string";
import { useLocalStorage } from "react-use";
import { useNewsModal } from '../news/useNewsModal';

const breakpointColumnsObj = {
  default: 3,
  "900": 2,
  "576": 1
};

let Home = () => {
  const language: LanguageState = useSelector(
    (state: State) => state.language,
    shallowEqual
  );
  const { labels } = language;

  const filters: FiltersState = useSelector(
    (state: State) => state.filters,
    shallowEqual
  );
  const { typologies, selectedTheme, isLoading, selectedBrand } = filters;

  const entitiesTypology = typologies.entities;

  const items: ItemsState = useSelector(
    (state: State) => state.items,
    shallowEqual
  );
  const { bestPracticesItems, isLoading: isLoadingItems } = items;
  const { latestNews, shouldSetNews, setShouldSetNews, handleNewsModalOk } = useNewsModal();
  
  const home: HomeState = useSelector(
    (state: State) => state.home,
    shallowEqual
  );
  const { isLoading: isLoadingBanner, homeBanners } = home;
  const homeBanner = homeBanners?.[0];

  const dispatch = useDispatch();
  const onSetType = useCallback(
    (id) => {
      const ids = [id];
      dispatch(actions.setTypology(ids));
      dispatch(push(`/${selectedBrand?.code}/library`));
    },
    [dispatch, selectedBrand]
  );

  const handleOnSelectItem = useCallback(
    (id) => {
      dispatch(actions.selectItem(id));
    },
    [dispatch]
  );

  return (
    <Row>
      <Col
        md={{ span: 24, offset: 0 }}
        xl={{ span: 20, offset: 2 }}
        xxl={{ span: 16, offset: 4 }}
        className="home-content"
      >
        {!isLoadingBanner && !isLoading && homeBanner && (
          <HomeBanner item={homeBanner} clssName="home-banner" />
        )}

          {/* News Modal */}
        <Modal
          title={formatDate(latestNews?.date || "")}
          visible={!!latestNews}
          onOk={handleNewsModalOk}
          okText="OK"
          cancelButtonProps={{ style: { display: "none" } }}
          width={800}
          bodyStyle={{maxHeight: 600}}
          footer={[
            <Checkbox
                checked={shouldSetNews}
                onChange={(e) => setShouldSetNews(e.target.checked)}
              >
              Do not show this news again
            </Checkbox>,
            <Button type="primary" onClick={handleNewsModalOk}>
              Ok
            </Button>
          ]}
        >
          <div>
            <div className="news-details-wrapper">
              <Header>
                <Row justify="space-between" align="middle">
                  {latestNews && (
                    <h2 className="news-title">
                      <DynamicIcon type="news" />
                      {latestNews?.title}
                    </h2>
                  )}
                </Row>
              </Header>
              {/* <div className="thumbUrl">
                {latestNews?.thumbUrl}
              </div> */}
              <div className="latest-news-content">
                {/* <h2 className="title"> */}
                  {/* <DynamicIcon type="news" /> */}
                  {/* {latestNews?.title} */}
                {/* </h2> */}
                {latestNews?.thumbUrl && (
                  <FileLoader
                    clssName="news-detail-image"
                    fileUrl={latestNews.thumbUrl}
                    type="tag"
                  />
                )}
                {/* <h4 className="date">{formatDate(date)}</h4> */}
                {/* <p className="subtitle">{subtitle}</p> */}
                
                <div
                  className="news-body"
                  dangerouslySetInnerHTML={{ __html: latestNews?.body || "" }}
                ></div>
              </div>
            </div>
          </div>
        </Modal>

        {false && (
          <>
            <Divider className="home-divider" orientation="left">
              {labels.latestBestPractices}
            </Divider>
            <div className="masonry-wrapper home">
              <Masonry
                className="masonry"
                columnClassName="masonry-column"
                breakpointCols={breakpointColumnsObj}
              >
                {bestPracticesItems.all.map((_id) => {
                  const item = bestPracticesItems.byId[_id];
                  const icon = entitiesTypology.byId[item.typeID[0]]?.icon;
                  item.icon = icon;

                  return (
                    <ThumbItem
                      key={_id}
                      item={item}
                      theme={selectedTheme}
                      editMode={false}
                      userCanShare={false}
                      onItemSelect={handleOnSelectItem}
                      downloadLabel={labels.download}
                      unpublishWarningLabel={labels.unpublishWarning}
                      addremovetags={labels.addremovetags}
                      noLabel={labels.no}
                      yesLabel={labels.yes}
                    />
                  );
                })}
              </Masonry>
            </div>
          </>
        )}
        <Loader isLoading={isLoadingItems && isLoading} />
        {!isLoading && (
          <>
            <Divider className="home-divider" orientation="left">
              {labels.library}
            </Divider>
            <div className="library">
              {entitiesTypology.all.map((_id) => {
                const { id, name, description, icon } =
                  entitiesTypology.byId[_id];
                return (
                  <Card
                    key={id}
                    className="home-card"
                    onClick={() => onSetType(id)}
                  >
                    <div className="custom-image">
                      <DynamicIcon type={icon} />
                    </div>
                    <div className="custom-card">
                      <h3>{name}</h3>
                      {description && (
                        <div
                          className="description"
                          title={description.replace(/<\/?[^>]+(>|$)/g, "")}
                          dangerouslySetInnerHTML={{ __html: description }}
                        />
                      )}
                    </div>
                  </Card>
                );
              })}
            </div>
          </>
        )}
        <Loader isLoading={isLoading} />
      </Col>
    </Row>
  );
};

export default Home;
