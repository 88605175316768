import { Entities, News } from "./../interfaces/index";
import * as actionTypes from "../constants/actionTypes";

interface BasicAction {
  type: string;
}
export interface GetNewsAction {
  type: typeof actionTypes.GET_NEWS;
  payload: {
    action?: BasicAction;
  };
}
export interface GetNewsSuccessAction {
  type: typeof actionTypes.GET_NEWS_SUCCESS;
  payload: {
    entities: Entities<News>;
    page: number;
    total: number;
    size: number;
  };
}
export interface GetNewsErrorAction {
  type: typeof actionTypes.GET_NEWS_ERROR;
  payload: {
    error: string;
    status: number;
  };
}

export interface GetNextPageForNewsAction {
  type: typeof actionTypes.GET_NEXT_PAGE_FOR_NEWS;
  payload: { page: number };
}
export interface ResetPageForNewsAction {
  type: typeof actionTypes.RESET_PAGE_FOR_NEWS;
}

export interface SetNewsAction {
  type: typeof actionTypes.SET_NEWS;
  payload: {
    news: News | null;
  };
}
export interface SetSearchStringForNewsAction {
  type: typeof actionTypes.SET_SEARCH_STRING_FOR_NEWS;
  payload: { text: string };
}
export interface SetSortForNewsAction {
  type: typeof actionTypes.SET_SORT_FOR_NEWS;
  payload: { sortBy: string };
}
export interface GetNewsByIdAction {
  type: typeof actionTypes.GET_NEWS_BY_ID;
  payload: { newsID: string };
}
export interface GetNewsByIdSuccessAction {
  type: typeof actionTypes.GET_NEWS_BY_ID_SUCCESS;
  payload: { news: News | null | undefined };
}
export interface GetNewsByIdErrorAction {
  type: typeof actionTypes.GET_NEWS_BY_ID_ERROR;
  payload: { error: string; status: number };
}

export type Action =
  | GetNewsAction
  | GetNewsErrorAction
  | GetNewsSuccessAction
  | GetNextPageForNewsAction
  | ResetPageForNewsAction
  | SetNewsAction
  | SetSearchStringForNewsAction
  | SetSortForNewsAction
  | GetNewsByIdAction
  | GetNewsByIdSuccessAction
  | GetNewsByIdErrorAction;

export function getNews(
  action: BasicAction | undefined = undefined
): GetNewsAction {
  return {
    type: actionTypes.GET_NEWS,
    payload: {
      action
    }
  };
}

export function getNewsSuccess(
  entities: Entities<News>,
  page: number,
  total: number,
  size: number
): GetNewsSuccessAction {
  return {
    type: actionTypes.GET_NEWS_SUCCESS,
    payload: {
      entities,
      page,
      total,
      size
    }
  };
}

export function getNewsError(
  error: string,
  status: number
): GetNewsErrorAction {
  return {
    type: actionTypes.GET_NEWS_ERROR,
    payload: {
      error,
      status
    }
  };
}

export function getNextPageForNews(page: number): Action {
  return {
    type: actionTypes.GET_NEXT_PAGE_FOR_NEWS,
    payload: {
      page
    }
  };
}

export function resetPageForNews(): Action {
  return {
    type: actionTypes.RESET_PAGE_FOR_NEWS
  };
}

export function setNews(news: News | null): SetNewsAction {
  return {
    type: actionTypes.SET_NEWS,
    payload: {
      news
    }
  };
}

export function setSearchStringForNews(text: string): Action {
  return {
    type: actionTypes.SET_SEARCH_STRING_FOR_NEWS,
    payload: {
      text
    }
  };
}

export function setSortForNews(sortBy: string): Action {
  return {
    type: actionTypes.SET_SORT_FOR_NEWS,
    payload: {
      sortBy
    }
  };
}

export function getNewsById(newsID: string): Action {
  return {
    type: actionTypes.GET_NEWS_BY_ID,
    payload: {
      newsID
    }
  };
}

export function getNewsByIdSuccess(news: News | null | undefined): Action {
  return {
    type: actionTypes.GET_NEWS_BY_ID_SUCCESS,
    payload: {
      news
    }
  };
}

export function getNewsByIdError(error: string, status: number): Action {
  return {
    type: actionTypes.GET_NEWS_BY_ID_ERROR,
    payload: {
      error,
      status
    }
  };
}
