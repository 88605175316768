import { combineReducers } from "redux";
import * as actionTypes from "../constants/actionTypes";
import { disableTreeNode } from "../utils";
import { Action as PropertiesAction } from "../actions/properties";
import { Action as FilterssAction } from "../actions/filters";
import { Action as LanguagesesAction } from "../actions/language";
import { Action as ItemsAction } from "../actions/items";
import { Entities, SelectedById, Property } from "../interfaces";

export type Action =
  | PropertiesAction
  | LanguagesesAction
  | FilterssAction
  | ItemsAction;

export type PropertiesState = ReturnType<typeof properties>;

const entities = (
  state: Entities<Property> = {
    all: [],
    byId: {}
  },
  action: Action
) => {
  switch (action.type) {
    case actionTypes.GET_FILTERS_SUCCESS:
      const { all, byId } = action.payload.properties;
      return {
        all,
        byId
      };

    case actionTypes.SEARCH_ITEMS_SUCCESS:
      // se la pagina non è 0, vuol dire che stiamo paginando con gli stesso criteri di ricerca e quindi non serve ricalcolare i filtri
      const { page } = action.payload;
      if (page > 0) {
        return {
          all: state.all,
          byId: state.byId
        };
      }

      // se è una nuova ricerca disabilito i tags su cui non ho risultati
      const { properties } = action.payload.filters;
      const { all: ids, byId: propById } = state;

      ids.forEach((id: string) => {
        if (propById && propById[id])
          propById[id] = disableTreeNode(propById[id], properties) as Property;
      });
      // console.log("propById ", propById);
      return {
        all: ids,
        byId: propById
      };

    case actionTypes.GET_FILTERS:
    case actionTypes.SELECT_LANGUAGE:
      return {
        all: [],
        byId: {}
      };
    default:
      return state;
  }
};

const selectedById = (state: SelectedById = {}, action: Action) => {
  switch (action.type) {
    case actionTypes.GET_FILTERS_SUCCESS:
      const { all } = action.payload.properties;
      const selectedById: SelectedById = {};
      all.forEach(id => {
        selectedById[id] = [];
      });
      // console.log("selectedById ", selectedById);
      return selectedById;

    case actionTypes.SELECT_PROPERTY:
      const { id, values } = action.payload;
      return {
        ...state,
        [id]: values
      };

    case actionTypes.GET_FILTERS:
    case actionTypes.SELECT_LANGUAGE:
      return {};

    default:
      return state;
  }
};

const properties = combineReducers({
  entities,
  selectedById
});

export default properties;
