import { Dictionary } from "../interfaces/index";

import { normalize, schema } from "normalizr";
import { Language } from "../reducers/language";
import { Item } from "../reducers/items";

export const normalizeLanguages = (data: any) => {
  const _language = new schema.Entity("languages", {}, { idAttribute: "id" });
  const mySchema = [_language];

  const normalizedData = normalize(data, mySchema);
  const { result, entities } = normalizedData;

  return {
    all: result,
    byId: entities.languages as Dictionary<Language>
  };
};

export const normalizeItems = (items: any) => {
  const item = new schema.Entity("items", {}, { idAttribute: "id" });
  const mySchema = [item];

  const normalizedData = normalize(items, mySchema);
  const { result, entities } = normalizedData;
  return {
    all: result,
    byId: entities.items as Dictionary<Item>
  };
};

export const normalizeFlatList = (data: any) => {
  const node = new schema.Entity("nodes", {});
  const mySchema = [node];

  const normalizedData = normalize(data, mySchema);
  const { result, entities } = normalizedData;
  return {
    all: result,
    byId: entities.nodes as Dictionary<any>
  };
};

export const normalizeTreeData = (data: any) => {
  const node = new schema.Entity(
    "nodes",
    {},
    {
      idAttribute: "key"
    }
  );
  const mySchema = [node];

  // const normalizedData = normalize(treeData, mySchema);
  const normalizedData = normalize(data, mySchema);
  const { result, entities } = normalizedData;
  const { nodes } = entities;
  return {
    all: result,
    byId: nodes as Dictionary<any>
  };
};

export const normalizeTreeDataAll = (data: any) => {
  const node = new schema.Entity(
    "nodes",
    {},
    {
      idAttribute: "key"
    }
  );
  const children = new schema.Array(node);
  node.define({ children });

  // const normalizedData = normalize(treeData, mySchema);
  const normalizedData = normalize(data, [node]);
  const { result, entities } = normalizedData;
  const { nodes } = entities;
  return {
    all: result,
    byId: nodes
  };
};
