import { Button, Dropdown, Menu, Progress, message } from "antd";
import React, { useState } from "react";
import { shallowEqual, useSelector } from "react-redux";

import { AuthState } from "../../../reducers/auth";
import { DownloadOutlined } from "@ant-design/icons";
import { FiltersState } from "../../../reducers/filters";
import { ItemFile } from "../../../reducers/items";
import ReactGA from "react-ga";
import { State } from "../../../interfaces";
import { Theme } from "../../../theme";
import axios from "axios";
import bytes from "bytes";
import { saveAs } from "file-saver";
import { useLocation } from "react-router";

type Direction =
  | "topLeft"
  | "topCenter"
  | "topRight"
  | "bottomLeft"
  | "bottomCenter"
  | "bottomRight"
  | undefined;

type Props = {
  itemID: string;
  files: Array<ItemFile>;
  direction?: Direction;
  label?: string;
  clsName?: string;
  theme: Theme;
};

const DownloadBtn: React.FC<Props> = (props) => {
  const { files, direction, label, clsName, itemID, theme } = props;
  const placement = direction;
  const [isDownloading, setIsDownloading] = useState(false);
  const [progress, setProgress] = useState(0);
  const location = useLocation();

  const auth: AuthState = useSelector(
    (state: State) => state.auth,
    shallowEqual
  );  
  const { accessToken, sessionToken } = auth;

  const filters: FiltersState = useSelector(
    (state: State) => state.filters,
    shallowEqual
  );
  

  const downloadFile = (e: any) => {
    const values = e.key.split("~");
    let url = values[0];
    const filename = values[1];

    const typology: string[] = filters.typologies?.selectedTypologies || [];
    const subTypology: string | undefined = filters.typologies?.selectedSubtypology?.id?.trim();
    let types: string[] = [];
    if(subTypology){
      types.push(subTypology); 
    } else if(typology){
      if(Array.isArray(typology)){
        types = typology;
      }        
    }

    const markets = filters.markets.selectedMarkets || "";
    url += `${url.includes('?') ? '&' : '?'}log_download=true&markets=${markets}&brands=${[filters.selectedBrand?.id]}`
        + `&types[]=${types}`;

    //console.log("url ", url);
    ReactGA.event({
      category: "Download",
      action: `Download file for item ${itemID}`,
      label: url
    });
    // const urlWithToken = `${url}?access_token=${sessionToken}`;
    // console.log("urlWithToken ", urlWithToken);
    // download(urlWithToken);
    setIsDownloading(true);
    axios({
      method: "get",
      url,
      responseType: "blob",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
        "X-CSRF-Token": sessionToken
      },
      onDownloadProgress: (progressEvent: any) => {
        // Do whatever you want with the native progress event
        const { loaded, total } = progressEvent;

        const percentage = (100 * loaded) / total;
        const _progress = parseFloat(percentage.toFixed(1));
        setProgress(_progress);
        // console.log("percentage ", percentage);
      }
    })
      .then((data) => {
        // console.log("data ", data.data);
        setIsDownloading(false);
        setProgress(0);
        saveAs(data.data, filename);
      })
      .catch((error) => {
        // console.log("error ", error.message);
        message.error(error.message);
        setIsDownloading(false);
        setProgress(0);
      });
  };

  const menu = (
    <Menu onClick={downloadFile}>
      {files.map((file) => {
        let size = "";
        if (file.hasOwnProperty("filesize")) {
          const sizeInt = parseInt(file.filesize as string, 10);
          size = ` (${bytes(sizeInt)})`;
        }
        return (
          <Menu.Item
            // key={file.url}
            key={`${file.url}~${file.filename}`}
          >
            {file.desc}
            {size} <DownloadOutlined />
          </Menu.Item>
        );
      })}
    </Menu>
  );

  return (
    <>
      {isDownloading ? (
        <Progress
          className="progress"
          percent={progress}
          strokeColor={theme.primary}
          size="small"
        />
      ) : (
        <Dropdown
          overlay={menu}
          trigger={["click"]}
          placement={placement}
          getPopupContainer={(triggerNode) =>
            triggerNode.parentNode as HTMLElement
          }
        >
          <Button className={`download-btn ${clsName}`}>
            {label}
            <DownloadOutlined style={label === "" ? { marginLeft: 0 } : {}} />
          </Button>
        </Dropdown>
      )}
    </>
  );
};

DownloadBtn.defaultProps = {
  direction: "topCenter" as Direction,
  label: "Download",
  clsName: "btn-transparent"
};

export default DownloadBtn;
