import React from "react";

export const SocialMediaSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="currentColor"
    viewBox="0 0 60 60"
  >
    <g
      id="Group_626"
      data-name="Group 626"
      transform="translate(-2595.712 -352.721)"
    >
      <path
        id="Path_647"
        data-name="Path 647"
        d="M2617.49,364.714h-13.355a1.474,1.474,0,0,0,0,2.947h13.355a1.474,1.474,0,0,0,0-2.947Z"
      />
      <path
        id="Path_648"
        data-name="Path 648"
        d="M2617.49,359.494h-13.355a1.473,1.473,0,0,0,0,2.946h13.355a1.473,1.473,0,0,0,0-2.946Z"
      />
      <path
        id="Path_649"
        data-name="Path 649"
        d="M2606.508,374.309h1.385l5.177,4.222a1.8,1.8,0,0,0,1.158.488,1.562,1.562,0,0,0,.512-.091,1.7,1.7,0,0,0,1.047-1.445l.639-3.25a10.8,10.8,0,0,0-1.308-21.512h-2.4a1.473,1.473,0,0,0,0,2.946h2.4a7.849,7.849,0,0,1,.344,15.691,1.654,1.654,0,0,0-1.755,1.433l-.416,2.116-3.76-3.068a1.827,1.827,0,0,0-1.351-.476h-1.672a7.848,7.848,0,1,1,0-15.7h2.21a1.473,1.473,0,0,0,0-2.946h-2.21a10.794,10.794,0,1,0,0,21.588Z"
      />
      <path
        id="Path_650"
        data-name="Path 650"
        d="M2628.432,380.021l-1.342,5.577a1.88,1.88,0,0,0,.368,1.851,1.59,1.59,0,0,0,1.153.466,2.959,2.959,0,0,0,.679-.1l5.848-1.407.354-.091.241.1a14.577,14.577,0,1,0-2.846-25.556,1.474,1.474,0,1,0,1.669,2.43,11.626,11.626,0,1,1,2.277,20.393l-.162-.067a2.427,2.427,0,0,0-1.9-.156l-4.4,1.062.938-3.9a2.1,2.1,0,0,0-.237-1.918c-.043-.067-.091-.146-.151-.254a11.624,11.624,0,0,1-1.418-5.572,11.507,11.507,0,0,1,2.526-7.242,1.472,1.472,0,0,0-.232-2.071,1.5,1.5,0,0,0-1.084-.313,1.467,1.467,0,0,0-.987.547,14.589,14.589,0,0,0-1.385,16.069C2628.377,379.925,2628.4,379.973,2628.432,380.021Z"
      />
      <path
        id="Path_651"
        data-name="Path 651"
        d="M2645.718,366.36a4.465,4.465,0,0,0-2.786-2.258,1.88,1.88,0,0,0-1.67.05,2.1,2.1,0,0,0-.92,1.591,8.665,8.665,0,0,1-.861,2.327,3.607,3.607,0,0,1-.766,1.064h-.059a2.387,2.387,0,0,0-1.859-.9h-2.19a2.375,2.375,0,0,0-2.375,2.37v6.6a2.376,2.376,0,0,0,2.375,2.375h2.19a2.377,2.377,0,0,0,1.861-.9h.253c.094,0,.189,0,.285,0a4.3,4.3,0,0,0,3.1,1.361h4.673a2.561,2.561,0,0,0,2.557-2.557V371.21a2.561,2.561,0,0,0-2.557-2.557h-.916A4.206,4.206,0,0,0,2645.718,366.36Zm-2.712,4.52a1.675,1.675,0,0,0,1.459.72h2.111v5.5h-4.283a2.265,2.265,0,0,1-.753-.2,2.543,2.543,0,0,0-2.368-1.157v-3.679c2.074-.267,3.279-3.173,3.757-4.632a.693.693,0,0,1,.118.182,2.236,2.236,0,0,1-.173,1.636A1.7,1.7,0,0,0,2643.006,370.88Zm-6.78.3v5.455h-1.048v-5.455Z"
      />
      <path
        id="Path_652"
        data-name="Path 652"
        d="M2611.992,380.159a16.3,16.3,0,0,0-16.28,16.28,16.1,16.1,0,0,0,6.462,12.972,1.118,1.118,0,0,0,.254.2,16.236,16.236,0,0,0,19.087.026,1.1,1.1,0,0,0,.218-.155,16.276,16.276,0,0,0-9.741-29.319Zm2,10.224-.835.223a2.757,2.757,0,0,1-2.329,0l-.835-.223a3.464,3.464,0,0,0-.863-.114,2.566,2.566,0,0,0-1.392.4,4.628,4.628,0,0,1,8.511,0A2.759,2.759,0,0,0,2613.989,390.383Zm-6.821,4.3a4.614,4.614,0,0,0,1.865-1.265c.115-.117.2-.194.223-.177l.806.21a5.691,5.691,0,0,0,3.855,0l.787-.275a1.911,1.911,0,0,1,.249.239,4.64,4.64,0,0,0,1.863,1.263v.731c0,1.687-1.825,3.959-3.758,4.674a2.637,2.637,0,0,1-2.128,0c-1.935-.715-3.762-2.987-3.762-4.674Zm3.08,8.284a5.164,5.164,0,0,0,3.487,0l.043.062-1.734,1.019-.052.034c-.029-.02-.062-.041-.108-.07l-1.676-.983C2610.222,403.006,2610.234,402.987,2610.248,402.965Zm-5.577,4.339c0-1.636.734-2.2,2.368-2.722l3.319,1.945a2.678,2.678,0,0,0,3.248.014l3.344-1.959c1.628.522,2.36,1.086,2.36,2.722v.275a13.506,13.506,0,0,1-14.639,0Zm15.091-11.894v-1.927a8.713,8.713,0,0,0-2.4-6.324,7.537,7.537,0,0,0-10.743,0,8.711,8.711,0,0,0-2.4,6.324v1.927a8.33,8.33,0,0,0,3.221,5.989c-1.868.5-4.522,1.241-5.412,3.9a13.184,13.184,0,0,1-3.372-8.856,13.334,13.334,0,0,1,26.668,0,13.185,13.185,0,0,1-3.374,8.856c-.887-2.652-3.537-3.4-5.412-3.9A8.323,8.323,0,0,0,2619.762,395.41Z"
      />
      <path
        id="Path_653"
        data-name="Path 653"
        d="M2644.161,389.618a11.556,11.556,0,0,0-11.083,8.3l-3.666,2.249a1.636,1.636,0,0,0,0,2.975l3.95,2.422.223.129-.043.039a11.552,11.552,0,1,0,10.616-16.11Zm0,2.946a8.6,8.6,0,1,1-6.084,14.69,8.708,8.708,0,0,1-1.875-2.806,2.187,2.187,0,0,0-1.1-1.286l-2.473-1.512,2.157-1.32a1.963,1.963,0,0,0,1.067-1.373l.057-.21a8.605,8.605,0,0,1,8.256-6.183Zm-10.277,12.807.146-.064h0l-.26.225Z"
      />
      <path
        id="Path_654"
        data-name="Path 654"
        d="M2643.113,407.344a1.472,1.472,0,0,0,2.045,0l4.2-4.051a4.617,4.617,0,0,0,0-6.36,33.74,33.74,0,0,0-10.437,0,4.626,4.626,0,0,0,.033,6.4Zm-2.078-8.365a.863.863,0,0,1,.644-.261,1.6,1.6,0,0,1,1.03.433,1.841,1.841,0,0,0,2.9-.038,1.212,1.212,0,0,1,1.631-.134,1.648,1.648,0,0,1,.012,2.25l-3.116,3-3.1-2.994A1.651,1.651,0,0,1,2641.035,398.979Z"
      />
    </g>
  </svg>
);
