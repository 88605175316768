import { NewsSort } from "./../interfaces/index";
import { combineReducers } from "redux";
import * as actionTypes from "../constants/actionTypes";
import { Action as NewsAction } from "../actions/news";
import { Action as FilterssAction } from "../actions/filters";
import { Action as LanguagesesAction } from "../actions/language";
import { Action as UserAction } from "../actions/auth";
import { Entities, News } from "../interfaces";

export type Action =
  | NewsAction
  | LanguagesesAction
  | FilterssAction
  | UserAction;

export type NewsState = ReturnType<typeof news>;

const entities = (
  state: Entities<News> = {
    all: [],
    byId: {}
  },
  action: Action
) => {
  switch (action.type) {
    case actionTypes.GET_NEWS_SUCCESS:
      const { entities, page } = action.payload;
      const newState =
        page === 0
          ? applySetResult(state, entities)
          : applyUpdateResult(state, entities);

      return newState;

    case actionTypes.RESET_PAGE_FOR_NEWS:
    case actionTypes.SELECT_LANGUAGE:
    case actionTypes.LOGOUT:
    case actionTypes.SET_BRAND:
      return {
        all: [],
        byId: {}
      };
    default:
      return state;
  }
};

const latest = (
  state: Entities<News> = {
    all: [],
    byId: {}
  },
  action: Action
) => {
  switch (action.type) {
    case actionTypes.GET_NEWS_SUCCESS:
      const { entities } = action.payload;
      if (state.all.length > 0) {
        return state;
      } else {
        return applySetResult(state, entities);
      }

    case actionTypes.RESET_PAGE_FOR_NEWS:
    case actionTypes.SET_BRAND:
    case actionTypes.SELECT_LANGUAGE:
    case actionTypes.LOGOUT:
      return {
        all: [],
        byId: {}
      };
    default:
      return state;
  }
};

const page = (state: number = 0, action: Action) => {
  switch (action.type) {
    case actionTypes.GET_NEXT_PAGE_FOR_NEWS:
      const { page } = action.payload;
      return page;

    case actionTypes.RESET_PAGE_FOR_NEWS:
    case actionTypes.SELECT_LANGUAGE:
    case actionTypes.LOGOUT:
    case actionTypes.SET_BRAND:
      return 0;

    default:
      return state;
  }
};

const itemsPerPage = (state: number = 30, action: Action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const total = (state: number = 0, action: Action) => {
  switch (action.type) {
    case actionTypes.GET_NEWS_SUCCESS:
      const { total } = action.payload;
      return total;

    case actionTypes.RESET_PAGE_FOR_NEWS:
    case actionTypes.SELECT_LANGUAGE:
    case actionTypes.LOGOUT:
    case actionTypes.SET_BRAND:
      return 0;

    default:
      return state;
  }
};

const applySetResult = (
  entities: Entities<News>,
  newEntities: Entities<News>
) => {
  const { all, byId } = newEntities;
  return {
    all,
    byId
  };
};

const applyUpdateResult = (
  entities: Entities<News>,
  newEntities: Entities<News>
) => {
  const prevAllItems = entities.all;
  const prevItemByIds = entities.byId;
  const { all, byId } = newEntities;
  return {
    all: [...prevAllItems, ...all],
    byId: { ...prevItemByIds, ...byId }
  };
};

const isLoading = (state: boolean = false, action: Action) => {
  switch (action.type) {
    case actionTypes.GET_NEWS:
      return true;

    case actionTypes.RESET_PAGE_FOR_NEWS:
    case actionTypes.GET_NEWS_SUCCESS:
    case actionTypes.GET_NEWS_ERROR:
      return false;

    default:
      return state;
  }
};

const selectedNews = (state: News | null = null, action: Action) => {
  switch (action.type) {
    case actionTypes.SET_NEWS:
    case actionTypes.GET_NEWS_BY_ID_SUCCESS:
      const { news } = action.payload;
      return news;

    case actionTypes.SET_BRAND:
      return null;

    default:
      return state;
  }
};

const isLoadingById = (state: boolean = false, action: Action) => {
  switch (action.type) {
    case actionTypes.GET_NEWS_BY_ID:
      return true;

    case actionTypes.GET_NEWS_BY_ID_SUCCESS:
    case actionTypes.GET_NEWS_BY_ID_ERROR:
      return false;

    default:
      return state;
  }
};

const searchString = (state: string = "", action: Action) => {
  switch (action.type) {
    case actionTypes.SET_SEARCH_STRING_FOR_NEWS:
      const { text } = action.payload;
      return text;

    case actionTypes.SELECT_LANGUAGE:
    case actionTypes.SET_BRAND:
      return "";
    default:
      return state;
  }
};

const sortBy = (state: string = NewsSort.DATE_ASC, action: Action) => {
  switch (action.type) {
    case actionTypes.SET_SORT_FOR_NEWS:
      const { sortBy } = action.payload;
      return sortBy;

    case actionTypes.SELECT_LANGUAGE:
    case actionTypes.SET_BRAND:
      return "";
    default:
      return state;
  }
};

const hasError = (state: boolean = false, action: Action) => {
  switch (action.type) {
    case actionTypes.GET_NEWS:
    case actionTypes.GET_NEWS_SUCCESS:
    case actionTypes.RESET_PAGE_FOR_NEWS:
      return false;

    case actionTypes.GET_NEWS_ERROR:
      return true;
    default:
      return state;
  }
};

const errorMessage = (state: string | null = null, action: Action) => {
  switch (action.type) {
    case actionTypes.GET_NEWS:
    case actionTypes.GET_NEWS_SUCCESS:
    case actionTypes.RESET_PAGE_FOR_NEWS:
      return null;

    case actionTypes.GET_NEWS_ERROR:
      const { error } = action.payload;
      return error;
    default:
      return state;
  }
};

const news = combineReducers({
  entities,
  latest,
  page,
  itemsPerPage,
  total,
  selectedNews,
  isLoading,
  isLoadingById,
  searchString,
  sortBy,
  hasError,
  errorMessage
});

export default news;
