import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import language from "./language";
import auth from "./auth";
import items from "./items";
import filters from "./filters";
import share from "./share";
import shareLibrary from "./shareLibrary";
import news from "./news";
import forums from "./forums";
import { History } from "history";
import home from "./home";

export default (history: History) =>
  combineReducers({
    router: connectRouter(history),
    language,
    auth,
    filters,
    items,
    share,
    shareLibrary,
    news,
    forums,
    home
  });
