import * as actionTypes from "../constants/actionTypes";

export interface GetSharedLibraryLinkAction {
  type: typeof actionTypes.GET_SHARED_LIBRARY_LINK;
  payload: {
    url: string;
  };
}
export interface GetSharedLibraryLinkSuccessAction {
  type: typeof actionTypes.GET_SHARED_LIBRARY_LINK_SUCCESS;
  payload: {
    link: string;
  };
}
export interface GetSharedLibraryLinkErrorAction {
  type: typeof actionTypes.GET_SHARED_LIBRARY_LINK_ERROR;
  payload: { error: string; status: number };
}

export type Action =
  | GetSharedLibraryLinkAction
  | GetSharedLibraryLinkSuccessAction
  | GetSharedLibraryLinkErrorAction;

export function getSharedLibraryLink(url: string) {
  return {
    type: actionTypes.GET_SHARED_LIBRARY_LINK,
    payload: {
      url,
    }
  };
}

export function getSharedLibraryLinkSuccess(link: string): Action {
  return {
    type: actionTypes.GET_SHARED_LIBRARY_LINK_SUCCESS,
    payload: {
      link
    }
  };
}

export function getSharedLibraryLinkError(error: string, status: number): Action {
  return {
    type: actionTypes.GET_SHARED_LIBRARY_LINK_ERROR,
    payload: {
      error,
      status
    }
  };
}
