import * as actions from "../../../actions";

import { Button, Card, Col, Divider, Row } from "antd";

import DynamicIcon from "../../DynamicIcon";
import { FolderViewOutlined } from "@ant-design/icons";
import React from "react";
import { Theme } from "../../../theme";
import { Typology } from "../../../interfaces";
import { useDispatch } from "react-redux";

interface Props {
  item: Typology;
  onItemSelect?: Function;
  theme: Theme;
}

const ThumbTypology: React.FC<Props> = (props) => {
  const { item, theme } = props;
  const dispatch = useDispatch();
  const { name, icon, description } = item;

  return (
    <Card
      onClick={() => {
        dispatch(actions.setSubTypology(item));
      }}
      className="thumb"
      style={{ cursor: "pointer" }}
      headStyle={{backgroundColor: theme.primary}}
      title={
        <div className="card-title">
          <DynamicIcon type={icon} /> <p>{name}</p>
        </div>
      }
    >
      <Row justify="center">
        <Col span={24}>{description}</Col>
      </Row>
      <Divider />
      <Row justify="center" align="bottom">
        <Button
          type="primary"
          shape="circle"
          style={{backgroundColor: theme.primary}}
          icon={<FolderViewOutlined />}
          size="large"
        />
      </Row>
    </Card>
  );
};

export default React.memo<Props>(ThumbTypology);
