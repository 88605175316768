import * as actionTypes from "../constants/actionTypes";
import * as actions from "../actions";

import { catchError, filter, map, switchMap } from "rxjs/operators";

import { Action } from "../actions/shareLibrary";
import { Epic } from "redux-observable";
import { State } from "../interfaces";
import { ajax } from "rxjs/ajax";
import domain from "../utils/domain";
import { isOfType } from "typesafe-actions";
import { of } from "rxjs";
import { message } from "antd";
import { copyToClipboard } from "../utils/clipboard";

export const getSharedLibraryLinkEpic: Epic<Action, Action, State> = (
  action$,
  state$
) =>
  action$.pipe(
    filter(isOfType(actionTypes.GET_SHARED_LIBRARY_LINK)),
    switchMap((action) => {
      const { url } = action.payload;

      const state: State = state$.value;
      const { auth, language } = state;
      const { labels } = language;
      const { accessToken, sessionToken } = auth;      

      return ajax({
        body: {
          url,       
        },
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
          "X-CSRF-Token": sessionToken
        },
        url: `${domain}api/url_shortner/create`,
        crossDomain: true,
        method: "POST"
      }).pipe(
        map(({ response }) => {
          const link = `${window.location.protocol + "//" + window.location.host}${response.shortened_url}`;
          copyToClipboard(link);
          message.success(labels?.copiedMessage);
          return actions.getSharedLibraryLinkSuccess(link);
        }),
        catchError((error) => {
          if (error.response)
            return of(
              actions.getSharedLibraryLinkError(error.response.message, error.status)
            );
          return of(actions.getSharedLibraryLinkError(error.message, error.status));
        })
      );
    })
  );
