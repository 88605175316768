import { useEffect, useState } from 'react';
import { Entities, News, State } from "../../interfaces";
import { useLocalStorage } from 'react-use';
import { hasKeys } from '../../utils';
import { useSelector, shallowEqual } from 'react-redux';

export function useNewsModal() {
  const news: Entities<News> = useSelector(
    (state: State) => state.news.latest,
    shallowEqual
  );

  const [ localLatestNews, setLocalLatestNews ] = useLocalStorage("latestNews", {});

  const [latestNews, setLatesNews] = useState<News | null>(null);
  const [shouldSetNews, setShouldSetNews] = useState(false);

  useEffect(() => {
    const lastNewsId = news.all[0];

    if(lastNewsId && !hasKeys(localLatestNews, [lastNewsId])) {
      setLatesNews(news.byId[lastNewsId]); 
    }
  }, [news])
 
  const handleNewsModalOk = () => {
    const lastNewsId = news.all[0];

    if (shouldSetNews) {
      setLocalLatestNews({
        ...localLatestNews,
        [lastNewsId]: true,
      });
    }
  
    setLatesNews(null);
  };

  return {
    latestNews,
    shouldSetNews,
    setShouldSetNews,
    handleNewsModalOk
  };
}
