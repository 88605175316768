import * as actionTypes from "../constants/actionTypes";

import { Entities } from "../interfaces";
import { Action as FilterAction } from "../actions/filters";
import { Action as ItemsAction } from "../actions/items";
import { Action as LanguagesesAction } from "../actions/language";
import { Action as UserAction } from "../actions/auth";
import { combineReducers } from "redux";
import { SetSearchStringAction } from "../actions/searchString";

export interface ItemFile {
  fid: string;
  ftoken: string;
  mimetype?: string;
  filename?: string;
  filesize?: string;
  changed?: string;
  desc?: string;
  display?: string;
  url?: string;
}

export interface Item {
  id: string;
  thumbUrl: string;
  name: string;
  icon: string;
  previewUrl: string;
  previewPosterUrl: string;
  previewType: string;
  publishDate: string;
  can_edit: boolean;
  tags: string[];
  markets: string[];
  typeID: string[];
  files: Array<ItemFile>;
  typologyName: string;
  typologyIcon: string;
}
// export type ItemsState = {
//   +entities: Entities,
//   +page: number,
//   +itemsPerPage: number,
//   +total: number,
//   +isLoading: boolean,
//   +hasError: boolean,
//   +errorMessage?: string;
//   +selectedItem?: Item,
//   +userCanShare: boolean,
//   +userCanEdit: boolean,
//   +editMode: boolean,
//   +itemToEdit?: Item,
//   +showModal: boolean
// };

export type ItemsState = ReturnType<typeof items>;

type Action = LanguagesesAction | ItemsAction | UserAction | FilterAction | SetSearchStringAction;

const isLoading = (state: boolean = false, action: Action) => {
  switch (action.type) {
    case actionTypes.SEARCH_ITEMS:
    case actionTypes.GET_NEXT_PAGE_FOR_ITEMS:
    case actionTypes.UNPUBLISH_ITEM:
    case actionTypes.EDIT_TAGS_FOR_ITEM:
    case actionTypes.TOGGLE_MARKET_ON_SELECTED_LIST:
    case actionTypes.SET_MARKET:
    case actionTypes.GET_FILTERS_SUCCESS:
    case actionTypes.SET_SEARCH_STRING:
      return true;

    case actionTypes.RESET_PAGE_FOR_ITEMS:
    case actionTypes.SEARCH_ITEMS_SUCCESS:
    case actionTypes.SEARCH_ITEMS_ERROR:
    case actionTypes.GET_ITEM_BY_ID_SUCCESS:
    case actionTypes.GET_ITEM_BY_ID_ERROR:
    case actionTypes.SELECT_LANGUAGE:
    case actionTypes.UNPUBLISH_ITEM_ERROR:
    case actionTypes.UNPUBLISH_ITEM_SUCCESS:
    case actionTypes.EDIT_TAGS_FOR_ITEM_ERROR:
    case actionTypes.EDIT_TAGS_FOR_ITEM_SUCCESS:
      return false;

    default:
      return state;
  }
};

const hasError = (state: boolean = false, action: Action) => {
  switch (action.type) {
    case actionTypes.SEARCH_ITEMS:
    case actionTypes.GET_NEXT_PAGE_FOR_ITEMS:
    case actionTypes.RESET_PAGE_FOR_ITEMS:
    case actionTypes.SET_MARKET:
    case actionTypes.TOGGLE_MARKET_ON_SELECTED_LIST:
    case actionTypes.SEARCH_ITEMS_SUCCESS:
    case actionTypes.GET_ITEM_BY_ID_SUCCESS:
    case actionTypes.SELECT_LANGUAGE:
    case actionTypes.UNPUBLISH_ITEM:
    case actionTypes.UNPUBLISH_ITEM_SUCCESS:
    case actionTypes.EDIT_TAGS_FOR_ITEM:
    case actionTypes.EDIT_TAGS_FOR_ITEM_SUCCESS:
      return false;

    case actionTypes.SEARCH_ITEMS_ERROR:
    case actionTypes.GET_ITEM_BY_ID_ERROR:
    case actionTypes.UNPUBLISH_ITEM_ERROR:
    case actionTypes.EDIT_TAGS_FOR_ITEM_ERROR:
      return true;
    default:
      return state;
  }
};

const errorMessage = (state: string | null = null, action: Action) => {
  switch (action.type) {
    case actionTypes.SEARCH_ITEMS:
    case actionTypes.GET_NEXT_PAGE_FOR_ITEMS:
    case actionTypes.RESET_PAGE_FOR_ITEMS:
    case actionTypes.SET_MARKET:
    case actionTypes.TOGGLE_MARKET_ON_SELECTED_LIST:
    case actionTypes.SEARCH_ITEMS_SUCCESS:
    case actionTypes.GET_ITEM_BY_ID_SUCCESS:
    case actionTypes.SELECT_LANGUAGE:
    case actionTypes.UNPUBLISH_ITEM:
    case actionTypes.UNPUBLISH_ITEM_SUCCESS:
    case actionTypes.EDIT_TAGS_FOR_ITEM:
    case actionTypes.EDIT_TAGS_FOR_ITEM_SUCCESS:
      return null;

    case actionTypes.SEARCH_ITEMS_ERROR:
    case actionTypes.GET_ITEM_BY_ID_ERROR:
    case actionTypes.UNPUBLISH_ITEM_ERROR:
    case actionTypes.EDIT_TAGS_FOR_ITEM_ERROR:
      const { error } = action.payload;
      return error;
    default:
      return state;
  }
};

const page = (state: number = 0, action: Action) => {
  switch (action.type) {
    case actionTypes.GET_NEXT_PAGE_FOR_ITEMS:
      const { page } = action.payload;
      return page;

    case actionTypes.RESET_PAGE_FOR_ITEMS:
    case actionTypes.SET_MARKET:
    case actionTypes.TOGGLE_MARKET_ON_SELECTED_LIST:
    case actionTypes.SELECT_LANGUAGE:
    case actionTypes.LOGOUT:
    case actionTypes.SET_BRAND:
      return 0;

    default:
      return state;
  }
};

const itemsPerPage = (state: number = 30, action: Action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const total = (state: number = 0, action: Action) => {
  switch (action.type) {
    case actionTypes.SEARCH_ITEMS_SUCCESS:
      const { total } = action.payload;
      return total;

    case actionTypes.UNPUBLISH_ITEM_SUCCESS:
      return state - 1;

    case actionTypes.RESET_PAGE_FOR_ITEMS:
    case actionTypes.SET_MARKET:
    case actionTypes.TOGGLE_MARKET_ON_SELECTED_LIST:
    case actionTypes.SELECT_LANGUAGE:
    case actionTypes.LOGOUT:
    case actionTypes.SET_BRAND:
      return 0;

    default:
      return state;
  }
};

const entities = (
  state: Entities<Item> = {
    all: [],
    byId: {}
  },
  action: Action
) => {
  switch (action.type) {
    case actionTypes.SEARCH_ITEMS_SUCCESS:
      const { entities, page } = action.payload;
      const newState =
        page === 0
          ? applySetResult(state, entities)
          : applyUpdateResult(state, entities);

      return newState;

    case actionTypes.UNPUBLISH_ITEM_SUCCESS:
      const { itemId } = action.payload;
      const newAll = state.all.filter((id) => itemId !== id);
      const newById = { ...state.byId };
      delete newById[itemId];
      return {
        all: newAll,
        byId: newById
      };

    case actionTypes.EDIT_TAGS_FOR_ITEM_SUCCESS:
      const { tags, itemID } = action.payload;
      const _newById = { ...state.byId };
      // const tags = _newById[itemID].tags.filter(id => tagID !== id);
      _newById[itemID].tags = [...tags];
      return {
        all: state.all,
        byId: _newById
      };

    case actionTypes.RESET_PAGE_FOR_ITEMS:
    case actionTypes.SET_MARKET:
    case actionTypes.TOGGLE_MARKET_ON_SELECTED_LIST:
    case actionTypes.SELECT_LANGUAGE:
    case actionTypes.LOGOUT:
    case actionTypes.SET_BRAND:
      return {
        all: [],
        byId: {}
      };

    default:
      return state;
  }
};

const applySetResult = (
  entities: Entities<Item>,
  newEntities: Entities<Item>
) => {
  const { all, byId } = newEntities;
  return {
    all,
    byId
  };
};

const applyUpdateResult = (
  entities: Entities<Item>,
  newEntities: Entities<Item>
) => {
  const prevAllItems = entities.all;
  const prevItemByIds = entities.byId;
  const { all, byId } = newEntities;
  return {
    all: [...prevAllItems, ...all],
    byId: { ...prevItemByIds, ...byId }
  };
};

const homeItems = (
  state: Entities<Item> = {
    all: [],
    byId: {}
  },
  action: Action
) => {
  switch (action.type) {
    case actionTypes.GET_HOME_ITEMS_SUCCESS:
      const { homeItems } = action.payload;
      return homeItems;

    case actionTypes.SELECT_LANGUAGE:
    case actionTypes.LOGOUT:
    case actionTypes.SET_BRAND:
      return {
        all: [],
        byId: {}
      };

    default:
      return state;
  }
};

const bestPracticesItems = (
  state: Entities<Item> = {
    all: [],
    byId: {}
  },
  action: Action
) => {
  switch (action.type) {
    case actionTypes.GET_HOME_ITEMS_SUCCESS:
      const { bestPracticesItems } = action.payload;
      return bestPracticesItems;

    case actionTypes.SELECT_LANGUAGE:
    case actionTypes.LOGOUT:
    case actionTypes.SET_BRAND:
      return {
        all: [],
        byId: {}
      };

    default:
      return state;
  }
};

const selectedItem = (
  state: Item | null = null,
  action: Action
): Item | null | undefined => {
  switch (action.type) {
    case actionTypes.SELECT_ITEM:
    case actionTypes.GET_ITEM_BY_ID_SUCCESS:
      const { item } = action.payload;
      return item;

    case actionTypes.SELECT_LANGUAGE:
    case actionTypes.LOGOUT:
    case actionTypes.SET_BRAND:
      return null;

    default:
      return state;
  }
};

const userCanShare = (state: boolean = false, action: Action) => {
  switch (action.type) {
    case actionTypes.SEARCH_ITEMS_SUCCESS:
      return action.payload.userCanShare;

    case actionTypes.SELECT_LANGUAGE:
    case actionTypes.LOGOUT:
    case actionTypes.SET_BRAND:
      return false;
    default:
      return state;
  }
};

const userCanEdit = (state: boolean = false, action: Action) => {
  switch (action.type) {
    case actionTypes.SEARCH_ITEMS_SUCCESS:
      return state === true ? true : action.payload.userCanEdit;

    case actionTypes.SELECT_LANGUAGE:
    case actionTypes.LOGOUT:
    case actionTypes.SET_BRAND:
      return false;
    default:
      return state;
  }
};

const editMode = (state: boolean = false, action: Action) => {
  switch (action.type) {
    case actionTypes.TOGGLE_EDIT_MODE:
      return !state;

    case actionTypes.LOGOUT:
      return false;
    default:
      return state;
  }
};

const itemToEdit = (state: Item | null = null, action: Action): Item | null => {
  switch (action.type) {
    case actionTypes.SHOW_EDIT_MODAL:
      const { visible, item } = action.payload;
      return visible ? item : null;

    case actionTypes.EDIT_TAGS_FOR_ITEM_SUCCESS:
      return null;

    default:
      return state;
  }
};

const showModal = (state: boolean = false, action: Action) => {
  switch (action.type) {
    case actionTypes.SHOW_EDIT_MODAL:
      const { visible } = action.payload;
      return visible;

    case actionTypes.LOGOUT:
    case actionTypes.EDIT_TAGS_FOR_ITEM_SUCCESS:
      return false;
    default:
      return state;
  }
};

const clearItems = (state: Entities<Item> = { all: [], byId: {}}, action: Action) => {
  switch (action.type) {
    case actionTypes.CLEAR_ITEMS:
      return { all: [], byId: {} };
    default:
      return state;
  }
}

const items = combineReducers({
  entities,
  homeItems,
  bestPracticesItems,
  page,
  itemsPerPage,
  total,
  isLoading,
  hasError,
  errorMessage,
  selectedItem,
  userCanShare,
  userCanEdit,
  editMode,
  itemToEdit,
  showModal,
  clearItems,
});

export default items;
