import * as actionTypes from "../constants/actionTypes";

export interface SetTagsAction {
  type: typeof actionTypes.SELECT_TAG;
  payload: { ids: string[] };
}

export type Action = SetTagsAction;

export function setTags(ids: string[]): Action {
  return {
    type: actionTypes.SELECT_TAG,
    payload: {
      ids
    }
  };
}
