import * as actionTypes from "../constants/actionTypes";
import {
  Entities,
  CategoryEntities,
  Typology,
  Market,
  Tag,
  Property,
  Brand
} from "../interfaces";
import { InitMarketAction, Action as MarkerActions } from "./markets";
import { InitSearchStringAction, SetSearchStringAction } from "./searchString";
import { Action as TypeAction } from "./typologies";

export interface SetBrandAction {
  type: typeof actionTypes.SET_BRAND;
  payload: { brand: Brand };
}
export interface GetFiltersAction {
  type: typeof actionTypes.GET_FILTERS;
}
export interface GetFiltersSuccessAction {
  type: typeof actionTypes.GET_FILTERS_SUCCESS;
  payload: {
    markets: Entities<Market>;
    typologies: Entities<Typology>;
    categories: CategoryEntities;
    tags: Entities<Tag>;
    properties: Entities<Property>;
  };
}
export interface InitFiltersSuccessAction {
  type: typeof actionTypes.INIT_FILTERS_SUCCESS;
  payload: {
    markets: Entities<Market>;
    typologies: Entities<Typology>;
    categories: CategoryEntities;
    tags: Entities<Tag>;
    properties: Entities<Property>;
  };
}
export interface GetFiltersErrorAction {
  type: typeof actionTypes.GET_FILTERS_ERROR;
  payload: { error: string; status: number };
}
export interface SetFiltersAfterAction {
  type: typeof actionTypes.SET_FILTERS_AFTER_EDIT;
  payload: {
    filters: {
      categories: string[];
      item_types: string[];
      markets: string[];
      properties: string[];
      tags: string[];
    };
  };
}

export type Action =
  | SetBrandAction
  | InitMarketAction
  | GetFiltersAction
  | GetFiltersSuccessAction
  | GetFiltersErrorAction
  | MarkerActions
  | SetFiltersAfterAction
  | TypeAction
  | InitFiltersSuccessAction
  | SetSearchStringAction
  | InitSearchStringAction;

export function setBrand(brand: Brand): Action {
  return {
    type: actionTypes.SET_BRAND,
    payload: { brand }
  };
}

export function getFilters(): Action {
  return {
    type: actionTypes.GET_FILTERS
  };
}

export function getFiltersSuccess(
  markets: Entities<Market>,
  typologies: Entities<Typology>,
  categories: CategoryEntities,
  tags: Entities<Tag>,
  properties: Entities<Property>
): Action {
  return {
    type: actionTypes.GET_FILTERS_SUCCESS,
    payload: {
      markets,
      typologies,
      categories,
      tags,
      properties
    }
  };
}

export function initFiltersSuccess(
  markets: Entities<Market>,
  typologies: Entities<Typology>,
  categories: CategoryEntities,
  tags: Entities<Tag>,
  properties: Entities<Property>
): Action {
  return {
    type: actionTypes.INIT_FILTERS_SUCCESS,
    payload: {
      markets,
      typologies,
      categories,
      tags,
      properties
    }
  };
}

export function getFiltersError(error: string, status: number): Action {
  return {
    type: actionTypes.GET_FILTERS_ERROR,
    payload: {
      error,
      status
    }
  };
}

export function setFiltersAfter(filters: {
  categories: string[];
  item_types: string[];
  markets: string[];
  properties: string[];
  tags: string[];
}): Action {
  return {
    type: actionTypes.SET_FILTERS_AFTER_EDIT,
    payload: {
      filters
    }
  };
}
