import React from "react";
import { shallowEqual, useSelector } from "react-redux";
import { AuthState } from "../../reducers/auth";
import { Banner, State } from "../../interfaces";
import { Link } from "react-router-dom";

interface Props {
  item: Banner;
  clssName?: string;
}

const HomeBanner: React.FC<Props> = (props) => {
  const { item } = props;

  const auth: AuthState = useSelector(
    (state: State) => state.auth,
    shallowEqual
  );

  if (!item) return null;

  const { accessToken } = auth;

  const _url = item.banner.includes("?")
    ? `${item.banner}&access_token=${accessToken}`
    : `${item.banner}?access_token=${accessToken}`;

  return (
    <div className="home-banner-section">
      <div className="panel-body" style={{ cursor: "pointer" }}>
        <Link to={{ pathname: item.url }} target="_blank">
          <img src={_url} alt={item.titolo} className="home-banner-img" />
        </Link>
      </div>
    </div>
  );
};

export default React.memo<Props>(HomeBanner);
