//import less from 'less';

export type Theme = {
  primary: string;
  gray: string;
  grayDark: string;
};

export const getTheme = (code: string | null) => {
  switch (code) {
    case "caseih":
      return {
        primary: "#B12430",
        secondary: "#ffffff",
        gray: "#e0e0e0",
        grayDark: "#707070"
      };
    case "nha":
      return {
        primary: "#00377d",
        secondary: "#f2b716",
        gray: "#e0e0e0",
        grayDark: "#000001"
      };

    default:
      return {
        primary: "#B4B4B4",
        secondary: "#ffffff",
        gray: "#e0e0e0",
        grayDark: "#707070"
      };
  }
};

export const setLess = (code: string | null) => {
  let styles = {};
  const html = document.getElementsByTagName("html")[0];
  const colors = getTheme(code);
  styles = colors
  styles = {
    "@primary-color": colors.primary,
    "@secondary-color": colors.secondary,
    "@gray": colors.gray,
    "@grayDark": colors.grayDark,
  };
  html.className = "";
  switch (code) {
    case "caseih":
      html.className = "caseih";
      break;

    case "nha":
      html.className = "nha"; 
      break;      
  }

  if (typeof window !== "undefined" && typeof window.less !== "undefined") {
    window.less
      .modifyVars(styles)
      .then(() => {
        console.debug(`Theme updated successfully with:`, styles);
      })
      .catch((error: any) => {
        console.log("error ", error);
      });
  }
};
