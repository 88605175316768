import * as actions from "../../actions";

import React, { useCallback, useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Dictionary, State } from "../../interfaces";
import { Item, ItemsState } from "../../reducers/items";

import { Empty } from "antd";
import Title from "antd/lib/typography/Title";
import useInfiniteScroll from "react-infinite-scroll-hook";
import { FiltersState } from "../../reducers/filters";
import { Loader } from "../loader";
import ThumbItem from "../repo/thumbitem";
import ThumbTypology from "../repo/thumbtypology";

const breakpointColumnsObj = {
  default: 6,
  "1900": 5,
  "1600": 4,
  "1200": 3,
  "992": 2,
  "767": 2,
  "576": 1
};
interface Props {
  labels: Dictionary<string>;
}

interface TypologyTree {
  id: string;
  parent: string | null;
  name: string;
}

const LibraryList: React.FC<Props> = (props) => {
  // useWhyDidYouUpdate("LibraryList", props)
  const { labels } = props;
  const filters: FiltersState = useSelector(
    (state: State) => state.filters,
    shallowEqual
  );
  const { typologies, tags, markets, selectedTheme, searchString } = filters;
  const { byId: typologyById } = typologies.entities;
  const { byId: tagById } = tags.entities;
  const { byId: marketById } = markets.entities;

  const items: ItemsState = useSelector(
    (state: State) => state.items,
    shallowEqual
  );
  const {
    entities,
    page,
    total,
    isLoading,
    hasError,
    itemsPerPage,
    editMode,
    userCanShare
  } = items;

  const { all, byId } = entities;

  const dispatch = useDispatch();

  const [hasNextPage, setHasNextPage] = useState(false);

  useEffect(() => {
    // controllo che ci siano ancora item da richiedere e non ci siano errori
    setHasNextPage((page + 1) * itemsPerPage < total && !hasError);
  }, [hasError, itemsPerPage, page, total]);

  const onGetNextPageForItems = useCallback(
    (page) => dispatch(actions.getNextPageForItems(page)),
    [dispatch]
  );

  const handleOnSelectItem = useCallback(
    (id) => {
      dispatch(actions.selectItem(id));
    },
    [dispatch]
  );

  const handleOnShare = useCallback(
    (item: Item) => {
      dispatch(actions.showShareModal(true, item));
    },
    [dispatch]
  );

  const handleOnOpenEditModal = useCallback(
    (item: Item) => {
      dispatch(actions.showEditModal(true, item));
    },
    [dispatch]
  );

  const handleOnTagClicked = useCallback(
    (tag: string) => dispatch(actions.setTags([tag])),
    [dispatch]
  );

  const onRemoveItem = useCallback(
    (id) => dispatch(actions.getSessionToken(actions.unpublishItem(id))),
    [dispatch]
  );

  // const onRemoveTag = useCallback(
  //   (id, itemId) =>
  //     dispatch(actions.getSessionToken(actions.editTagsForItem(id, itemId))),
  //   [dispatch]
  // )

  const onPaginatedSearch = () => {
    // console.log("onPaginatedSearch ")
    // controllo che ci siano ancora item da richiedere
    if (hasNextPage) {
      onGetNextPageForItems(page + 1);
    }
  };

  const infiniteRef = useInfiniteScroll<HTMLDivElement>({
    loading: isLoading,
    // This value is set to "true" for this demo only. You will need to
    // get this value from the API when you request your items.
    hasNextPage,
    onLoadMore: onPaginatedSearch,
    scrollContainer: "window"
  });
  const isSearchTextEnabled = searchString.text?.trim().length > 0;
  const baseId = typologies.selectedTypologies[0];
  const isLeaf = Boolean(
    typologies.selectedSubtypology
      ? typologies.selectedSubtypology.isLeaf
      : typologyById[baseId]?.isLeaf
  );
  const typology = typologies.selectedSubtypology || typologyById[baseId];
  const noItemsFound = isSearchTextEnabled
    ? all.length === 0
    : isLeaf && all.length === 0;

  let className = "library-list";

  if (isLeaf) className += " leaves";

  const renderNoItemsFound = () => {
    return (
      <Empty
        image={false}
        description={
          <Title level={2} style={{ color: selectedTheme.primary }}>
            {labels.noResultsFound}
          </Title>
        }
        className=""
        style={{
          display: "flex",
          flexDirection: "column",
          height: "90vh",
          justifyContent: "center",
          alignContent: "center",
          alignItems: "center"
        }}
      />
    );
  };

  const renderSearchTextEnabled = () => {
    return (
      <div className={className} ref={infiniteRef}>
        {all.map((itemId: string, index: number) => {
          const item: any = byId[itemId];
          const typologiesExpanded = Object.values(
            item.typologies_expanded ?? {}
          ) as TypologyTree[];
          const objectWithParentNull = typologiesExpanded.filter(
            (obj) => obj.parent === null
          );

          const typologyId = objectWithParentNull[0]?.id;
          const icon = typologyById[typologyId]?.icon;
          const name = typologyById[typologyId]?.name;
          item.typologyName = name;
          item.typologyIcon = icon;

          return (
            <ThumbItem
              key={itemId}
              item={item}
              theme={selectedTheme}
              editMode={editMode}
              userCanShare={userCanShare}
              tagById={tagById}
              marketById={marketById}
              onItemSelect={handleOnSelectItem}
              onShare={handleOnShare}
              onOpenEditModal={handleOnOpenEditModal}
              onTagClicked={handleOnTagClicked}
              onRemoveItem={onRemoveItem}
              downloadLabel={labels.download}
              unpublishWarningLabel={labels.unpublishWarning}
              addremovetags={labels.addremovetags}
              noLabel={labels.no}
              yesLabel={labels.yes}
            />
          );
        })}

        {!hasNextPage && isLeaf && !isLoading && (
          <p style={{ textAlign: "center", marginTop: 20 }}>
            {labels.noMoreItemLabel}
          </p>
        )}
      </div>
    );
  };

  const renderIsLeaf = () => {
    return (
      <div className={className} ref={infiniteRef}>
        {all.map((itemId: string, index: number) => {
          const item = byId[itemId];
          const icon = typologyById[item.typeID[0]]?.icon;
          item.icon = icon;

          return (
            <ThumbItem
              key={itemId}
              item={item}
              theme={selectedTheme}
              editMode={editMode}
              userCanShare={userCanShare}
              tagById={tagById}
              marketById={marketById}
              onItemSelect={handleOnSelectItem}
              onShare={handleOnShare}
              onOpenEditModal={handleOnOpenEditModal}
              onTagClicked={handleOnTagClicked}
              onRemoveItem={onRemoveItem}
              downloadLabel={labels.download}
              unpublishWarningLabel={labels.unpublishWarning}
              addremovetags={labels.addremovetags}
              noLabel={labels.no}
              yesLabel={labels.yes}
            />
          );
        })}

        {!hasNextPage && isLeaf && !isLoading && (
          <p style={{ textAlign: "center", marginTop: 20 }}>
            {labels.noMoreItemLabel}
          </p>
        )}
      </div>
    );
  };

  const renderTypologiesCards = () => {
    return (
      <div className={className} ref={infiniteRef}>
        {typology?.subtypes?.map((sub) => (
          <ThumbTypology item={sub} key={sub.id} theme={selectedTheme} />
        ))}
      </div>
    );
  };

  const renderLibraryListContent = () => {
    /*if(isLoading){
      return (<></>)
    } else */ if (noItemsFound && !isLoading) {
      return renderNoItemsFound();
    } else if (isSearchTextEnabled) {
      return renderSearchTextEnabled();
    } else if (isLeaf) {
      return renderIsLeaf();
    }
    return renderTypologiesCards();
  };

  return (
    <div>
      {renderLibraryListContent()}
      <Loader isLoading={isLoading && isLeaf} />
    </div>
  );
};

export default LibraryList;
