import { combineReducers } from "redux";
import * as actionTypes from "../constants/actionTypes";
import { disableMainTreeNode } from "../utils";
import { Entities, Dictionary } from "../interfaces";
import { Action as CategoriesAction } from "../actions/categories";
import { Action as FiltersAction } from "../actions/filters";
import { Action as LanguagesesAction } from "../actions/language";
import { Action as ItemsAction } from "../actions/items";
// import { Action as TypologyAction } from "../actions/typologies";
// import { Action as MarketAction } from "../actions/markets";
import { SelectedById } from "../interfaces";

export type Action =
  | CategoriesAction
  | LanguagesesAction
  | FiltersAction
  | ItemsAction;
// | TypologyAction
// | MarketAction;

export interface Category {
  key: string;
  label: string;
  value: string;
  isDisabled?: boolean;
  children?: Category[];
}

export interface CategoryAll {
  value: string;
  key: string;
  label: string;
  description: string;
  disabled?: boolean;
  children?: string[];
}

// export type CategoriesState = {
//   +entities: Entities,
//   +allById: Object,
//   +selectedById: { [string]: string[] },
//   +selectedByIdWithChildren: { [string]: string[] }
// };

export type CategoriesState = ReturnType<typeof categories>;

const entities = (
  state: Entities<Category> = {
    all: [],
    byId: {}
  },
  action: Action
) => {
  switch (action.type) {
    case actionTypes.GET_FILTERS_SUCCESS:
      const { all, byId } = action.payload.categories;
      return {
        all,
        byId
      };

    case actionTypes.SEARCH_ITEMS_SUCCESS:
      // se la pagina non è 0, vuol dire che stiamo paginando con gli stesso criteri di ricerca e quindi non serve ricalcolare i filtri
      const { page } = action.payload;
      if (page > 0) {
        return state;
      }

      // se è una nuova ricerca disabilito i tags su cui non ho risultati
      const { categories } = action.payload.filters;
      const { all: ids, byId: catById } = state;
      const _catById = {
        ...catById
      };
      ids.forEach(id => {
        if (_catById && _catById[id]) {
          const node = {
            ..._catById[id]
          };
          const newCatById = disableMainTreeNode(node, categories);
          node.isDisabled = newCatById.isDisabled;
          _catById[id] = node;
        }
      });
      return {
        all: ids,
        byId: _catById
      };

    case actionTypes.GET_FILTERS:
    case actionTypes.SELECT_LANGUAGE:
      return {
        all: [],
        byId: {}
      };
    default:
      return state;
  }
};

const allById = (state: Dictionary<CategoryAll> = {}, action: Action) => {
  switch (action.type) {
    case actionTypes.GET_FILTERS_SUCCESS:
      const { allById } = action.payload.categories;
      return allById;

    case actionTypes.GET_FILTERS:
    case actionTypes.SELECT_LANGUAGE:
      return {};
    default:
      return state;
  }
};

const selectedById = (state: SelectedById = {}, action: Action) => {
  switch (action.type) {
    case actionTypes.GET_FILTERS_SUCCESS:
      const { all } = action.payload.categories;
      const selectedById: SelectedById = {};
      all.forEach(id => {
        selectedById[id] = [];
      });
      // console.log("selectedById ", selectedById);
      return selectedById;

    case actionTypes.SELECT_CATEGORY:
      const { id, values } = action.payload;
      return {
        ...state,
        [id]: values
      };

    case actionTypes.GET_FILTERS:
    case actionTypes.SELECT_LANGUAGE:
      return {};

    // case actionTypes.SET_TYPOLOGY:
    // case actionTypes.TOGGLE_TYPOLOGY_ON_SELECTED_LIST:
    // case actionTypes.TOGGLE_MARKET_ON_SELECTED_LIST:
    // case actionTypes.SET_MARKET:
    //   const _selectedById = {};
    //   for (const prop in state) {
    //     _selectedById[prop] = [];
    //   }
    //   return _selectedById;

    default:
      return state;
  }
};

const selectedByIdWithChildren = (state: SelectedById = {}, action: Action) => {
  switch (action.type) {
    case actionTypes.GET_FILTERS_SUCCESS:
      const { all } = action.payload.categories;
      const selectedByIdWithChildren: SelectedById = {};
      all.forEach(id => {
        selectedByIdWithChildren[id] = [];
      });
      // console.log("selectedByIdWithChildren ", selectedByIdWithChildren);
      return selectedByIdWithChildren;

    case actionTypes.SELECT_CATEGORY:
      const { id, children } = action.payload;
      return {
        ...state,
        [id]: children
      };

    case actionTypes.GET_FILTERS:
    case actionTypes.SELECT_LANGUAGE:
      return {};

    default:
      return state;
  }
};

const categories = combineReducers({
  entities,
  allById,
  selectedById,
  selectedByIdWithChildren
});

export default categories;
