import React from "react";

export default () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="currentColor"
    viewBox="0 0 60.001 60"
  >
    <g id="press_kit" transform="translate(-2397.192 -352.721)">
      <path
        id="Path_647"
        data-name="Path 647"
        d="M2436.548,407.1h-25.487a8.74,8.74,0,0,0,1.124-3.75V360.221a3.76,3.76,0,0,1,3.748-3.75h29.986a3.759,3.759,0,0,1,3.748,3.75V377.1a1.874,1.874,0,1,0,3.748,0V360.221a7.52,7.52,0,0,0-7.5-7.5h-29.986a7.52,7.52,0,0,0-7.5,7.5v20.625h-9.37a1.771,1.771,0,0,0-1.875,1.875v20.625a7.521,7.521,0,0,0,7.5,7.5,3.647,3.647,0,0,0,1.312-.188.687.687,0,0,0,.562.188h29.985a1.875,1.875,0,0,0,0-3.75Zm-28.111-3.75a3.748,3.748,0,1,1-7.5,0V384.6h7.5Z"
      />
      <path
        id="Path_648"
        data-name="Path 648"
        d="M2417.808,377.1h13.118a1.771,1.771,0,0,0,1.874-1.875V362.1a1.771,1.771,0,0,0-1.874-1.875h-13.118a1.771,1.771,0,0,0-1.875,1.875v13.125A1.771,1.771,0,0,0,2417.808,377.1Zm1.874-13.125h9.37v9.375h-9.37Z"
      />
      <path
        id="Path_649"
        data-name="Path 649"
        d="M2444.982,399.6h1.874a4.641,4.641,0,0,0,4.685-4.688v-11.25a4.64,4.64,0,0,0-4.685-4.687h-1.874a4.64,4.64,0,0,0-4.685,4.687v11.25A4.641,4.641,0,0,0,2444.982,399.6Zm-.937-15.938a.885.885,0,0,1,.937-.937h1.874a.885.885,0,0,1,.937.937v11.25a.886.886,0,0,1-.937.938h-1.874a.886.886,0,0,1-.937-.938Z"
      />
      <path
        id="Path_650"
        data-name="Path 650"
        d="M2455.664,393.971a2.055,2.055,0,0,0-2.249,1.5c-.749,3.937-3.373,6-7.5,6-4.31,0-6.747-1.875-7.5-6a1.912,1.912,0,1,0-3.749.75c.937,5.25,4.311,8.25,9.371,8.812v3.938h-1.874a1.875,1.875,0,0,0,0,3.75h7.5a1.875,1.875,0,0,0,0-3.75h-1.874v-3.938a10.183,10.183,0,0,0,9.371-8.812A2.057,2.057,0,0,0,2455.664,393.971Z"
      />
      <path
        id="Path_651"
        data-name="Path 651"
        d="M2436.548,369.6h7.5a1.875,1.875,0,0,0,0-3.75h-7.5a1.875,1.875,0,0,0,0,3.75Z"
      />
      <path
        id="Path_652"
        data-name="Path 652"
        d="M2436.548,377.1h7.5a1.875,1.875,0,0,0,0-3.75h-7.5a1.875,1.875,0,0,0,0,3.75Z"
      />
      <path
        id="Path_653"
        data-name="Path 653"
        d="M2434.674,384.721a1.771,1.771,0,0,0-1.874-1.875h-14.992a1.875,1.875,0,0,0,0,3.75H2432.8A1.771,1.771,0,0,0,2434.674,384.721Z"
      />
      <path
        id="Path_654"
        data-name="Path 654"
        d="M2417.808,390.646a1.875,1.875,0,0,0,0,3.75h11.244a1.875,1.875,0,0,0,0-3.75Z"
      />
    </g>
  </svg>
);
