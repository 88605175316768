
import { combineReducers } from "redux";
import * as actionTypes from "../constants/actionTypes";
import { Action } from "../actions/share";
import { Item } from "./items";

export type ShareState = ReturnType<typeof share>;

const isLoading = (state: boolean = false, action: Action) => {
  switch (action.type) {
    case actionTypes.GET_SHARED_LINKS:
      return true;

    case actionTypes.GET_SHARED_LINKS_SUCCESS:
    case actionTypes.GET_SHARED_LINKS_ERROR:
      return false;

    default:
      return state;
  }
};

const hasError = (state: boolean = false, action: Action) => {
  switch (action.type) {
    case actionTypes.GET_SHARED_LINKS:
    case actionTypes.GET_SHARED_LINKS_SUCCESS:
      return false;

    case actionTypes.GET_SHARED_LINKS_ERROR:
      return true;
    default:
      return state;
  }
};

const errorMessage = (state: string | null = null, action: Action) => {
  switch (action.type) {
    case actionTypes.GET_SHARED_LINKS:
    case actionTypes.GET_SHARED_LINKS_SUCCESS:
      return null;

    case actionTypes.GET_SHARED_LINKS_ERROR:
      const { error } = action.payload;
      return error;
    default:
      return state;
  }
};

const showModal = (state: boolean = false, action: Action) => {
  switch (action.type) {
    case actionTypes.SHOW_SHARE_MODAL:
      const { visible } = action.payload;
      return visible;
    default:
      return state;
  }
};

const item = (state: Item | null = null, action: Action) => {
  switch (action.type) {
    case actionTypes.SHOW_SHARE_MODAL:
      const { item } = action.payload;
      return item;

    default:
      return state;
  }
};

const links = (state: any[] = [], action: Action) => {
  switch (action.type) {
    case actionTypes.GET_SHARED_LINKS_SUCCESS:
      const { links } = action.payload;
      return links;

    case actionTypes.SHOW_SHARE_MODAL:
      return [];

    default:
      return state;
  }
};

const share = combineReducers({
  item,
  links,
  showModal,
  isLoading,
  hasError,
  errorMessage
});

export default share;
