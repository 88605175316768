import React from "react";
import logoCaseIH from "../../images/caseih-logo.png";
import logoNHA from "../../images/nha-logo.png";
import { Brand } from "../../interfaces";

interface Props {
  brand: Brand | null;
}
const LogoBrand: React.FC<Props> = (props) => {
  const { brand } = props;
  switch (brand?.code) {
    case "caseih":
      return <img src={logoCaseIH} alt="" className="logoBrand" />;
    case "nha":
      return <img src={logoNHA} alt="" className="logoBrand" />;

    default:
      return null;
  }
};

export default LogoBrand;
