import { combineReducers } from "redux";
import * as actionTypes from "../constants/actionTypes";
import { Action } from "../actions/language";
import { Entities, Dictionary } from "../interfaces";

export type Language = {
  id: string;
  code: string;
  name: string;
  labels: Dictionary<string>;
  default: boolean;
};

// export type LanguageState = {
//   +entities: Entities,
//   +isLoading: boolean,
//   +hasError: boolean,
//   +errorMessage?: string;
//   +selectedLanguage?: Language,
//   +labels?: Object,
//   +browserLanguageId?: string
// };

export type LanguageState = ReturnType<typeof language>;

const isLoading = (state: boolean = false, action: Action) => {
  switch (action.type) {
    case actionTypes.GET_LANGUAGES:
      return true;

    case actionTypes.GET_LANGUAGES_SUCCESS:
    case actionTypes.GET_LANGUAGES_ERROR:
      return false;

    default:
      return state;
  }
};

const hasError = (state: boolean = false, action: Action) => {
  switch (action.type) {
    case actionTypes.GET_LANGUAGES:
    case actionTypes.GET_LANGUAGES_SUCCESS:
      return false;

    case actionTypes.GET_LANGUAGES_ERROR:
      return true;
    default:
      return state;
  }
};

const errorMessage = (state: string | null = null, action: Action) => {
  switch (action.type) {
    case actionTypes.GET_LANGUAGES:
    case actionTypes.GET_LANGUAGES_SUCCESS:
      return null;

    case actionTypes.GET_LANGUAGES_ERROR:
      const { error } = action.payload;
      return error;
    default:
      return state;
  }
};

const entities = (
  state: Entities<Language> = {
    all: [],
    byId: {}
  },
  action: Action
): Entities<Language> => {
  switch (action.type) {
    case actionTypes.GET_LANGUAGES_SUCCESS:
      const { all, byId } = action.payload.entities;
      return {
        all,
        byId
      };

    default:
      return state;
  }
};

const selectedLanguage = (state: Language | null = null, action: Action) => {
  switch (action.type) {
    case actionTypes.SELECT_LANGUAGE:
      const { language } = action.payload;
      return language;

    default:
      return state;
  }
};

const labels = (state: Dictionary<string> = {}, action: Action) => {
  switch (action.type) {
    case actionTypes.GET_LOCALE_SUCCESS:
      const { labels } = action.payload;
      return labels;

    case actionTypes.SELECT_LANGUAGE:
      return {};

    default:
      return state;
  }
};

const browserLanguageId = (state: string | null = null, action: Action) => {
  switch (action.type) {
    case actionTypes.SET_BROWSER_LANGUAGE:
      const { id } = action.payload;
      return id;

    default:
      return state;
  }
};

const language = combineReducers({
  entities,
  isLoading,
  hasError,
  errorMessage,
  selectedLanguage,
  browserLanguageId,
  labels
});

export default language;
