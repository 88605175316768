import { Entities, Forums } from "./../interfaces/index";
import * as actionTypes from "../constants/actionTypes";

interface BasicAction {
  type: string;
}
export interface GetForumsAction {
  type: typeof actionTypes.GET_FORUMS;
  payload: {
    action?: BasicAction;
  };
}
export interface GetForumsSuccessAction {
  type: typeof actionTypes.GET_FORUMS_SUCCESS;
  payload: {
    entities: Entities<Forums>;
  };
}
export interface GetForumsErrorAction {
  type: typeof actionTypes.GET_FORUMS_ERROR;
  payload: {
    error: string;
    status: number;
  };
}

export interface GetNextPageForForumsAction {
  type: typeof actionTypes.GET_NEXT_PAGE_FOR_FORUMS;
  payload: { page: number };
}
export interface ResetPageForForumsAction {
  type: typeof actionTypes.RESET_PAGE_FOR_FORUMS;
}

export interface SetForumAction {
  type: typeof actionTypes.SET_FORUM;
  payload: {
    forums: Forums;
  };
}

export type Action =
  | GetForumsAction
  | GetForumsErrorAction
  | GetForumsSuccessAction
  | GetNextPageForForumsAction
  | ResetPageForForumsAction
  | SetForumAction;

export function getForums(
  action: BasicAction | undefined = undefined
): GetForumsAction {
  return {
    type: actionTypes.GET_FORUMS,
    payload: {
      action
    }
  };
}

export function getForumsSuccess(
  entities: Entities<Forums>
): GetForumsSuccessAction {
  return {
    type: actionTypes.GET_FORUMS_SUCCESS,
    payload: {
      entities
    }
  };
}

export function getForumsError(
  error: string,
  status: number
): GetForumsErrorAction {
  return {
    type: actionTypes.GET_FORUMS_ERROR,
    payload: {
      error,
      status
    }
  };
}

export function getNextPageForForums(page: number): Action {
  return {
    type: actionTypes.GET_NEXT_PAGE_FOR_FORUMS,
    payload: {
      page
    }
  };
}

export function resetPageForForums(): Action {
  return {
    type: actionTypes.RESET_PAGE_FOR_FORUMS
  };
}

export function setForum(forums: Forums): SetForumAction {
  return {
    type: actionTypes.SET_FORUM,
    payload: {
      forums
    }
  };
}
