import { Breadcrumb } from "antd";
import React from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { CombinedState } from "redux";
import { Entities, MaxWidth, State, Typology } from "../../interfaces";
import * as actions from "../../actions";
import { getBreadcrumbItems } from "../../utils";
import { LanguageState } from "../../reducers/language";
import { useMedia } from "react-use";

export const LibraryBreadcrumb = (props: any) => {
  const dispatch = useDispatch();
  const isSmall = useMedia(MaxWidth.MD);

  const typologies: CombinedState<{
    entities: Entities<Typology>;
    selectedTypologies: string[];
    selectedSubtypology: Typology | null;
  }> = useSelector((state: State) => state.filters.typologies, shallowEqual);

  const language: LanguageState = useSelector(
    (state: State) => state.language,
    shallowEqual
  );

  const getItems = () => {
    if (!typologies) return [];
    const selectedType = typologies.selectedTypologies?.[0];
    if (!selectedType) return [];
    const selectedSubType = typologies.selectedSubtypology;
    if (!selectedSubType) {
      return [typologies.entities.byId[selectedType]];
    }
    const items: any = getBreadcrumbItems(
      typologies.entities.byId?.[selectedType],
      selectedSubType.id
    );
    return items;
  };

  const isBaseType = (type: Typology) => {
    const selectedType = typologies.selectedTypologies?.[0];
    return selectedType == type.id;
  };

  const onItemClick = (item: Typology, count: number) => {
    if (typologies.selectedSubtypology?.id == item.id) {
      return;
    }
    if (isBaseType(item) && count == 1) {
      return;
    }
    dispatch(actions.setSubTypology(isBaseType(item) ? null : item));
  };

  const renderPath = () => {
    const items = getItems().filter(Boolean);
    return items.map((item: any) => (
      <Breadcrumb.Item
        key={item.id}
        className="library-breadcrumb-item"
        onClick={() => onItemClick(item, items.length)}
      >
        {item.name}
      </Breadcrumb.Item>
    ));
  };

  return (
    <Breadcrumb>
      {isSmall ? null : (
        <Breadcrumb.Item>{language?.labels?.library}</Breadcrumb.Item>
      )}
      {renderPath()}
    </Breadcrumb>
  );
};
