import { createStore, applyMiddleware, compose } from "redux";
import { createEpicMiddleware } from "redux-observable";
import { createBrowserHistory } from "history";
import { routerMiddleware } from "connected-react-router";
import { rootEpic } from "../epics";
import createRootReducer from "../reducers";
import { State } from "./../interfaces/index";

// Create a history of your choosing (we're using a browser history in this case)
export const history = createBrowserHistory();

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const epicMiddleware = createEpicMiddleware();

const configureStore = (initialState?: State) => {
  const store = createStore(
    createRootReducer(history),
    initialState,
    composeEnhancers(applyMiddleware(routerMiddleware(history), epicMiddleware))
  );

  if (process.env.NODE_ENV !== "production") {
    if (module.hot) {
      module.hot.accept("../reducers/index", () => {
        store.replaceReducer(createRootReducer(history));
      });
    }
  }

  epicMiddleware.run(rootEpic);
  return store;
};

export default configureStore;
