// copy to clipboard compatible with any browser

export function copyToClipboard(text: string): void {
  const textarea = document.createElement('textarea');
  textarea.value = text;
  document.body.appendChild(textarea);
  textarea.select();
  // deprecated but there is no other way to copy to clipboard as of 2023 December
  document.execCommand('copy');
  document.body.removeChild(textarea);
}
