import React from "react";

const Brand = () => {
  return (
    // <img src={logo} alt="Ag Parts Sellout logo" className="logo" />
    <div className="brand">
      <span>P&S</span> PLATFORM
    </div>
  );
};

export default Brand;
