import React from "react";

export const IstitutionalMaterialSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="currentColor"
    viewBox="0 0 60 60.024"
  >
    <g id="istitutional_material" transform="translate(-721 -555.976)">
      <path
        id="Rectangle_Copy_16"
        data-name="Rectangle Copy 16"
        d="M0,0H60V60H0Z"
        transform="translate(721 616) rotate(-90)"
        fill="none"
      />
      <path
        id="icone_ams-02"
        d="M62.02,17.968a5.657,5.657,0,0,0-5.58-5.637h-.1l-17.15.036-.012-6.827A1.628,1.628,0,0,0,37.576,3.93h-.024l-10.966.024a1.583,1.583,0,0,0-1.147.481,1.446,1.446,0,0,0-.5,1.118l.012,6.827-17.15.036A5.652,5.652,0,0,0,3.8,14.074a5.586,5.586,0,0,0-1.655,3.99L2.02,58.293A5.657,5.657,0,0,0,7.6,63.93h.1l48.527-.084a5.672,5.672,0,0,0,5.664-5.661ZM28.554,39.976l3.309-.4,3.285.385a3.463,3.463,0,0,1,3.08,3.425l-.121,16.25-12.754.024.121-16.25A3.464,3.464,0,0,1,28.554,39.976Zm-.338-6.346a3.588,3.588,0,0,1,1.28-2.452,3.635,3.635,0,0,1,2.391-.841c.085,0,.181.012.266.012a3.613,3.613,0,0,1,2,6.382l-2.319,1.935L29.5,36.73a3.288,3.288,0,0,1-.459-.457A3.623,3.623,0,0,1,28.216,33.63Zm13.708,9.76h0a7.159,7.159,0,0,0-3.539-6.142,7.338,7.338,0,0,0,.761-2.656,7.181,7.181,0,0,0-1.691-5.325,7.358,7.358,0,0,0-10.326-.913,7.252,7.252,0,0,0-2.6,4.952,7.177,7.177,0,0,0,.773,3.954,7.142,7.142,0,0,0-3.527,6.154l-.121,17.248L7.7,60.685a2.49,2.49,0,0,1-1.715-.709,2.378,2.378,0,0,1-.713-1.695L5.39,18.065a2.419,2.419,0,0,1,2.415-2.416l17.15-.036V17.62l-3.768.012a1.617,1.617,0,1,0-.024,3.233h.024l5.374-.012h.024l10.978-.012h0l5.4-.012a1.625,1.625,0,0,0,1.63-1.623,1.568,1.568,0,0,0-.483-1.142,1.586,1.586,0,0,0-1.123-.469H39.194V15.589l17.15-.024a2.4,2.4,0,0,1,2.428,2.4L58.65,58.2a2.427,2.427,0,0,1-2.415,2.416L41.8,60.625Zm-5.99-25.781-7.717.012L28.2,7.163l7.717-.012Z"
        transform="translate(718.98 552.046)"
      />
    </g>
  </svg>
);
