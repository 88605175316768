import * as actionTypes from "../constants/actionTypes";
import * as actions from "../actions";

import { Action as AuthAction, empty } from "../actions/auth";
import { State, Typology } from "../interfaces";
import {
  catchError,
  debounceTime,
  filter,
  map,
  mapTo,
  mergeMap,
  switchMap,
  tap
} from "rxjs/operators";
import { forkJoin, of } from "rxjs";

import { Action } from "../actions/items";
import { Epic } from "redux-observable";
import { Action as FilterAction } from "../actions/filters";
import { FiltersState } from "../reducers/filters";
import { Item } from "../reducers/items";
import { ajax } from "rxjs/ajax";
import domain from "../utils/domain";
import { history } from "../stores/configureStore";
import { isOfType } from "typesafe-actions";
import { normalizeItems } from "../utils/norm";
import queryString from "query-string";

export type ActionWithFilters = Action | FilterAction | AuthAction;

export const setFilterForSearchEpic: Epic<Action, Action> = (action$, state$) =>
  action$.pipe(
    filter(
      isOfType([
        actionTypes.SET_SEARCH_STRING,
        // actionTypes.SET_TYPOLOGY,
        // actionTypes.TOGGLE_TYPOLOGY_ON_SELECTED_LIST,
        actionTypes.SELECT_CATEGORY,
        actionTypes.SELECT_PROPERTY,
        actionTypes.SELECT_TAG,
      ])
    ),
    debounceTime(300),
    mapTo(actions.resetPageForItems())
  );

const fetchTypesForLogging = (state: State) => {
  try {
    const { auth, language, filters } = state;
    const { selectedBrand } = filters;
    const { accessToken } = auth;
    const markets = filters.markets.selectedMarkets || "";
    const [typoId] = (filters.typologies.selectedTypologies || []);
    const { id : subTypoId}  = (filters.typologies.selectedSubtypology || {});
    const startingType = subTypoId || typoId;
    if(!startingType) return;
    ajax({
      crossDomain: true,
      responseType: "json",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`
      },
      url: `${domain}api/item/item_types?_format=json&lang=${
        language.selectedLanguage?.id
      }&brands=${[selectedBrand?.id]}&market=${markets}&multilevel=0&startingType=${startingType}`
    }).subscribe(
      () => console.debug('Logging done.'), 
      (err) => console.error('Failed to log typology navigation.', err));
  } catch (error) {
    console.error('Failed to log typology navigation.', error)
  }
};

export const resetPageForItemsEpic: Epic<Action, Action, State> = (action$, state$) =>
  action$.pipe(
    filter(isOfType(actionTypes.RESET_PAGE_FOR_ITEMS)),
    tap({ 
      next: () => fetchTypesForLogging(state$.value), 
      error: (err) => console.error('Failed to log typology navigation.', err)}),
    switchMap((_) => {
      const { entities, selectedTypologies, selectedSubtypology} = state$.value.filters.typologies;
      const baseId = selectedTypologies[0];
      const isLeaf = Boolean(selectedSubtypology
        ? selectedSubtypology?.isLeaf
        : entities.byId[baseId]?.isLeaf);
      return of(actions.searchItems());
     // return of(isLeaf ? actions.searchItems() : actions.clearItems());
    })
  );

/*
 uso debounceTime per non fare richieste multiple al server anche se con 
 switchMap vengono cancellate e ricevo solo il risultato dell'ultima
*/
export const fetchItemsEpic: Epic<Action, Action, State> = (action$, state$) =>
  action$.pipe(
    filter(
      isOfType([actionTypes.GET_FILTERS_SUCCESS, actionTypes.SEARCH_ITEMS]),
    ),
    switchMap((action) => {
      const state = state$.value;
      const { auth, language } = state;
      const { accessToken, sessionToken } = auth;

      const { items } = state;
      // setto parametri di ricerca
      const { filters } = state;
      const _filters = setFiltersForSearch(filters);
      const subType: Typology | null = filters.typologies.selectedSubtypology
      if(subType && _filters.types.length) _filters.types = [subType?.id];

      return ajax({
        crossDomain: true,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
          "X-CSRF-Token": sessionToken
        },
        url: `${domain}api/item/list?_format=json`,
        body: {
          lang: language.selectedLanguage?.id,
          page: items.page,
          pageSize: items.itemsPerPage,
          sortBy: "date",
          ..._filters
        }
      }).pipe(
        map((e) => {
          const { results } = e.response;
          const { items, page, total, filters, share_pr } = results;
          // prima di normalizzare i dati controllo se almeno uno è modificabile e setto userCanEdit
          let userCanEdit = false;

          items.forEach((item: Item) => {
            if (item.can_edit) {
              userCanEdit = true;
              return;
            }
          });

          const userCanShare = share_pr;

          const entities = normalizeItems(items);
          return actions.searchItemsSuccess({
            entities,
            page,
            total,
            filters,
            userCanEdit,
            userCanShare
          });
        }),
        catchError((error) => {
          if (error.response)
            return of(
              actions.searchItemsError(error.response.message, error.status)
            );
          return of(actions.searchItemsError(error.message, error.status));
        })
      );
    })
  );

const setFiltersForSearch = (filters: FiltersState) => {
  // categorie
  const { entities } = filters.categories;
  const { selectedByIdWithChildren } = filters.categories;
  const selectedCategoriesValues: any = entities.all.map(
    (id: string) => selectedByIdWithChildren[id]
  );
  const categories = [].concat(...selectedCategoriesValues);
  // properties
  // const { entities: entitiesProperties } = filters.properties;
  const { selectedById: propertiesSelectedById } = filters.properties;
  // const selectedPropertiesValues = entitiesProperties.all.map(
  //   id => propertiesSelectedById[id]
  // );
  // const properties = [].concat(...selectedPropertiesValues);

  const properties = propertiesSelectedById;
  // typologies
  const { typologies, selectedBrand } = filters;
  const types = typologies.selectedTypologies;
  // market
  const market = filters.markets.selectedMarkets;
  // tags
  const tags = filters.tags.selectedTags;
  // searchString
  const searchString = filters.searchString.text;

  const _filters = {
    searchString,
    types,
    categories,
    properties,
    market,
    tags,
    brands: [selectedBrand?.id]
  };

  const subType: Typology | null = filters.typologies.selectedSubtypology
  if(subType && _filters.types.length) _filters.types = [subType?.id];
  return _filters;
};

export const fetchNextPageEpic: Epic<Action, Action> = (action$) =>
  action$.pipe(
    filter(isOfType(actionTypes.GET_NEXT_PAGE_FOR_ITEMS)),
    mapTo(actions.searchItems())
  );

const getInHomeItems = () => (action: any, state: State) => {
  const { auth, language, filters } = state;
  const { accessToken, sessionToken } = auth;
  const { selectedBrand } = filters;

  return ajax({
    crossDomain: true,
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
      "X-CSRF-Token": sessionToken
    },
    url: `${domain}api/item/list?_format=json`,
    body: {
      lang: language.selectedLanguage?.id,
      page: 0,
      pageSize: 3,
      sortBy: "date",
      brands: [selectedBrand?.id],
      in_home: true
    }
  });
};

const getBestPracticesItems = () => (action: any, state: State) => {
  const { auth, language, filters } = state;
  const { accessToken, sessionToken } = auth;
  const { selectedBrand } = filters;

  return ajax({
    crossDomain: true,
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
      "X-CSRF-Token": sessionToken
    },
    url: `${domain}api/item/list?_format=json`,
    body: {
      lang: language.selectedLanguage?.id,
      page: 0,
      pageSize: 3,
      sortBy: "date",
      brands: [selectedBrand?.id],
      types: ["8"]
    }
  });
};

export const fetchItemsForHomeEpic: Epic<
  ActionWithFilters,
  ActionWithFilters,
  State
> = (action$, state$) =>
  action$.pipe(
    filter(isOfType([actionTypes.SET_BRAND, actionTypes.SELECT_LANGUAGE])),
    mergeMap((action) => {
      const { filters } = state$.value;
      const { selectedBrand } = filters;

      if (!selectedBrand) return of(empty());
      // uso forkJoin per chiamare più servizi in sequenza
      return forkJoin([
        getInHomeItems()(action, state$.value),
        getBestPracticesItems()(action, state$.value)
      ]).pipe(
        map((results) => {
          const [homeItems, bpItems] = results;

          const homeEntities = normalizeItems(homeItems.response.results.items);
          const bestPracticesEntities = normalizeItems(
            bpItems.response.results.items
          );
          return actions.getHomeItemsSuccess(
            homeEntities,
            bestPracticesEntities
          );
        }),
        catchError((error) => {
          if (error.response)
            return of(
              actions.getHomeItemsError(error.response.message, error.status)
            );
          return of(actions.getHomeItemsError(error.message, error.status));
        })
      );
    })
  );

export const fetchItemByIdEpic: Epic<Action, Action, State> = (
  action$,
  state$
) =>
  action$.pipe(
    filter(isOfType(actionTypes.GET_ITEM_BY_ID)),
    switchMap((action) => {
      const state = state$.value;
      const { auth, language } = state;
      const { accessToken, sessionToken } = auth;

      const { itemID } = action.payload;
      return ajax({
        crossDomain: true,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
          "X-CSRF-Token": sessionToken
        },
        url: `${domain}api/item/list?_format=json`,
        body: {
          lang: language.selectedLanguage?.id,
          page: 0,
          pageSize: 1,
          itemid: itemID
        }
      }).pipe(
        map(({ response }) => {
          // gestisco il caso non ci sia item
          if (response.results.items.length < 1)
            return actions.getItemByIdError(`no item for ID ${itemID}`, 400);

          return actions.getItemByIdSuccess(response.results.items[0]);
        }),
        catchError((error) => {
          if (error.response)
            return of(
              actions.getItemByIdError(error.response.message, error.status)
            );
          return of(actions.getItemByIdError(error.message, error.status));
        })
      );
    })
  );

export const removeItemByIdEpic: Epic<Action, Action, State> = (
  action$,
  state$
) =>
  action$.pipe(
    filter(isOfType(actionTypes.UNPUBLISH_ITEM)),
    switchMap((action) => {
      const state = state$.value;
      const { auth } = state;
      const { accessToken, sessionToken } = auth;

      const { itemID } = action.payload;
      return ajax({
        method: "POST",
        crossDomain: true,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
          "X-CSRF-Token": sessionToken
        },
        url: `${domain}api/item/item_data?_format=json`,
        body: {
          itemID,
          oper: "unpublish"
        }
      }).pipe(
        map(({ response }) => {
          return actions.unpublishItemSuccess(itemID);
        }),
        catchError((error) => {
          if (error.response)
            return of(
              actions.unpublishItemError(error.response.message, error.status)
            );
          return of(actions.unpublishItemError(error.message, error.status));
        })
      );
    })
  );

export const editTagsForItemEpic: Epic<Action, Action, State> = (
  action$,
  state$
) =>
  action$.pipe(
    filter(isOfType(actionTypes.EDIT_TAGS_FOR_ITEM)),
    switchMap((action) => {
      const state = state$.value;
      const { auth } = state;
      const { accessToken, sessionToken } = auth;

      const { added, removed, itemID } = action.payload;
      return ajax({
        crossDomain: true,
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
          "X-CSRF-Token": sessionToken
        },
        url: `${domain}api/item/item_data?_format=json`,
        body: {
          itemID,
          added,
          removed,
          oper: "tags"
        }
      }).pipe(
        map(({ response }) => {
          const { tags } = response;
          return actions.editTagsForItemSuccess(tags, itemID);
        }),
        catchError((error) => {
          if (error.response)
            return of(
              actions.editTagsForItemError(error.response.message, error.status)
            );
          return of(actions.editTagsForItemError(error.message, error.status));
        })
      );
    })
  );

export const fetchItemsAfterEditEpic: Epic<
  ActionWithFilters,
  ActionWithFilters,
  State
> = (action$, state$) =>
  action$.pipe(
    filter(isOfType(actionTypes.EDIT_TAGS_FOR_ITEM_SUCCESS)),
    switchMap((action) => {
      const state = state$.value;
      const { auth, language } = state;
      const { accessToken, sessionToken } = auth;

      const { items } = state;
      // setto parametri di ricerca
      const { filters } = state;
      const _filters = setFiltersForSearch(filters);

      return ajax({
        crossDomain: true,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
          "X-CSRF-Token": sessionToken
        },
        url: `${domain}api/item/list?_format=json`,
        body: {
          lang: language.selectedLanguage?.id,
          page: items.page,
          pageSize: items.itemsPerPage,
          sortBy: "date",
          ..._filters
        }
      }).pipe(
        map((e) => {
          const { results } = e.response;
          const { filters } = results;
          return actions.setFiltersAfter(filters);
        }),
        catchError((error) => {
          if (error.response)
            return of(
              actions.searchItemsError(error.response.message, error.status)
            );
          return of(actions.searchItemsError(error.message, error.status));
        })
      );
    })
  );

export const typologyEpic: Epic<Action, Action> = (action$) =>
  action$.pipe(
    filter(isOfType([actionTypes.SET_SUBTYPOLOGY, actionTypes.SET_TYPOLOGY, actionTypes.TOGGLE_TYPOLOGY_ON_SELECTED_LIST])),
    switchMap((_) => of(actions.resetPageForItems()))
  );