
import { combineReducers } from "redux";
import * as actionTypes from "../constants/actionTypes";
import { Action } from "../actions/shareLibrary";

export type ShareLibraryState = ReturnType<typeof shareLibrary>;

const isLoading = (state: boolean = false, action: Action) => {
  switch (action.type) {
    case actionTypes.GET_SHARED_LIBRARY_LINK:
      return true;

    case actionTypes.GET_SHARED_LIBRARY_LINK_SUCCESS:
    case actionTypes.GET_SHARED_LIBRARY_LINK_ERROR:
      return false;

    default:
      return state;
  }
};

const hasError = (state: boolean = false, action: Action) => {
  switch (action.type) {
    case actionTypes.GET_SHARED_LIBRARY_LINK:
    case actionTypes.GET_SHARED_LIBRARY_LINK_SUCCESS:
      return false;

    case actionTypes.GET_SHARED_LIBRARY_LINK_ERROR:
      return true;
    default:
      return state;
  }
};

const errorMessage = (state: string | null = null, action: Action) => {
  switch (action.type) {
    case actionTypes.GET_SHARED_LIBRARY_LINK:
    case actionTypes.GET_SHARED_LIBRARY_LINK_SUCCESS:
      return null;

    case actionTypes.GET_SHARED_LIBRARY_LINK_ERROR:
      const { error } = action.payload;
      return error;
    default:
      return state;
  }
};


const link = (state: String = '', action: Action) => {
  switch (action.type) {
    case actionTypes.GET_SHARED_LIBRARY_LINK_SUCCESS:
      const { link } = action.payload;
      return link;

    default:
      return state;
  }
};

const shareLibrary = combineReducers({
  link,
  isLoading,
  hasError,
  errorMessage
});

export default shareLibrary;
