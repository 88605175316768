import * as actions from "../../actions";

import React, { useEffect } from "react";

import { Loader } from "../../components/loader";
import { useDispatch } from "react-redux";

interface Props {}

const LogoutPage: React.FC<Props> = (props) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(actions.logout());
  }, [dispatch]);

  return (
    <>
      <Loader isLoading={true} />
    </>
  );
};

export default LogoutPage;
