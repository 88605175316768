import { Button, Col, Row } from "antd";
import React, { useCallback, useEffect } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";

import { AuthState } from "../../reducers/auth";
import { State } from "../../interfaces";

interface Props {
  location: any;
}

const DealerportalLoginPage: React.FC<Props> = (props) => {
  const url = process.env.REACT_APP_INTERNAL_USER_SSO_URL;


  const dispatch = useDispatch();
  const auth: AuthState = useSelector(
    (state: State) => state.auth,
    shallowEqual
  );

  useEffect(() => {
    if (!auth || !auth?.accessToken) {
      window.location.href = url;
    }
  }, [auth]);

  return (
    <Row className="login" align="middle" justify="center">
      <Col xs={{ span: 22, offset: 1 }} sm={{ span: 8 }}>
        <h1>Login access</h1>
        <Button
          type="primary"
          className="login-form-button"
          onClick={() => { window.location.href = url; }}
        >
          Access to user portal
        </Button>
      </Col>
    </Row>
  );
};

export default DealerportalLoginPage;
