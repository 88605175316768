import React from "react";
import { shallowEqual, useSelector } from "react-redux";
import { Player } from "video-react";

import { AuthState } from "../../reducers/auth";
import { State } from "../../interfaces";

interface Props {
  fileUrl: string;
  fileName?: string;
  poster?: string;
  clssName?: string;
  type: "tag" | "css" | "video";
}

// const fetchFile = (url: string, accessToken: string, sessionToken: string) =>
//   axios({
//     method: "get",
//     url: url,
//     responseType: "blob",
//     headers: {
//       "Content-Type": "application/json",
//       Authorization: `Bearer ${accessToken}`,
//       "X-CSRF-Token": sessionToken
//     }
//     // cancelToken: source.token
//   })
//     .then(async data => {
//       // console.log("data ", data.data);
//       const imageBlob = data.data;
//       const src = await fileScr(imageBlob);
//       return src;
//     })
//     .catch(error => {
//       console.log("error ", error.message);
//       // return "assets/fallback.png";
//       return imgBroken;
//     });

const FileLoader: React.FC<Props> = (props) => {
  const { fileUrl, fileName, type, poster, clssName } = props;

  const auth: AuthState = useSelector(
    (state: State) => state.auth,
    shallowEqual
  );
  const { accessToken } = auth;

  const _url = fileUrl.includes("?")
    ? `${fileUrl}&access_token=${accessToken}`
    : `${fileUrl}?access_token=${accessToken}`;

  const _poster = poster?.includes("?")
    ? `${poster}&access_token=${accessToken}`
    : `${poster}?access_token=${accessToken}`;

  switch (type) {
    case "video":
      return (
        <Player playsInline src={_url} poster={_poster} className={clssName} />
      );

    case "tag":
      return <img alt={fileName} width="100%" src={_url} className={clssName} />;
    case "css":
      return (
        <div
          className={clssName}
          style={{
            backgroundImage: `url(${_url})`
          }}
        />
      );
  }
};

export default React.memo<Props>(FileLoader);
