import { Button, Dropdown, Menu } from "antd";

import { Dictionary } from "../../interfaces";
import { LogoutAction } from "../../actions/auth";
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { User } from "../../reducers/auth";
import { UserOutlined } from "@ant-design/icons";

interface Props {
  isFetching: boolean;
  labels: Dictionary<string>;
  user: User | null;
  openUserProfile: () => void;
  onLogout: () => LogoutAction;
}
const LogoutBtn: React.FC<Props> = (props) => {
  const { user, labels, isFetching, openUserProfile, onLogout } = props;

  const menu = (
    <Menu>
      <Menu.Item>
        <Menu.Item title="Profile">
          <a onClick={openUserProfile}>{labels.profile}</a>
        </Menu.Item>
      </Menu.Item>
      <Menu.Item title="Privacy policy">
        <a href="/privacy-policy.pdf" target="_blank">
          {labels.privacyPolicy}
        </a>
      </Menu.Item>
    </Menu>
  );

  return (
    <>
      {user && (
        <Dropdown.Button
          overlay={menu}
          placement="bottomCenter"
          icon={<UserOutlined />}
        >
          {/* {user.firstName} */}
        </Dropdown.Button>
      )}
      {user && (
        <Button
          className="logout-button"
          onClick={() => onLogout()}
          loading={isFetching}
        >
          Log out
        </Button>
      )}
    </>
  );
};

export default LogoutBtn;
