import React from "react";
import { shallowEqual, useSelector, useDispatch } from "react-redux";
// import { push } from "connected-react-router";
import { Link } from "react-router-dom";
import * as actions from "../../actions";
import { State, Brand } from "../../interfaces";
import { LanguageState } from "../../reducers/language";
import { AuthState } from "../../reducers/auth";
import LogoBrand from "../../components/nav/LogoBrand";
import { useLocalStorage } from "react-use";

let SelectBrand = () => {
  const [, setStorageBrand] = useLocalStorage("brand");

  const language: LanguageState = useSelector(
    (state: State) => state.language,
    shallowEqual
  );
  const { labels } = language;
  const auth: AuthState = useSelector(
    (state: State) => state.auth,
    shallowEqual
  );
  const { user } = auth;

  const dispatch = useDispatch();

  const selectBrand = (brand: Brand) => {
    setStorageBrand(brand);
    dispatch(actions.setBrand(brand));
    // push("/home");
  };

  return (
    <div className="select-brand">
      <h4>{labels.selectTheBrand}:</h4>
      <div className="wrapper">
        {user?.brands.map((brand: Brand) => {
          const { code } = brand;
          return (
            <Link
              key={code}
              to={`/${code}/home`}
              onClick={() => selectBrand(brand)}
            >
              <div className="box">
                <LogoBrand brand={brand} />
              </div>
            </Link>
          );
        })}
      </div>
    </div>
  );
};

export default SelectBrand;
