import * as actionTypes from "../constants/actionTypes";

import { Brand } from "../interfaces";
import { Item } from "../reducers/items";

export interface GetSharedLinksAction {
  type: typeof actionTypes.GET_SHARED_LINKS;
  payload: {
    nid: string;
    fids: string[];
    exp: number;
    typology: string[];
    subTypology: string;
    selectedBrand: Brand | null,
  };
}
export interface GetSharedLinksSuccessAction {
  type: typeof actionTypes.GET_SHARED_LINKS_SUCCESS;
  payload: {
    links: Array<Object>;
  };
}
export interface GetSharedLinksErrorAction {
  type: typeof actionTypes.GET_SHARED_LINKS_ERROR;
  payload: { error: string; status: number };
}
export interface ShowShareModalAction {
  type: typeof actionTypes.SHOW_SHARE_MODAL;
  payload: { visible: boolean; item: Item | null | undefined };
}

export type Action =
  | GetSharedLinksAction
  | GetSharedLinksSuccessAction
  | GetSharedLinksErrorAction
  | ShowShareModalAction;

export function getSharedLinks(nid: string, fids: string[], exp: number, typology: string[], subTypology: string, selectedBrand: Brand | null) {
  return {
    type: actionTypes.GET_SHARED_LINKS,
    payload: {
      nid,
      fids,
      exp,
      typology,
      subTypology,
      selectedBrand
    }
  };
}

export function getSharedLinksSuccess(links: Array<Object>): Action {
  return {
    type: actionTypes.GET_SHARED_LINKS_SUCCESS,
    payload: {
      links
    }
  };
}

export function getSharedLinksError(error: string, status: number): Action {
  return {
    type: actionTypes.GET_SHARED_LINKS_ERROR,
    payload: {
      error,
      status
    }
  };
}

export function showShareModal(
  visible: boolean,
  item: Item | null | undefined
): Action {
  return {
    type: actionTypes.SHOW_SHARE_MODAL,
    payload: {
      visible,
      item
    }
  };
}
