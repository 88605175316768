import * as actionTypes from "../constants/actionTypes";
import * as actions from "../actions";

import { Action, empty } from "../actions/auth";
import { catchError, delay, filter, map, mergeMap } from "rxjs/operators";
import { concat, of, forkJoin } from "rxjs";

import { CallHistoryMethodAction } from "connected-react-router";
import { Epic } from "redux-observable";
import { Action as FilterAction } from "../actions/filters";
import { Action as ForumsAction } from "../actions/forums";
import { Action as ItemsAction } from "../actions/items";
import { Action as NewsAction } from "../actions/news";
import { Action as ShareAction } from "../actions/share";
import { State } from "../interfaces";
import { User } from "../reducers/auth";
import { ajax } from "rxjs/ajax";
import domain from "../utils/domain";
import { isOfType } from "typesafe-actions";
import { Observable } from "redux";

const client_id = process.env.REACT_APP_CLIENT_ID;
const client_secret = process.env.REACT_APP_CLIENT_SECRET;

export type ActionWithFilters = Action | FilterAction;
export type ActionWithRedirect = Action | CallHistoryMethodAction<any>;

export const sessionTokenEpic: Epic<Action, Action, State> = (
  action$,
  state$
) =>
  action$.pipe(
    filter(isOfType([actionTypes.GET_SESSION_TOKEN])),
    mergeMap((action) => {
      const { action: nextAction } = action.payload;

      const doLogin = nextAction ? false : true;

      return ajax({
        crossDomain: true,
        url: `${domain}session/token?${new Date().getTime()}`,
        responseType: "text/plain"
      }).pipe(
        mergeMap((e) => {
          let _actions: any[] = [
            actions.getSessionTokenSuccess(e.response, doLogin)
          ];

          if (nextAction) _actions.push(nextAction);

          return concat(_actions);
        }),
        catchError((error) =>
          of(actions.getSessionTokenError(error.response.message, error.status))
        )
      );
    })
  );

export const autoLoginEpic: Epic<Action, Action, State> = (action$, state$) =>
  action$.pipe(
    filter(isOfType(actionTypes.GET_SESSION_TOKEN_SUCCESS)),
    mergeMap((action) => {
      const { doLogin } = action.payload;
      const auth = localStorage.getItem("auth");
      if (doLogin && auth) {
        const _auth = JSON.parse(auth);
        const { access_token, refresh_token } = _auth;
        const localFrom = localStorage.getItem("from");
        const from =
          localFrom === "/" || localFrom === null
            ? "/home"
            : localFrom ?? "/home";
        return of(actions.loginWithToken(access_token, refresh_token, from));
      }
      return of(empty());
    })
  );

export const loginEpic: Epic<ActionWithFilters, ActionWithFilters, State> = (
  action$,
  state$
) =>
  action$.pipe(
    filter(isOfType(actionTypes.LOGIN)),
    mergeMap((action) => {
      const { values } = action.payload;
      // console.log("payload ", values);
      const userName = values.userName;
      const password = values.password;
      const state = state$.value;
      const { auth } = state;
      const { sessionToken } = auth;
      return ajax({
        crossDomain: true,
        method: "POST",
        headers: {
          "X-CSRF-Token": sessionToken
        },
        url: `${domain}oauth/token`,
        body: {
          grant_type: "password",
          client_id,
          client_secret,
          username: userName,
          password: password,
          scope: "simple approver dealer_portal manager supermanager"
        }
      }).pipe(
        mergeMap(({ response }) => {
          const {
            access_token: accessToken,
            refresh_token: refreshToken,
            expires_in
          } = response;
          const state = state$.value;
          const { auth } = state;
          if (auth.rememberUser) {
            // salvo la response su localStorage
            localStorage.setItem("auth", JSON.stringify(response));
          }
          // recupero il tempo in cui scade il token, che è in secondi,
          // lo trasformo in millisecondi e sottraggo 20 secondi per poterlo refreshare prima che scada
          const timeToRefresh = expires_in * 1000 - 20000;
          // per test
          // const timeToRefresh = 5000;

          return concat(
            [actions.loginSuccess(accessToken, refreshToken, expires_in)],
            of(actions.refreshToken(refreshToken)).pipe(delay(timeToRefresh))
          );
        }),
        catchError((error) => {
          console.log("error ", error);
          if (error.response)
            return of(actions.loginError(error.response.message, error.status));
          return of(actions.loginError(error.message, error.status));
        })
      );
    })
  );

export const loginWithTokenEpic: Epic<
  ActionWithFilters,
  ActionWithFilters,
  State
> = (action$, state$) =>
    action$.pipe(
      filter(isOfType(actionTypes.LOGIN_WITH_TOKEN)),
      mergeMap((action) => {
        const state = state$.value;
        const { auth } = state;
        const { sessionToken } = auth;

        const { refresh_token } = action.payload;
        return ajax({
          crossDomain: true,
          method: "POST",
          headers: {
            "X-CSRF-Token": sessionToken
          },
          url: `${domain}oauth/token`,
          body: {
            grant_type: "refresh_token",
            refresh_token: refresh_token,
            client_id,
            client_secret
          }
        }).pipe(
          mergeMap(({ response }) => {
            const { access_token, refresh_token, expires_in } = response;
            const state = state$.value;
            const { auth } = state;
            // if (auth.rememberUser) {
            localStorage.setItem("auth", JSON.stringify(response));
            // }
            // recupero il tempo in cui scade il token, che è in secondi,
            // lo trasformo in millisecondi e sottraggo 20 secondi per poterlo refreshare prima che scada
            const timeToRefresh = expires_in * 1000 - 20000;

            return concat(
              [
                actions.refreshTokenSuccess(
                  access_token,
                  refresh_token,
                  expires_in
                ),
                actions.loginSuccess(access_token, refresh_token, expires_in)
              ],
              of(actions.refreshToken(refresh_token)).pipe(delay(timeToRefresh))
            );
          }),
          catchError((error) => {
            console.log("error ", error);
            return concat([
              actions.refreshTokenError(error.response?.message, error.status),
              error.response
                ? actions.loginError(error.response.message, error.status)
                : actions.loginError(error.message, error.status),
              actions.logout()
            ]);
          })
        );
      })
    );

export const loginSuccessEpic: Epic<
  ActionWithFilters,
  ActionWithFilters,
  State
> = (action$, state$) =>
    action$.pipe(
      filter(isOfType(actionTypes.LOGIN_SUCCESS)),
      map((action) => {
        const { access_token } = action.payload;

        return actions.getUser(access_token);
      })
    );

export const refreshTokenEpic: Epic<Action, Action, State> = (
  action$,
  state$
) =>
  action$.pipe(
    filter(isOfType(actionTypes.REFRESH_TOKEN)),
    mergeMap((action) => {
      const state = state$.value;
      const { auth } = state;
      const { sessionToken } = auth;

      const { refresh_token } = action.payload;
      return ajax({
        crossDomain: true,
        method: "POST",
        headers: {
          "X-CSRF-Token": sessionToken
        },
        url: `${domain}oauth/token`,
        body: {
          grant_type: "refresh_token",
          refresh_token: refresh_token,
          client_id,
          client_secret
        }
      }).pipe(
        map(({ response }) => {
          const { access_token, refresh_token, expires_in } = response;
          const state = state$.value;
          const { auth } = state;
          if (auth.rememberUser) {
            localStorage.setItem("auth", JSON.stringify(response));
          }
          return actions.refreshTokenSuccess(
            access_token,
            refresh_token,
            expires_in
          );
        }),
        catchError((error) =>
          of(actions.refreshTokenError(error.response.message, error.status))
        )
      );
    })
  );

const fetchUserAuthenticated = () => (action: any, state$: State) => {
  const { access_token } = action.payload;
  const { auth } = state$;
  const { sessionToken } = auth;

  return ajax({
    crossDomain: true,
    url: `${domain}ic_sso/authenticated`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${access_token}`,
      "X-CSRF-Token": sessionToken
    }
  });
};

const fetchUserData = () => (action: any, state$: State) => {
  const { access_token } = action.payload;
  const { auth } = state$;
  const { sessionToken } = auth;

  return ajax({
    crossDomain: true,
    url: `${domain}api/user/data?_format=json&access_token=${access_token}`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${access_token}`,
      "X-CSRF-Token": sessionToken
    }
  });
};

export const getUserEpic: Epic<
  ActionWithRedirect,
  ActionWithRedirect,
  State
> = (action$, state$) =>
    action$.pipe(
      filter(
        isOfType(actionTypes.GET_USER)
      ),
      mergeMap((action) => {
        return forkJoin(
          fetchUserAuthenticated()(action, state$.value),
          fetchUserData()(action, state$.value),
        ).pipe(
          mergeMap((results) => {
            const [user_authenticated, user_data] = results;
            const {
              email,
              firstname,
              markets,
              name,
              surname,
              role,
              portal,
              brands
            } = user_data.response;
            const user: User = {
              userName: name,
              firstName: firstname,
              lastName: surname,
              markets,
              email,
              role,
              portal,
              brands
            };
            const state = state$.value.auth;
            const redirectToReferrer = state.from !== null && state.from !== "/";
            // console.log('user: ',user);
            let _actions: any[] = [actions.getUserSuccess(user)];

            localStorage.setItem("markets", JSON.stringify(markets));

            // se il from arriva da un link di condivisione libreria "/s/:token"
            // non autoselezionare il brand ma fai un redirect alla pagina del token
            if (state.from && state.from.startsWith("/s/")) {
              _actions.push(actions.redirectToPage(state.from as string));
              return concat(_actions);
            }
            // se il from arriva da un link di condivisione libreria "/item/shared/:token"
            // non autoselezionare il brand ma fai un redirect alla pagina del token
            if (state.from && state.from.startsWith("/item/shared/")) {
              _actions.push(actions.redirectToPage(state.from as string));
              return concat(_actions);
            }

            if (user.brands.length === 1) {
              // auto seleziona la brand
              const from = redirectToReferrer ? state.from : `/${user.brands[0].code}/home`;
              _actions.push(actions.setBrand(user.brands[0]));
              _actions.push(actions.redirectToPage(from as string));
            } else {
              const brand = localStorage.getItem("brand");
              const brandQ = localStorage.getItem("brandq");
              const _brand = user.brands.find((brand) => brand.code === brandQ);

              // auto seleziona la brand
              if (_brand) {
                const from = redirectToReferrer ? state.from : `/${_brand.code}/home`;
                _actions.push(actions.setBrand(_brand));
                _actions.push(actions.redirectToPage(from as string));

                localStorage.removeItem("brandq");
              } else if (brand) {
                const lsBrand = JSON.parse(brand);
                const validBrand = redirectToReferrer && user.brands.find(b => state.from?.startsWith(`/${b.code}`));

                const from = validBrand ? state.from : `/${lsBrand.code}/home`;
                _actions.push(actions.setBrand(validBrand || lsBrand));
                if (redirectToReferrer && !validBrand) {
                  _actions.push(actions.redirectToPage("/select-brand"));
                } else {
                  _actions.push(actions.redirectToPage(from as string));
                }
              } else {
                const brand = user.brands.find(b => state.from?.startsWith(`/${b.code}`));
                if (brand) {
                  const from = redirectToReferrer ? state.from : `/${brand.code}/home`;
                  _actions.push(actions.setBrand(brand));
                  _actions.push(actions.redirectToPage(from as string));
                } else {
                  _actions.push(actions.redirectToPage("/select-brand"));
                }
              }
            }
            localStorage.removeItem('from');

            return concat(_actions);
          }),
          catchError((error) => {
            console.log("error ", error);
            let error_message = error.response?.message || 'Error getting user data';
            return of(actions.getUserError(error_message, error.status));
          })
        );
      })
    );

export const logoutEpic: Epic<Action, Action, State> = (
  action$,
  state$
) =>
  action$.pipe(
    filter(isOfType(actionTypes.LOGOUT)),
    mergeMap(() => {
      const state = state$.value;
      const roles = state.auth.user?.role;
      
      // elimino localStorage
      localStorage.removeItem("brand");
      localStorage.removeItem("auth");

      let url = process.env.REACT_APP_INTERNAL_USER_SSO_URL || '';
      if (roles?.includes('dealer_portal')) {
        url = process.env.REACT_APP_DEALER_USER_SSO_URL || '';
      };
      return of(actions.redirectToExternalUrl(url) as unknown as Action); // Fix: Cast the action to Action type
    })
  );

export const recoverPasswordEpic: Epic<
  ActionWithRedirect,
  ActionWithRedirect,
  State
> = (action$, state$) =>
    action$.pipe(
      filter(isOfType(actionTypes.RECOVER_PASSWORD)),
      mergeMap((action) => {
        const { username, email } = action.payload;
        const state = state$.value;
        const { auth } = state;
        const { sessionToken } = auth;

        return ajax({
          crossDomain: true,
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "X-CSRF-Token": sessionToken
          },
          url: `${domain}user/password?_format=json`,
          body: {
            name: username,
            mail: email
            // name: username
          }
        }).pipe(
          mergeMap(({ response }) =>
            of(actions.recoverPasswordSuccess(), actions.redirectToPage("/"))
          ),
          catchError((error) => {
            console.log("error recovery: ", error);
            return of(actions.recoverPasswordError(error.message, error.status));
          })
        );
      })
    );

export type ActionWithUnauthorizedUser =
  | Action
  | FilterAction
  | ItemsAction
  | NewsAction
  | ShareAction
  | ForumsAction;

export const unauthorizedUserEpic: Epic<
  ActionWithUnauthorizedUser,
  ActionWithUnauthorizedUser,
  State
> = (action$, state$) =>
    action$.pipe(
      filter(
        isOfType([
          actionTypes.GET_USER_ERROR,
          actionTypes.GET_FILTERS_ERROR,
          actionTypes.SEARCH_ITEMS_ERROR,
          actionTypes.GET_HOME_ITEMS_ERROR,
          actionTypes.GET_ITEM_BY_ID_ERROR,
          actionTypes.UNPUBLISH_ITEM_ERROR,
          actionTypes.EDIT_TAGS_FOR_ITEM_ERROR,
          actionTypes.GET_SHARED_LINKS_ERROR,
          actionTypes.GET_NEWS_ERROR,
          actionTypes.GET_FORUMS_ERROR
        ])
      ),
      mergeMap((action) => {
        const { status } = action.payload;

        if (status === 401) {
          return of(actions.logout());
        } else {
          return of(empty());
        }
      })
    );
