import React, { useEffect, useState } from "react";
import { Route, Redirect } from "react-router-dom";
import { User } from "../../reducers/auth";
import * as actions from "../../actions";
import { useDispatch } from "react-redux";

interface Props {
  // component: any;
  component: any;
  user?: User;
  exact?: boolean;
  path?: string;
}

const PrivateRoute: React.FC<Props> = (props) => {
  const { component: Component, user, ...rest } = props;
  const [isLoading, setIsLoading] = useState(true);
  const dispatch = useDispatch();

  useEffect(() => {
    const auth = localStorage.getItem('auth');
    const fetchData = async () => {
      try {
        const { access_token, refresh_token } = JSON.parse(auth!);
        const from = localStorage.getItem('from') || '/';
        dispatch(actions.getSessionToken(actions.loginWithToken(access_token, refresh_token, from )));
      } catch (error) {
        console.error("Error fetching user data: ", error);
      } finally {
        if (user) {
          setIsLoading(false);
        };
      };
    };
    // Se l'utente non è nello stato, prima di fare un redirect alla
    // login prova a rifare il fetch col token nel localstorage
    if (!user && auth) {
      fetchData();
    } else {
      setIsLoading(false);
    };

  }, [user, dispatch]);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <Route
      {...rest}
      render={(props) => {
        return user ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: "/",
              state: { from: props.location }
            }}
          />
        );
      }}
    />
  );
};

export default PrivateRoute;
